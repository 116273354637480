import React, { useRef } from "react"
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite"
import { Table, TTableOnFetch, TTableRef } from "@btc-alpha/ui-components"

import { IInternalTransferDetails } from "types/internal_transfers"
import transfersMessages from "messages/transfers"
import InternalTransferService from "services/InternalTransferService"

import { useColumns, useFilters } from "./hooks"

const Transfers = () => {
  const { formatMessage } = useIntl()
	const tableRef = useRef<TTableRef>(null)
	const handleRefetch = () => tableRef.current?.refetch()
	const columns = useColumns(handleRefetch)
	const filters = useFilters()

	const handleFetch: TTableOnFetch<IInternalTransferDetails> = async ({ page, limit, filters }) => {
		const { results, count } = await InternalTransferService.loadTransfersHistory({
			page,
			"page-size": limit,
			currency: filters.currency,
		})
		return { data: results, count }
	}

	return (
		<Table
			ref={tableRef}
			columns={columns}
			filters={filters}
      id="txid"
			empty={formatMessage(transfersMessages.no_orders_to_show)}
			lazyLoad
			resetFilters
			defFetching
			footer="pagination"
			onFetch={handleFetch}
		/>
	)
}

export default observer(Transfers)
