import ApiClient from "helpers/ApiClient";
import { useQuery } from "react-query";
import { IPromotedPair, IVolumes } from "types/home";

const HomeService = {
	getPromotedPairs: (): Promise<IPromotedPair[]> => ApiClient.get("web/marketing/promoted-pairs"),

	// @TODO: getVolumes - not implemented on the backend
	getVolumes:(): Promise<IVolumes> => ApiClient.get("v1/trading-volume"),
};

export default HomeService;

export const usePromotedPairs = () =>
	useQuery(["promoted-pairs", null], async () => {
		const data = await HomeService.getPromotedPairs();
		return data ?? [];
	});

export const getVolumes = async (): Promise<IVolumes> => HomeService.getVolumes()
