import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "styles/pages/ProfileVerification.module.scss"
import commonMessages from "messages/common"
import verificationMessages from "messages/verification"
import Button from "components/UI/Button"
import InternalLink from "components/InternalLink"
import { routes } from "constants/routing"
// import LootBox, { ELootBoxColor } from "../../../LootBox"
// import doge from "../../../../assets/images/loot/doge.png"

interface IFormSuccessfullySent {
	onClick?(): void
}

export const FormSuccessfullySent: React.FC<IFormSuccessfullySent> = ({ onClick }) => {
	const { formatMessage } = useIntl()
	// const [opened, setOpened] = useState(true)

	return (
		<div className={styles.form_sent_successfully}>
			<i className="ai ai-mail_open" />
			<span>
				{formatMessage(verificationMessages.the_data_has_been_successfully_sent_for_verification)}
			</span>
			{onClick && (
				<div className={styles.form_button_group}>
					<Button
						label={`${formatMessage(verificationMessages.verification)} [${formatMessage(
							verificationMessages.address,
						)}]`}
						variant="filled"
						color="primary"
						onClick={onClick}
						fullWidth
					/>
					<InternalLink to={routes.verification.root}>
						<Button
							label={formatMessage(commonMessages.back_btn)}
							variant="text"
							color="primary"
							fullWidth
						/>
					</InternalLink>
				</div>
			)}

			{/*<LootBox*/}
			{/*	isOpened={opened}*/}
			{/*	onClose={() => setOpened(false)}*/}
			{/*	color={ELootBoxColor.YELLOW}*/}
			{/*	title={["Get your BTC Box", "for Verification"]}*/}
			{/*	description="Grab your silver box and get 1000 USDC, 0.01 BTC, 500 free trading operations, a bonus to reduce trading fees for 1 month, and a random reward in DOGE, PEPE, TRX, or XRP."*/}
			{/*	footer="Claim your crypto box by Registration. Box expires by 26 Oct 2024 03:28:02 UTC"*/}
			{/*	submittedTitle={["Congratulation!", "You won 1 DOGE."]}*/}
			{/*	submittedFooter="Your reward will be automatically credited to your balance within 6 hours."*/}
			{/*	coinText="1 DOGE"*/}
			{/*	coinLogoSrc={doge}*/}
			{/*	onClaim={() => setOpened(false)}*/}
			{/*/>*/}
		</div>
	)
}

export interface IFormError {
	firstName: string | null
	lastName: string | null
	middleName: string | null
	b_day: string | null
	b_year: string | null
	d_day: string | null
	d_year: string | null
}
