import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl";
import { Table, TTableOnFetch } from "@btc-alpha/ui-components"

import { TWithdraw } from "types/withdrawal"
import historyMessages from "messages/history"
import WithdrawalService from "services/WithdrawalService"

import { useColumns, useFilters } from "./hooks"

const Withdrawals = () => {
  const { formatMessage } = useIntl()

	const columns = useColumns()
	const filters = useFilters()

	const handleFetch: TTableOnFetch<TWithdraw> = async ({ page, limit, filters }) => {
		const { results, count } = await WithdrawalService.getPreviousWithdraws({
			page,
			"page-size": limit,
			currency: filters.currency,
		})
		return { data: results, count }
	}

	return (
		<Table
			columns={columns}
			filters={filters}
			empty={formatMessage(historyMessages.no_orders_to_show)}
			lazyLoad
			resetFilters
			defFetching
			footer="pagination"
			onFetch={handleFetch}
		/>
	)
}

export default observer(Withdrawals)
