import React from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Table, TTableOnFetch } from "@btc-alpha/ui-components"

import { TDeposit } from "types/deposit"
import historyMessages from "messages/history"
import DepositService from "services/DepositService"

import { useColumns, useFilters } from "./hooks"

const Deposits = () => {
	const { formatMessage } = useIntl()

	const columns = useColumns()
	const filters = useFilters()

	const handleFetch: TTableOnFetch<TDeposit> = async ({ page, limit, filters }) => {
		const { results, count } = await DepositService.getPreviousDeposits({
			currency: filters.currency,
			page,
			"page-size": limit,
		})
		return { data: results, count }
	}

	return (
		<Table<TDeposit>
			columns={columns}
			filters={filters}
			empty={formatMessage(historyMessages.no_orders_to_show)}
			lazyLoad
			resetFilters
			defFetching
			footer="pagination"
			onFetch={handleFetch}
		/>
	)
}

export default observer(Deposits)
