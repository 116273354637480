import { IProfileStatus } from "models/Account"

export type TLoginRequestBody = {
	email: string
	password?: string
	wizard_id?: string
	totp?: string
	token?: string
	pincode?: string
}

// export type TLoginResponse = {
//   uid: string
//   access_token: string
//   expires_in: number
// }

export enum EWizardStep {
	TOKEN = "token",
	PINCODE = "pincode",
	AUTH = "auth",
	RESTORE_2FA = "restore_2fa",
	RESTORE_PINCODE = "restore_pincode",
	RESTORE_PASSWORD = "restore_password",
}

export type TLoginResponse = {
	done?: {
		user: IProfileStatus
		token: string
		expire_at: string
	}
	form?: {
		fields: Array<{
			name: string
			label: string
			help_text: string
			required: boolean
			type: string
			length?: number
			delay?: string | null
			left?: number
		}>
		values: Record<string, string>
		errors?: Record<string, string[]>
		title?: string
		description?: string
	}
	wizard?: {
		id: string
		step: EWizardStep
		steps: EWizardStep[]
		passed_steps: EWizardStep[]
	}
}

export type TLoginResendPincodeResponse = {
	success: boolean
	delay?: string
	left?: number
}

export type TRegisterRequestBody = {
	email: string
	password: string
	account_type: number
}

export type TRegisterResponse = {
	token: string
}

export type TConfirmRequestBody = {
	code: string
	token: string
}

export interface IRegisterPersonalInfoResponse {
	name: string
	second_name: string
	issuing_country: string
}

export interface IRegisterPersonalInfoBody {
	name: string
	second_name: string
	issuing_country: string
}

export interface IRegisterJuridicalInfoBody {
	company_id: string
	company_name: string
	company_jurisdiction: string
	benefice_first_name: string
	benefice_last_name: string
	benefice_position: string
}

export interface IRegisterJuridicalInfoResponse {
	company_id: string
	company_name: string
	company_jurisdiction: string
	benefice_first_name: string
	benefice_last_name: string
	benefice_position: string
}

export interface IRestorePasswordBody {
  password: string
  wizard_id: string
}

export interface IResetPasswodRequestBody {
  email: string
  ecaptcha: string
}

export interface IResetPasswordBody {
  password: string
  password2: string
}
