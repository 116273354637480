export interface IAppLocale {
	name: string
	value: string
	country: string
}

export const appLocales: IAppLocale[] = [
	{ name: "English", value: "en", country: "us" },
	{ name: "Русский", value: "ru", country: "ru" },
	{ name: "Deutsch", value: "de", country: "de" },
	{ name: "Español", value: "es", country: "es" },
	{ name: "Français", value: "fr", country: "fr" },
	{ name: "हिन्दी", value: "hi", country: "in" },
	{ name: "Português", value: "pt", country: "pt" },
	{ name: "Türkçe", value: "tr", country: "tr" },
	{ name: "简体中文", value: "zh", country: "cn" },
	{ name: "Українська", value: "uk", country: "ua" },
]

export const defaultLocale = "en"

export const availableLocales = appLocales.map(i => i.value)

export const translationMessages = async (locale: string) => {
	const values: Record<string, unknown> = {}

	return Promise.all([import(/* webpackChunkName: `locale` */ `../../lang/${locale}.json`)])
		.then(locales => {
			values[locale] = locales[0].default

			return values
		})
		.catch(err => Error(`Error while fetching locale data: ${err}`))
}
