import axios, {
	AxiosError,
	AxiosRequestConfig,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios"
import cookies from "js-cookie"
import { messageError, messageInfo } from "@btc-alpha/ui-components"

import appConfig from "helpers/config"
import { LOCALE_CACHE_KEY } from "utils/cacheKeys"
import { defaultLocale } from "providers/LanguageProvider/i18n"
import { TResponseType, TRequestMethod, IError } from "types/apiClient"
import cache from "./cache"

const API_PREFIX = appConfig.apiPrefix
const CSRF_COOKIE_NAME = appConfig.csrfCookieName
const SESSION_COOKIE_NAME = appConfig.sessionCookieName

const apiInstance = axios.create({
	baseURL: API_PREFIX,
	withCredentials: true,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
})

const getDetails = (obj: Record<string, unknown>): string => {
	// const d = obj[Object.keys(obj)?.length ? Object.keys(obj)[0] : "details"]
	// while (typeof d === "object") {
	//   return getDetails(d as Record<string, unknown>)
	// }

	const key = Object.keys(obj)?.length ? Object.keys(obj)[0] : "details"
	const d = obj[key]

	if (typeof d === "object" && d !== null) {
		return getDetails(d as Record<string, unknown>)
	}

	return typeof d === "string" ? d : "Server error"
}

let isUnauthorized = false

apiInstance.interceptors.response.use(
	(response: AxiosResponse) => response.data || response,
	(error: AxiosError) => {
		const status = error.response?.status ?? 0

		if ([401, 403].includes(status)) {
			if (!isUnauthorized) {
				isUnauthorized = true
				cookies.remove(appConfig.sessionCookieName)

				messageInfo(status === 401 ? "Unauthorized [401]" : "Access denied [403]")

				setTimeout(() => {
					const locale = cache.getItem(LOCALE_CACHE_KEY, defaultLocale)
					window.location.href = `/${locale}/login`
				}, 2000)
			}

			return Promise.resolve()
		}

		const originalError = error.response?.data || error
		const details = { ...originalError }
		const message =
			typeof details === "object"
				? getDetails(details as Record<string, unknown>)
				: error.message || "Server error"

		if (appConfig.isDebug) {
			console.error("API error: ", error.response)
		}

		const apiError: IError = {
			data: originalError,
			status: status,
			message: message,
		}

		if (apiError.status === 0 || apiError.status >= 500) {
			messageError("Server error")
		} else if (apiError.status === 404) {
			messageError("Not Found")
		} else if (apiError.status === 401) {
			messageError(message)
		}

		return Promise.reject(apiError)
	},
)

apiInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const sessionToken = cookies.get(SESSION_COOKIE_NAME)
		const csrfToken = cookies.get(CSRF_COOKIE_NAME)

		if (config.headers) {
			if (sessionToken) {
				config.headers.Authorization = `Bearer ${sessionToken}`
			}
			if (csrfToken) {
				config.headers["X-CSRFToken"] = csrfToken
			}
		}

		config.headers["accept-language"] = cache.getItem(LOCALE_CACHE_KEY, defaultLocale)

		return config
	},
	error => Promise.reject(error),
)

const request = async (
	url: string,
	method: TRequestMethod,
	data: any,
	params = null,
	headers = {},
	responseType: TResponseType = "json",
): Promise<any> => {
	const config: AxiosRequestConfig = {
		method,
		params,
		url,
		data,
		responseType,
		headers,
	}

	return apiInstance(config)
}

export default class ApiClient {
	static get = async (
		url: string,
		params: any = null,
		headers: Record<string, string> = {},
		responseType?: TResponseType,
	) => request(url, "GET", null, params, headers, responseType)

	static post = async (
		url: string,
		data?: unknown,
		params?: any,
		headers: Record<string, string> = {},
	) => request(url, "POST", data, params, headers)

	static put = async (url: string, data?: any, params: any = null) =>
		request(url, "PUT", data, params)

	static patch = async (
		url: string,
		data?: any,
		params: any = null,
		headers: Record<string, string> = {},
	) => request(url, "PATCH", data, params, headers)

	static delete = async (url: string, data?: any, headers: any = {}) =>
		request(url, "DELETE", data, null, headers)
}

// @TODO: don't remove. these code will be used for new Auth service:
// const API_AUTH_PREFIX = appConfig.apiAuthPrefix
// class Client {
//  private axios: AxiosInstance ...
//
// const ApiAuthClient = new Client({
// 	prefix: API_AUTH_PREFIX,
// 	onRequest: config => {
// 		if (config.headers) {
// 			const sessionToken = cookies.get(SESSION_COOKIE_NAME)
// 			if (sessionToken) config.headers.Authorization = `Bearer ${sessionToken}`
// 		}
// 		return config
// 	},
// })
// export { ApiAuthClient }