import ApiClient from "helpers/ApiClient"
import errorHandler from "utils/errorHandler"
import {
	ICreateDepositReq,
	ICreateDepositRes,
	IGetDepositHistoryParams,
	IGetDepositHistoryResponse,
	IGetDepositMethodsParams,
	TGetDepositMethodsResponse,
} from "types/deposit"

const DepositService = {
	depositMethodsInit: (params: IGetDepositMethodsParams): Promise<TGetDepositMethodsResponse> =>
		ApiClient.get("web/deposit/methods", params),
	getPreviousDeposits: (params?: IGetDepositHistoryParams): Promise<IGetDepositHistoryResponse> =>
		ApiClient.get("web/deposit/deposits", params),
	createDeposit: async (data: ICreateDepositReq): Promise<any> => {
		try {
			const result: ICreateDepositRes = await ApiClient.post("web/deposit/request", data)
			window.location.replace(result.redirect_url)
		} catch (err) {
			errorHandler(err)
		}
	},
}

export const createDeposit = (data: ICreateDepositReq) => DepositService.createDeposit(data)

export default DepositService
