/* eslint-disable no-unused-expressions,jsx-a11y/control-has-associated-label */
import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { Button, useCopyClick } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import commonMessages from "messages/common"
import accountMessages from "messages/account"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import useAccount from "hooks/useAccount"
import { useTradingFees } from "services/TradingFeesService"
import { MAX_PRICE_PRECISION, USD_PRECISION } from "utils/constants"

import { getPageTitle } from "helpers/global"
import RedesignedLayout from "layouts/RedesignedLayout"
import LoadingSpinner from "components/UI/LoadingSpinner"

import IconDatabase from "assets/icons/menu/database.svg"
import IconBot from "assets/icons/bot.svg"

import styles from "./walletOverview.module.scss"
import { EAccountType } from "../Register/types"

const WalletOverview = () => {
	const { formatNumber, formatMessage } = useIntl()
	const {
		account: { totalBalance, isBalancesLoaded },
		finance: { isBalancesVisible, setIsBalancesVisible },
	} = useMst()
	const { data: tradingFees } = useTradingFees()
	const { character, name, uid, type } = useAccount()
	const copy = useCopyClick()

	const handleCopyUidClick = () => uid && copy(uid)
	const handleVisibilityClick = () => setIsBalancesVisible(!isBalancesVisible)

	const estimatedBalance = isBalancesVisible
		? formatNumber(totalBalance.USDT, {
				useGrouping: true,
				minimumFractionDigits: USD_PRECISION,
				maximumFractionDigits: USD_PRECISION,
		  })
		: STARS_STR

	const btcRate = totalBalance.BTC / totalBalance.USDT || 0

	const fundAmount = isBalancesVisible
		? formatNumber(totalBalance.USDT, {
				useGrouping: true,
				minimumFractionDigits: USD_PRECISION,
				maximumFractionDigits: USD_PRECISION,
		  })
		: STARS_STR

	const fundAmountBtc = isBalancesVisible
		? formatNumber(totalBalance.USDT * btcRate, {
				useGrouping: true,
				minimumFractionDigits: MAX_PRICE_PRECISION,
				maximumFractionDigits: MAX_PRICE_PRECISION,
		  })
		: STARS_STR

	return (
		<RedesignedLayout
			title={formatMessage(financeMessages.overview)}
			helmet={{ title: getPageTitle(formatMessage(financeMessages.overview)) }}
			className={styles.walletOverview}
		>
			{!isBalancesLoaded ? (
				<LoadingSpinner />
			) : (
				<>
					<div className={styles.account}>
						<img src={character} alt="" className={styles.character} />
						{!!name && (
							<div className={styles.info}>
								<div>{formatMessage(commonMessages.welcome_back)},</div>
								<div className={styles.name}>
									<span>{name}</span>
								</div>
							</div>
						)}
						<div className={styles.infos}>
							{!!uid && (
								<div className={styles.info}>
									<div>{formatMessage(commonMessages.user_uid)}</div>
									<div>
										<span>{uid}</span>
										<Button
											size="mini"
											kind="clear"
											icon="ai-copy_new"
											iconSize={12}
											onClick={handleCopyUidClick}
										/>
									</div>
								</div>
							)}
							<div className={styles.info}>
								<div>{formatMessage(commonMessages.vip_level)}</div>
								<div>
									<span>{tradingFees?.personal?.fee_tier?.name ?? "VIP ?"}</span>
								</div>
							</div>
							<div className={styles.info}>
								<div>{formatMessage(accountMessages.user_type)}</div>
								<div>
									<span>
										{formatMessage(
											type === EAccountType.JURIDICAL
												? commonMessages.business
												: commonMessages.personal,
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.wallet}>
						<div className={styles.estimated}>
							<div>
								<span>{formatMessage(financeMessages.estimated_balance)}</span>
								<Button
									size="mini"
									kind="clear"
									icon={isBalancesVisible ? "ai-eye_disabled" : "ai-eye"}
									iconSize={16}
									title={formatMessage(
										isBalancesVisible ? financeMessages.hide_balance : financeMessages.show_balance,
									)}
									onClick={handleVisibilityClick}
								/>
							</div>
							<div className={styles.amount}>${estimatedBalance}</div>
						</div>
						<div className={styles.actions}>
							<Button
								caption={formatMessage(commonMessages.deposit)}
								icon="ai-credit-card-upload"
								size="mini"
								to={routes.profile.createDeposit}
							/>
							<Button
								caption={formatMessage(commonMessages.withdraw)}
								icon="ai-credit-card-download"
								size="mini"
								kind="outlined"
								to={routes.profile.createWithdraw}
							/>
						</div>
					</div>
					<h2>{formatMessage(accountMessages.accounts)}</h2>
					<h4>{formatMessage(accountMessages.my_assets)}</h4>
					<Button kind="secondary" className={styles.asset} to={routes.profile.wallets}>
						<div className={styles.caption}>
							<img src={IconDatabase} alt={formatMessage(financeMessages.funding)} />
							<span>{formatMessage(financeMessages.wallet_spot)}</span>
						</div>
						<div className={styles.balance}>
							<div>${fundAmount}</div>
							<div>≈ {fundAmountBtc} BTC</div>
						</div>
						<div className={styles.actions}>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-credit-card-upload"
								title={formatMessage(commonMessages.deposit)}
								to={routes.profile.createDeposit}
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-credit-card-download"
								title={formatMessage(commonMessages.withdraw)}
								to={routes.profile.createWithdraw}
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-trade-candle-01"
								title={formatMessage(commonMessages.trade)}
								to={routes.trade.root}
							/>
						</div>
					</Button>
					<h4>{formatMessage(accountMessages.invested_products)}</h4>
					<Button kind="secondary" disabled className={styles.asset}>
						<div className={styles.caption}>
							<img src={IconBot} alt={formatMessage(financeMessages.trading_bot)} />
							<span>{formatMessage(financeMessages.trading_bot)}</span>
						</div>
						<div className={styles.balance}>
							<div>$0.00</div>
							<div>≈ 0.00 BTC</div>
						</div>
						<div className={styles.actions}>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-credit-card-upload"
								title={formatMessage(commonMessages.deposit)}
								disabled
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-trade-candle-01"
								title={formatMessage(commonMessages.trade)}
								disabled
							/>
						</div>
						<div className={styles.soon}>
							<span>{formatMessage(commonMessages.futures_description)}</span>
						</div>
					</Button>
				</>
			)}
		</RedesignedLayout>
	)
}

export default observer(WalletOverview)
