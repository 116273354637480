export enum EOrderStatus {
	OPEN = "OPEN",
	PARTIAL_CANCELED = "PARTIAL_CANCELED",
	PARTIAL_CANCELLED = "PARTIAL_CANCELLED",
	PARTIAL_FILLED = "PARTIAL_FILLED",
	PENDING = "PENDING",
	CANCELLED = "CANCELLED",
	EXPIRED = "EXPIRED",
	FAILED = "FAILED",
	FILLED = "FILLED",
}

export enum EOrderSide {
	BUY = "BUY",
	SELL = "SELL",
}

export enum EOrderNumberSide {
	SELL = 1,
	BUY = 2,
}

export enum EOrderType {
	LIMIT = "LIMIT",
	MARKET = "MARKET",
	STOP_LIMIT = "STOP_LIMIT",
	STOP_ORDER = "STOP_ORDER",
	STOP_MARKET = "STOP_MARKET",
}

export enum EOrderNumberType {
	UNDEFINED = 0,
	MARKET = 1,
	LIMIT = 2,
	STOP_LIMIT = 3,
	STOP_MARKET = 4,
}
