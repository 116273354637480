/* eslint-disable no-unused-expressions */
import React from "react"
import { useIntl } from "react-intl"
import {
	CurrencyIcon,
	Ellipser,
	TTableColumns,
	TTableFilters,
	ETableFilterType,
	useCopyClick,
	useShare,
} from "@btc-alpha/ui-components"

import { EWithdrawalStatuses, TWithdraw } from "types/withdrawal"
import { useMst } from "models/Root"
import commonMessages from "messages/common"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import { formatDate } from "utils/formatDate"
import { splitWords } from "utils/format"
import { capitalizeFirstLetter } from "utils/reportUtils"

import Badge, { BadgeColorEnum } from "components/UI/Badge"

import styles from "./withdrawals.module.scss"

const statusColor: Record<EWithdrawalStatuses, BadgeColorEnum> = {
	[EWithdrawalStatuses.NEW]: BadgeColorEnum.BLUE,
	[EWithdrawalStatuses.CONFIRMED]: BadgeColorEnum.ORANGE,
	[EWithdrawalStatuses.VERIFIED]: BadgeColorEnum.ORANGE,
	[EWithdrawalStatuses.PROCESSING]: BadgeColorEnum.ORANGE,
	[EWithdrawalStatuses.DONE]: BadgeColorEnum.GREEN,
	[EWithdrawalStatuses.REFUSED]: BadgeColorEnum.RED,
	[EWithdrawalStatuses.CANCELLED]: BadgeColorEnum.GREY,
}

export const useColumns = (): TTableColumns<TWithdraw> => {
	const { formatNumber, formatMessage } = useIntl()
	const share = useShare()
	const copy = useCopyClick()

	const extractAddress = (withdraw: TWithdraw): string =>
		withdraw.attributes?.find(({ label }) => label.toLowerCase() === "address")?.value || ""

	return [
		{
			key: "date",
			caption: formatMessage(historyMessages.date_and_time),
			render: ({ date }) => formatDate(date),
		},
		{
			key: "currency",
			caption: formatMessage(historyMessages.coin),
			className: styles.currency,
			render: ({ currency }) => (
				<>
					<CurrencyIcon name={currency.code} size={20} />
					<span>{currency.code}</span>
				</>
			),
		},
		{ key: "payment_method_name", caption: formatMessage(historyMessages.chain_type), width: 150 },
		{
			key: "amount",
			caption: formatMessage(historyMessages.quantity),
			render: ({ amount, currency }) =>
				`${formatNumber(+amount, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 8,
				})} ${currency.code}`,
		},
		{
			key: "fee_amount",
			caption: formatMessage(historyMessages.fee),
			render: ({ fee_amount }) =>
				formatNumber(+fee_amount, { minimumFractionDigits: 2, maximumFractionDigits: 8 }),
		},
		{
			key: "address",
			caption: formatMessage(historyMessages.address),
			render: row => <Ellipser value={extractAddress(row)} middle={4} style={{ width: 150 }} />,
			actions: () => [
				{
					icon: "ai-share_outline",
					visible: !!share,
					onClick: row => share?.({ text: extractAddress(row) }),
				},
				{
					icon: "ai-copy_new",
					onClick: row => copy(extractAddress(row)),
				},
			],
		},
		{
			key: "txid",
			caption: formatMessage(historyMessages.txid),
			render: ({ txid }) => (txid?.length ? <Ellipser value={txid} style={{ width: 90 }} /> : "–"),
			actions: ({ txid }) =>
				!!txid && [
					{
						icon: "ai-share_outline",
						visible: !!share,
						onClick: ({ txid }) => share?.({ text: txid || "" }),
					},
					{
						icon: "ai-copy_new",
						onClick: ({ txid }) => copy(txid || ""),
					},
				],
		},
		{
			key: "note",
			caption: formatMessage(commonMessages.note),
			width: "15%",
			render: ({ note }) => note || "–",
		},
		{
			key: "status",
			width: 50,
			caption: formatMessage(historyMessages.orders_table_status),
			render: ({ status }) => (
				<Badge color={statusColor[status.id] || BadgeColorEnum.GREY_DARK} alpha>
					{splitWords(capitalizeFirstLetter(status.label))}
				</Badge>
			),
		},
	]
}

export const useFilters = (): TTableFilters<TWithdraw> => {
	const { formatMessage } = useIntl()
	const {
		account: { balances },
	} = useMst()

	return [
		{
			key: "currency",
			type: ETableFilterType.SELECT,
			items: balances?.map(({ code, name }) => ({ value: code, label: code, description: name })),
			caption: formatMessage(exchangeMessages.coin),
			clearable: true,
		},
	]
}
