/* eslint-disable no-unused-expressions */
import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import {
	ETableColumnAlign,
	ETableFilterType,
	TTableColumns,
	TTableFilters,
	CurrencyIcon,
} from "@btc-alpha/ui-components"

import { FORMAT_NUMBER_OPTIONS_USD } from "constants/format"
import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION } from "utils/constants"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import { IBalance } from "models/Account"
import { useRatesUSDT } from "services/AccountService"

import styles from "./wallets.module.scss"

const getFormatNumberOptions = (precision: number | null) => ({
	useGrouping: true,
	minimumFractionDigits: Math.min(MIN_PRICE_PRECISION, precision || MAX_PRICE_PRECISION),
	maximumFractionDigits: precision || MAX_PRICE_PRECISION,
})

export const useColumns = (): TTableColumns<IBalance> => {
	const { formatNumber, formatMessage } = useIntl()
	const {
		finance: { isBalancesVisible },
	} = useMst()

	const { data } = useRatesUSDT()
	const rates = useMemo(() => {
		const result = new Map<string, number>()
		data?.forEach(({ currency, rate }) => result.set(currency, rate))
		return result
	}, [data])

	const renderVisibility = (value: number | string) => (isBalancesVisible ? value : STARS_STR)

	const renderConverted = (code: string, value: string | number) =>
		`≈ $${renderVisibility(
			formatNumber(+value * (rates.get(code) || 0), FORMAT_NUMBER_OPTIONS_USD),
		)}`

	return [
		{
			key: "code",
			caption: formatMessage(financeMessages.currency),
			align: ETableColumnAlign.MIDDLE,
			className: styles.currency,
			render: ({ code, name }) => (
				<>
					<CurrencyIcon name={code} size={32} />
					<div>
						<span>{code}</span>
						<span>{name}</span>
					</div>
				</>
			),
		},
		{
			key: "balance",
			caption: formatMessage(financeMessages.equity),
			render: ({ balance, precision }) =>
				renderVisibility(formatNumber(+balance, getFormatNumberOptions(precision))),
			subline: ({ code, balance }) => renderConverted(code, balance),
		},
		{
			key: "available",
			caption: formatMessage(financeMessages.available),
			render: ({ available, precision }) =>
				renderVisibility(formatNumber(available, getFormatNumberOptions(precision))),
			subline: ({ code, available }) => renderConverted(code, available),
		},
		{
			key: "reserve",
			caption: formatMessage(financeMessages.frozen),
			render: ({ reserve, precision }) =>
				renderVisibility(formatNumber(+reserve, getFormatNumberOptions(precision))),
			subline: ({ code, reserve }) => renderConverted(code, reserve),
		},
		{
			key: "actions",
			align: ETableColumnAlign.MIDDLE,
			width: 1,
			caption: formatMessage(commonMessages.action),
			className: styles.actions,
			actions: ({ code, available, is_deposit_enabled, is_withdraw_enabled }) => [
				{
					icon: "ai-credit-card-upload",
					caption: formatMessage(commonMessages.deposit),
					disabled: !is_deposit_enabled,
					to: routes.profile.getDepositCurrency(code),
				},
				{
					icon: "ai-credit-card-download",
					disabled: !available || !is_withdraw_enabled,
					to: routes.profile.getWithdrawCurrency(code),
				},
			],
		},
	]
}

export const useFilters = (): TTableFilters<IBalance> => {
	const { formatMessage } = useIntl()
	return [
		{
			key: "code",
			type: ETableFilterType.STRING,
			icon: "ai-search",
			placeholder: formatMessage(commonMessages.search),
			clearable: true,
		},
		{
			key: "balance",
			type: ETableFilterType.CHECKBOX,
			caption: formatMessage(financeMessages.hide_empty_balances),
			toRight: true,
			filter: (checked, { balance }) => !checked || !!+balance,
		},
	]
}
