import { IBalance, IRateAlgo } from "models/Account";
import { ITicker } from "models/Ticker";
import { AccountTypeEnum, RateAlgoPlanEnum } from "types/account";

/**
 * Format the given balance object by ensuring that `borrowed`, `interest`,
 * and `balance` fields are initialized with default values if they are missing.
 *
 * @param {IBalance} balance - The balance object to be formatted.
 * @returns {IBalance} - Returns the formatted balance.
 */
export const formatBalance = (balance: IBalance): IBalance => ({
	...balance,
	borrowed: balance.borrowed ?? "0",
	interest: balance.interest ?? "0",
	balance: balance.balance ?? "0",
});

/**
 * Format the given balances by filtering out demo balances and then applying
 * a formatting operation.
 *
 * @param {IBalance[]} balances - Array of balances to be formatted.
 * @returns {IBalance[]} - Returns the formatted balances.
 */
export const formatBalances = (balances: IBalance[]): IBalance[] =>
	balances.filter((b) => !b.is_demo).map(formatBalance);

/**
 * Retrieve the balance based on account type, code, and pair.
 *
 * @param {string} code - The currency code (e.g., "BTC").
 * @param {string} pair - The currency pair (e.g., "BTC/USDC").
 * @param {AccountTypeEnum} type - Type of the account. Defaults to SPOT.
 * @param {IBalance[]} balances - Balances for the SPOT account.
 * @param {IBalance[]} balancesCross - Balances for the CROSS account.
 * @param {IBalance[]} balancesIsolated - Balances for the ISOLATED account.
 * @returns {IBalance | null} - Returns the matching balance or null if not found.
 */
export const getBalance = (
	code: string,
	pair: string,
	type: AccountTypeEnum = AccountTypeEnum.SPOT,
	balances: IBalance[],
	balancesCross: IBalance[],
	balancesIsolated: IBalance[],
) => {
	// Create a mapping to associate each AccountTypeEnum to its corresponding balance array
	const balanceMapping: Record<AccountTypeEnum, IBalance[]> = {
		[AccountTypeEnum.SPOT]: balances,
		[AccountTypeEnum.CROSS]: balancesCross,
		[AccountTypeEnum.ISOLATED]: balancesIsolated,
	};

	// Select the appropriate balance array based on the provided type
	const selectedBalances = balanceMapping[type];

	// If the type is ISOLATED, we need to consider the currency pair as well
	if (type === AccountTypeEnum.ISOLATED) {
		return selectedBalances.find((b) => b.code === code && b.pair?.replace("/", "_") === pair);
	}

	// For other types
	return selectedBalances.find((b) => b.code === code) ?? null;
};

/**
 * Retrieve the list of filled balances based on the account type.
 *
 * @param {AccountTypeEnum} type - Type of the account. Defaults to SPOT.
 * @param {IBalance[]} balancesFilled - Filled balances for the SPOT account.
 * @param {IBalance[]} balancesCrossFilled - Filled balances for the CROSS account.
 * @param {IBalance[]} balancesIsolatedFilled - Filled balances for the ISOLATED account.
 * @returns {IBalance[]} - Returns the array of filled balances corresponding to the account type.
 */
export const getBalancesFilled = (
	type: AccountTypeEnum = AccountTypeEnum.SPOT,
	balancesFilled: IBalance[],
	balancesCrossFilled: IBalance[],
	balancesIsolatedFilled: IBalance[],
): IBalance[] => {
	// Create a mapping to associate each AccountTypeEnum to its corresponding filled balance array
	const filledBalancesMapping: Record<AccountTypeEnum, IBalance[]> = {
		[AccountTypeEnum.SPOT]: balancesFilled,
		[AccountTypeEnum.CROSS]: balancesCrossFilled,
		[AccountTypeEnum.ISOLATED]: balancesIsolatedFilled,
	};

	// Return the filled balances array based on the provided type
	return filledBalancesMapping[type];
};

export const convertRatePrice = (plan: IRateAlgo, tickers: ITicker[] = []): number => {
	if (!tickers?.length) {
		return 0;
	}

	const getTickerPrice = (symbol: string): number => {
		const ticker = tickers.find((t: ITicker) => t.symbol === symbol);
		return ticker?.close ?? plan.rate ?? 0;
	};

	return getPriceByRate(
		plan.type as RateAlgoPlanEnum,
		getTickerPrice(plan.params?.[0] ?? ""),
		getTickerPrice(plan.params?.[1] ?? ""),
	);
};

export const getPriceByRate = (
	planType: RateAlgoPlanEnum,
	firstPrice: number,
	secondPrice: number,
) => {
	switch (planType) {
		case RateAlgoPlanEnum.CONSTANT:
			return 1;
		case RateAlgoPlanEnum.DIRECT:
			return firstPrice;
		case RateAlgoPlanEnum.DIV: {
			const hasPrice = firstPrice !== 0 && secondPrice !== 0;

			return hasPrice ? firstPrice / secondPrice : 0;
		}
		case RateAlgoPlanEnum.MUL: {
			const hasPrice = firstPrice !== 0 && secondPrice !== 0;

			return hasPrice ? firstPrice * secondPrice : 0;
		}
		case RateAlgoPlanEnum.REVERSED: {
			return firstPrice !== 0 ? 1 / firstPrice : 0;
		}
		case RateAlgoPlanEnum.MUL_REVERSED: {
			const hasPrice = firstPrice !== 0 && secondPrice !== 0;

			return hasPrice ? 1 / (firstPrice * secondPrice) : 0;
		}
		default:
			return 0;
	}
};

export const convertCurrency = (
	price: number,
	currency: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rates: any,
	quoteCurrencyCode: string,
): number => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const crate = rates?.find((rate: any) => rate.currency === quoteCurrencyCode);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const targetRate = crate?.rates?.find((rate: any) => rate.currency === currency)?.rate;
	const rate = targetRate ?? 1;
	return rate * price;
};

// Функция для вычисления общего размера счета на основе списка балансов
export const totalEquityBalance = (
	balances: IBalance[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rates: any,
	quoteCurrencyCode: string,
): number =>
	balances.reduce(
		(total, b) => total + convertCurrency(Number(b.total), b.code, rates, quoteCurrencyCode),
		0,
	);
