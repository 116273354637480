/* eslint-disable no-unused-expressions */
import React from "react"
import { useIntl } from "react-intl"
import {
	Ellipser,
	CurrencyIcon,
	ETableFilterType,
	TTableColumns,
	TTableFilters,
	useCopyClick,
	useShare,
} from "@btc-alpha/ui-components"

import { EDepositStatus, TDeposit } from "types/deposit"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION } from "utils/constants"
import { useMst } from "models/Root"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import { formatDate } from "utils/formatDate"
import { splitWords } from "utils/format"
import { capitalizeFirstLetter } from "utils/reportUtils"

import Badge, { BadgeColorEnum } from "components/UI/Badge"

import styles from "./deposits.module.scss"

const statusColor: Record<EDepositStatus, BadgeColorEnum> = {
	[EDepositStatus.PENDING]: BadgeColorEnum.ORANGE,
	[EDepositStatus.CONFIRMED]: BadgeColorEnum.GREEN,
	[EDepositStatus.MODERATION]: BadgeColorEnum.GREY,
	[EDepositStatus.REJECTED]: BadgeColorEnum.GREY_DARK,
}

export const useColumns = (): TTableColumns<TDeposit> => {
	const { formatNumber, formatMessage } = useIntl()
	const share = useShare()
	const copy = useCopyClick()

	return [
		{
			key: "date",
			caption: formatMessage(historyMessages.date_and_time),
			render: ({ date }) => formatDate(date),
		},
		{
			key: "currency",
			caption: formatMessage(historyMessages.coin),
			className: styles.currency,
			render: ({ currency }) => (
				<>
					{currency?.code && <CurrencyIcon name={currency?.code} />}
					<span>{currency?.code}</span>
				</>
			),
		},
		{ key: "payment_method_name", caption: formatMessage(historyMessages.chain_type) },
		{
			key: "amount",
			caption: formatMessage(historyMessages.quantity),
			render: ({ amount }) =>
				formatNumber(+amount, {
					minimumFractionDigits: MIN_PRICE_PRECISION,
					maximumFractionDigits: MAX_PRICE_PRECISION,
				}),
		},
		// {
		// 	key: "to_address",
		// 	caption: formatMessage(historyMessages.address),
		// 	render: ({ to_address }) =>
		// 		to_address?.length ? (
		// 			<Ellipser value={to_address} middle={4} style={{ width: 150 }} />
		// 		) : (
		// 			"–"
		// 		),
		// 	actions: ({ to_address }) =>
		// 		!!to_address && [
		// 			{
		// 				icon: "ai-share_outline",
		// 				visible: !!share,
		// 				onClick: ({ to_address }) => share?.({ text: to_address || "" }),
		// 			},
		// 			{
		// 				icon: "ai-copy_new",
		// 				onClick: ({ to_address }) => copy(to_address || ""),
		// 			},
		// 		],
		// },
		{
			key: "txid",
			caption: formatMessage(historyMessages.txid),
			render: ({ txid }) =>
				txid?.length ? <Ellipser value={txid} middle={3} style={{ width: 130 }} /> : "–",
			actions: ({ txid }) =>
				!!txid && [
					{
						icon: "ai-share_outline",
						visible: !!share,
						onClick: ({ txid }) => share?.({ text: txid || "" }),
					},
					{
						icon: "ai-copy_new",
						onClick: ({ txid }) => copy(txid || ""),
					},
				],
		},
		{
			key: "confirmations",
			caption: formatMessage(historyMessages.confirmations),
		},
		{
			key: "status",
			width: 50,
			caption: formatMessage(historyMessages.orders_table_status),
			render: ({ status }) => (
				<Badge color={statusColor[status.id] || BadgeColorEnum.GREY_DARK} alpha>
					{splitWords(capitalizeFirstLetter(status.label))}
				</Badge>
			),
		},
	]
}

export const useFilters = (): TTableFilters<TDeposit> => {
	const { formatMessage } = useIntl()
	const {
		account: { balances },
	} = useMst()

	return [
		{
			key: "currency",
			type: ETableFilterType.SELECT,
			items: balances?.map(({ code, name }) => ({ value: code, label: code, description: name })),
			caption: formatMessage(exchangeMessages.coin),
			clearable: true,
		},
	]
}
