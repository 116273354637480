import React from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import Confetti from "react-confetti"
import { Button } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import accountMessages from "messages/account"
import AuthService from "services/AuthService"
import useAccount from "hooks/useAccount"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import { useRegisterContext } from "pages/Register/context"

import RedesignedAuthLayout from "layouts/RedesignedAuthLayout"

import Header from "../components/Header"

import styles from "./regWelcome.module.scss"

const RegWelcome: React.FC = () => {
	const { formatMessage } = useIntl()
	const { setIsAuthenticated } = useAccount()
	const localeNavigate = useLocaleNavigate()
	const { response } = useRegisterContext()

	const submitFinishStep = async () => {
		await AuthService.complete(response?.slug || "")
		setIsAuthenticated(true)
		localeNavigate(routes.trade.root)
	}

	return (
		<RedesignedAuthLayout
			title={formatMessage(accountMessages.welcome_to_crypto_trading)}
			description={formatMessage(accountMessages.professional_platform_for_trading)}
		>
			<div className={styles.root}>
				<Header
					headline={formatMessage(accountMessages.welcome_to_btc_alpha)}
					description={formatMessage(accountMessages.embark_on_crypto_journey)}
				/>

				<Confetti
					width={window.innerWidth}
					height={window.innerHeight}
					numberOfPieces={100}
					gravity={0.03}
				/>

				<Button
					caption={formatMessage(accountMessages.start_trading)}
					className={styles.submit}
					onClick={submitFinishStep}
				/>
			</div>
		</RedesignedAuthLayout>
	)
}

export default observer(RegWelcome)
