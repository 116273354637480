import React, { useMemo } from "react"
import { Route, Routes } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Tabs, TTabsItem } from "@btc-alpha/ui-components"

import { routes, URL_VARS } from "constants/routing"
import transfersMessages from "messages/transfers"
import historyMessages from "messages/history"
import useRouting from "hooks/useRouting"

import { getPageTitle } from "helpers/global"
import RedesignedLayout from "layouts/RedesignedLayout"

import Deposits from "./Deposits"
import Withdrawals from "./Withdrawals"
import Transfers from "./Transfers"

import styles from "./financeHistory.module.scss"

const FinanceHistory: React.FC = () => {
	const { formatMessage } = useIntl()
	const { route, navigateRoute } = useRouting({ routes: routes.financeHistory })

	const tabs = useMemo<
		Array<TTabsItem<NonNullable<typeof route>> & { path?: string; Component: React.FC }>
	>(
		() => [
			// {
			// 	key: "root",
			// 	label: formatMessage(historyMessages.all_transactions),
			// 	separated: true,
			// },
			{
				key: "root",
				label: formatMessage(historyMessages.deposits),
				path: URL_VARS.ROOT,
				Component: Deposits,
			},
			{
				key: "withdraws",
				label: formatMessage(historyMessages.withdraws),
				Component: Withdrawals,
			},
			{
				key: "transfers",
				label: formatMessage(transfersMessages.transfers),
				Component: Transfers,
			},
		],
		[formatMessage],
	)

	return (
		<RedesignedLayout
			title={formatMessage(historyMessages.history)}
			helmet={{ title: getPageTitle(formatMessage(historyMessages.history)) }}
			className={styles.financeHistory}
		>
			<Tabs items={tabs} selected={route} onClick={navigateRoute} />
			<div className={styles.table}>
				<Routes>
					{tabs.map(({ key, path, Component }) => (
						<Route key={`history-${key}`} path={path || key} element={<Component />} />
					))}
				</Routes>
			</div>
		</RedesignedLayout>
	)
}

export default observer(FinanceHistory)
