import React from "react"

import Navigation from "layouts/RedesignedLayout/navigation";
import AboutUsHelmet from "components/NewUI/Helmets/AboutUs"
import FooterLarge from "components/NewUI/Footer/Large"
import Banner from "components/NewUI/AboutUs/Banner"
import Wallet from "components/NewUI/AboutUs/Wallet"
import Cards from "components/NewUI/AboutUs/Cards"
import Team from "components/NewUI/AboutUs/Team"
import Question from "components/NewUI/AboutUs/Question"
import History from "components/NewUI/AboutUs/History"
// import Map from "components/NewUI/AboutUs/Map"
import Cookies from "components/Cookies"
import Partners from "components/NewUI/AboutUs/Partners"

const AboutUs: React.FC = () => (
	<>
		<AboutUsHelmet />

    <Navigation />

		<main>
			<Banner />
			<Wallet />
			<Cards />
			<History />
			<Team />
			{/*<Partners />*/}
			{/*<Map />*/}
			<Question />
		</main>

		<FooterLarge />
		<Cookies />
	</>
)

export default AboutUs
