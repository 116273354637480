import React, { PropsWithChildren } from "react"

import RedesignedLayout from "layouts/RedesignedLayout"

import styles from "./redesignedAuthLayout.module.scss"

type TProps = {
	title: string
	description: string
}

const RedesignedAuthLayout: React.FC<PropsWithChildren<TProps>> = ({
	title,
	description,
	children,
}) => (
	<RedesignedLayout className={styles.content} layoutClassName={styles.layout}>
		<div className={styles.about}>
			<div className={styles.subtitle}>Welcome to BTC-Alpha</div>
			<div className={styles.title}>{title}</div>
			<div className={styles.description}>{description}</div>
		</div>
		{children}
	</RedesignedLayout>
)

export default RedesignedAuthLayout
