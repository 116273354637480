import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cn from "classnames"

import { useMst } from "models/Root"
import { QUOTED_CURRENCIES_FIAT } from "models/Ticker"
import { TickersQuotedEnum } from "types/ticker"
import { queryVars } from "constants/query"
import useAutoFetch from "hooks/useAutoFetch"
import useBanned from "hooks/useBanned"
import messages from "messages/common"
import exchangeMessages from "messages/exchange"

import Navigation from "layouts/RedesignedLayout/navigation"
// import News from "components/NewUI/Terminal/News"
import Cookies from "components/Cookies"
import WebSocket, { WSListenEventEnum } from "components/WebSocket"
import Helmet from "components/NewUI/Helmets/Terminal"
import Favorites from "components/NewUI/Terminal/Favorites"
import Trading from "components/NewUI/Terminal/Trading"
import Orders from "components/NewUI/Terminal/Orders"
import RecentTrades from "components/NewUI/Terminal/RecentTrades"
import Footer from "components/NewUI/Footer"
import Bottom from "components/NewUI/Terminal/Bottom"
import FunctionalPanel from "components/NewUI/Terminal/FunctionalPanel"
import StopOrderModal from "components/NewUI/Terminal/StopOrderModal"
import AdjustLeverage from "components/NewUI/Terminal/AdjustLeverage"
import useIdlePageReload from "hooks/newUI/useIdlePageReload"
import RiskMarginModal from "components/NewUI/Terminal/RiskMarginModal"

import styles from "./index.module.scss"

const Terminal: React.FC = () => {
	useIdlePageReload(180000) // 3 minutes

	const {
		terminal: { loadExchange, ticker, setTicker },
		global: { isAuthenticated, isWSDown },
		tickers: { filter, loadTickers, isLoaded, list, isMargin },
	} = useMst()
	const { pair: pairParams } = useParams<{ [queryVars.pair]: string }>()
	const { formatMessage } = useIntl()
  const banned = useBanned()

	useEffect(() => {
		loadTickers().then(() => null)
	}, [])

	useEffect(() => {
		loadExchange(isAuthenticated).then(() => null)
	}, [ticker?.symbol, isAuthenticated])

	useAutoFetch(loadTickers, isWSDown)
	useAutoFetch(loadExchange, isWSDown)

	const processTickers = () => {
		if (isLoaded) {
			const nextTicker = list.find((t: any) => t.symbol === pairParams)
			if (nextTicker) {
				if (filter.quotedCurrency !== TickersQuotedEnum.FAVORITES) {
					filter.setQuotedCurrency(
						QUOTED_CURRENCIES_FIAT.includes(nextTicker.quote_currency_code)
							? TickersQuotedEnum.FIAT
							: nextTicker.quote_currency_code,
					)
				}

				setTicker(nextTicker)
			}
		}
	}

	useEffect(() => {
		processTickers()
	}, [pairParams, list.length, isLoaded])

	return (
		<>
			<Helmet />

			<WebSocket
				events={[
					WSListenEventEnum.ORDERS,
					WSListenEventEnum.ORDERS_PAIR,
					WSListenEventEnum.TICKERS,
					WSListenEventEnum.FUNDS,
					WSListenEventEnum.ORDERBOOK,
					WSListenEventEnum.TRADES,
					WSListenEventEnum.CHAT,
					WSListenEventEnum.WALLETS,
					WSListenEventEnum.LOAN_BALANCE,
					WSListenEventEnum.LOAN_POSITION,
					// WSListenEventEnum.RATES,
				]}
			/>

			<Navigation />

			{/*<News />*/}

			<main className={styles.main}>
				<div className={styles.main__favorites}>
					<Favorites />
				</div>

				<div className={styles.main__tradingView}>
					<Trading />
				</div>

				{/*  THE ORDER OF ELEMENTS IN THIS COMPONENT IS CRUCIAL.
				IF YOU'RE INSERTING SOMETHING NEW, MAKE SURE TO REFER TO THE STYLES AND UPDATE THE SEQUENCE OF TAGS ACCORDINGLY. */}
				<div className={cn(styles.main__orderTrades, { [styles.blur]: banned })}>
					{/* These tabs are written in pure HTML5 and CSS. This is done to ensure instant responsiveness of the components,
					as well as to avoid interrupting the JavaScript thread with something as simple as tab switching.
					This approach allows us to achieve smooth animations and fast rendering without any delays. */}
					<input
						className={`visually-hidden ${styles.main__tabRadio}`}
						type="radio"
						name="OrdersRecentTradesHeader"
						id="OrdersHeader"
						value="OrdersHeader"
						defaultChecked
					/>

					<input
						className={`visually-hidden ${styles.main__tabRadio}`}
						type="radio"
						name="OrdersRecentTradesHeader"
						id="RecentTradesHeader"
						value="RecentTradesHeader"
					/>

					<div className={styles.main__ordersHeader}>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className={styles.main__ordersHeaderTabButton} htmlFor="OrdersHeader">
							<span>{formatMessage(messages.orderbook)}</span>
						</label>

						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className={styles.main__ordersHeaderTabButton} htmlFor="RecentTradesHeader">
							<span>{formatMessage(exchangeMessages.recent_trades)}</span>
						</label>
					</div>

					<div className={styles.main__orders}>
						<Orders />
					</div>

					<div className={styles.main__trades}>
						<RecentTrades />
					</div>
				</div>

				<div className={cn(styles.main__terminal, { [styles.blur]: banned })}>
					<FunctionalPanel />
				</div>

				<div className={cn(styles.main__history, { [styles.blur]: banned })}>
					<Bottom />
				</div>
			</main>

			<StopOrderModal />

			{isMargin ? <AdjustLeverage /> : null}

			{isMargin && isAuthenticated ? <RiskMarginModal /> : null}

			<Footer />

			<div className={styles.main__portalRoot} id="portalRoot" />

			<Cookies />
		</>
	)
}

export default observer(Terminal)
