import React, { useState } from "react"
import cn from "classnames"
import { Button, rems, pxs, AlphaIcon } from "@btc-alpha/ui-components"

import { TRootItem } from "../types"
import { ROOT_ICON } from "../constants";

import useDropDown from "./useDropDown"
import styles from "../navigation.module.scss"

type TProps = {
	item: TRootItem
}

const RootItem: React.FC<TProps> = ({ item }) => {
	const [isSubShowed, setSubShowed] = useState<boolean>(false)

	const minWidth = item.itemsWidth || pxs(rems(300))
	const { handleMouseEnter, handleMouseLeave, portal } = useDropDown(
		item.items,
		({ rect: { left, bottom } }) => ({
			left: Math.min(left - pxs(rems(16)), document.body.clientWidth - minWidth),
			top: bottom,
		}),
		setSubShowed,
	)

	return (
		<>
			<Button
				size="mini"
				kind={item.primary ? "primary" : "secondary"}
				to={item.link}
				caption={item.title}
				icon={item.icon}
				iconSize={ROOT_ICON}
				hovered={isSubShowed}
				disabled={item.disabled}
				className={cn({ [styles.primary]: item.primary, [styles.active]: isSubShowed })}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{item.items && item.title && (
					<AlphaIcon name="ai-chevron_down" className={styles.chevron} size={10} />
				)}
			</Button>
			{portal}
		</>
	)
}

export default RootItem
