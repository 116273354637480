/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react"
import { FormattedRelativeTime, useIntl } from "react-intl"
import { AlphaIcon, Button, CodeInput, Counter, Field, KeyValues } from "@btc-alpha/ui-components"

import {
	ICreateWithdrawBody,
	TCreateWithdrawRequestError,
	TWithdrawalDetails,
	TWithdrawMethod,
} from "types/withdrawal"
import config from "helpers/config"
import { EMAIL_PINCODE_LENGTH, GOOGLE_2FA_LENGTH } from "constants/verification"
import commonMessages from "messages/common"
import p2pMessages from "messages/p2p"
import historyMessages from "messages/history"
import financeMessages from "messages/finance"
import errorHandler from "utils/errorHandler"
import WithdrawalService from "services/WithdrawalService"
import { TDefaultModalProps, TModalParams } from "hooks/useModal"

import Loader from "assets/icons/Loader"
import google2faIcon from "assets/icons/google-2fa.svg"
import SuccessImg from "assets/images/withdraws/success.svg"

import { ERROR_ADDRESS, ERROR_AMOUNT } from "./constants"

import styles from "./withdrawal.module.scss"

type TResendButtonProps = {
	text: string
	onClick?: () => Promise<void>
}

const ResendButton: React.FC<TResendButtonProps> = ({ text, onClick }) => {
	const [loading, setLoading] = useState<boolean>(false)

	const handleClick = async () => {
		try {
			setLoading(true)
			await onClick?.()
		} catch (error) {
			errorHandler(error, false)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className={styles.resend}>
			<button type="button" disabled={loading} onClick={handleClick}>
				{loading ? (
					<Loader size={16} color="--alpha-ui-color-accent-purple" />
				) : (
					<AlphaIcon name="ai-reload" size={16} color="--alpha-ui-color-accent-purple" />
				)}
				{text}
			</button>
		</div>
	)
}

type TParams = {
	currency: string
	address: string
	method?: TWithdrawMethod
	received: number
	fee: number
	tag?: string
	note?: string
	withdrawal?: TWithdrawalDetails
	updateHistory?: () => void
	onSuccess?: () => void
}

type TProps = TDefaultModalProps & TParams

type TStep = "info" | "totp" | "pincode" | "success"

const ConfirmWithdrawal: React.FC<TProps> = ({
	currency,
	address,
	method,
	received,
	fee,
	tag = "",
	note = "",
	withdrawal,
	updateHistory,
	onSuccess,
	updateModal,
	closeModal,
	localeNavigate,
}) => {
	const { formatMessage } = useIntl()

	const [step, setStep] = useState<TStep>("info")
	const setNextStep = ({
		is_ok,
		is_totp_required,
		is_totp_ok,
		is_pincode_required,
		is_pincode_ok,
	}: Partial<TWithdrawalDetails> = {}) => {
		if (is_ok) setStep("success")
		else if (is_totp_required && !is_totp_ok) setStep("totp")
		else if (is_pincode_required && !is_pincode_ok) setStep("pincode")
		else setStep("info")
	}

	const [loading, setLoading] = useState<boolean>(false)
	const makeAction =
		(
			action: () => Promise<void>,
			onError?: (error: any) => Promise<void> | void,
			setProcessing: React.Dispatch<React.SetStateAction<boolean>> = setLoading,
		) =>
		async () => {
			try {
				setProcessing(true)
				await action()
			} catch (error) {
				await onError?.((error as any)?.data)
				errorHandler(error, false)
			} finally {
				setProcessing(false)
			}
		}

	const [details, setDetails] = useState<TWithdrawalDetails | undefined>(withdrawal)
	useEffect(() => setNextStep(details), [details])

	const {
		pincode_tries_left,
		pincode_timeout,
		totp_timeout,
		slug: detailsSlug = "",
	} = details || {}

	const loadWithdrawDetails = (slug: string) =>
		WithdrawalService.getWithdrawDetails(slug)
			.then(setDetails as any)
			.catch(error => errorHandler(error, false))

	const close = () => {
		localeNavigate({ search: {} })
		closeModal()
	}

	/* Creating */

	const create = makeAction(
		async () => {
			if (!method) return
			const attributes: ICreateWithdrawBody["attributes"] = {
				address,
			}
			tag.length && (attributes.tag = tag)
			const result = await WithdrawalService.createWithdraw({
				payment_type: method.id,
				attributes,
				amount: received.toString(),
				note: note.length ? note : undefined,
				fee_source: parseInt(config.withdrawFeeMode, 10),
			})
			setDetails(result)
			localeNavigate({ search: { slug: result.slug } })
		},
		error => {
			if ((error as TCreateWithdrawRequestError)?.address?.length) closeModal(ERROR_ADDRESS)
			if ((error as TCreateWithdrawRequestError)?.amount?.length) closeModal(ERROR_AMOUNT)
		},
	)

	const [canceling, setCanceling] = useState<boolean>(false)
	const cancel = makeAction(
		async () => {
			await WithdrawalService.cancelWithdraw(detailsSlug)
			close()
		},
		undefined,
		setCanceling,
	)

	/* 2FA */

	const [totp, setTotp] = useState<string>("")
	const [totpError, setTotpError] = useState<boolean>(false)
	useEffect(() => setTotpError(false), [totp])

	const verifyTotp = makeAction(
		async () => {
			const result = await WithdrawalService.confirmWithdraw(detailsSlug, { totp, token: totp })
			result?.is_ok && updateHistory?.()
			setDetails(result)
		},
		async () => {
			await loadWithdrawDetails(detailsSlug)
			setTotpError(true)
		},
	)

	/* Pincode */

	const [pincode, setPincode] = useState<string>("")
	const [pincodeError, setPincodeError] = useState<boolean>(false)
	useEffect(() => setPincodeError(false), [pincode])

	const verifyPincode = makeAction(
		async () => {
			const result = await WithdrawalService.confirmWithdraw(detailsSlug, { pincode })
			result?.is_ok && updateHistory?.()
			setDetails(result)
		},
		async () => {
			await loadWithdrawDetails(detailsSlug)
			setPincodeError(true)
		},
	)

	const handleResend = async () => {
		const result = await WithdrawalService.resendWithdrawPincode(detailsSlug)
		setDetails(result)
	}

	/* Render */

	useEffect(() => {
		const titles: Record<TStep, Required<TModalParams["title"]>> = {
			info: formatMessage(financeMessages.confirm_to_withdraw),
			totp: "2FA Authentication",
			pincode: formatMessage(financeMessages.email_verification),
			success: (
				<div className={styles.success}>
					<img src={SuccessImg} alt="" />
					<strong>{formatMessage(financeMessages.withdrawal_success)}</strong>
					<span>{formatMessage(financeMessages.withdrawal_processed_successfully)}</span>
				</div>
			),
		}
		updateModal({ title: titles[step] })
	}, [step])

	useEffect(() => {
		step === "success" && onSuccess?.()
	}, [step, onSuccess])

	const submitHandlers: Record<TStep, () => void> = {
		info: create,
		totp: verifyTotp,
		pincode: verifyPincode,
		success: close,
	}

	const cancelHandlers: Record<TStep, () => void> = {
		info: close,
		totp: cancel,
		pincode: cancel,
		success: () => {
			closeModal()
			localeNavigate({ search: {} })
		},
	}

	const submitCaptions: Record<TStep, string> = {
		info: formatMessage(p2pMessages.next_step),
		totp: formatMessage(commonMessages.confirm),
		pincode: formatMessage(commonMessages.confirm),
		success: formatMessage(commonMessages.close),
	}

	const cancelCaptions: Record<TStep, string> = {
		info: formatMessage(commonMessages.cancel),
		totp: formatMessage(commonMessages.cancel),
		pincode: formatMessage(commonMessages.cancel),
		success: formatMessage(financeMessages.withdrawal_history),
	}

	const isSubmitDisabled =
		canceling ||
		(step === "totp" && totp.length !== GOOGLE_2FA_LENGTH) ||
		(step === "pincode" && pincode.length !== EMAIL_PINCODE_LENGTH)

	return (
		<div className={styles.confirmation}>
			<div className={styles.content}>
				{step === "totp" ? (
					<Field
						fComponent={CodeInput}
						fLabel={formatMessage(commonMessages.enter_2fa)}
						fLabelIcon={
							<img
								src={google2faIcon}
								alt={formatMessage(financeMessages.google_2fa)}
								className={styles.fieldIcon}
							/>
						}
						className={styles.code}
						length={GOOGLE_2FA_LENGTH}
						number
						error={totpError}
						value={totp}
						onChange={setTotp}
						fSubline={
							<Counter
								destination={totp_timeout || 0}
								render={value =>
									formatMessage(commonMessages.otp_input_blocked, {
										time_to_wait: (
											<FormattedRelativeTime value={value} updateIntervalInSeconds={1} />
										),
									})
								}
								hideOnFinish
							/>
						}
					/>
				) : step === "pincode" ? (
					<Field
						fComponent={CodeInput}
						fLabel={formatMessage(commonMessages.enter_pincode)}
						fLabelIcon="ai-mail_outline_new"
						className={styles.code}
						length={EMAIL_PINCODE_LENGTH}
						numeric
						error={pincodeError}
						value={pincode}
						onChange={setPincode}
						fSubline={
							<Counter
								destination={pincode_timeout}
								render={value =>
									formatMessage(
										typeof pincode_tries_left === "number" && pincode_tries_left > 0
											? commonMessages.pincode_input_with_tries
											: pincode_tries_left === 0
											? commonMessages.pincode_input_with_no_tries
											: commonMessages.pincode_input_get_new_pincode_success,
										{
											tries: pincode_tries_left,
											time_to_wait: (
												<FormattedRelativeTime value={value} updateIntervalInSeconds={1} />
											),
										},
									)
								}
							>
								<ResendButton
									text={formatMessage(
										pincode_tries_left === 0
											? commonMessages.pincode_input_with_no_tries
											: commonMessages.pincode_input_get_new_pincode,
										{ time_to_wait: "" },
									)}
									onClick={handleResend}
								/>
							</Counter>
						}
					/>
				) : (
					<KeyValues
						items={[
							[formatMessage(financeMessages.on_chain_withdrawal), address],
							{
								key: formatMessage(financeMessages.chain_type),
								value: method?.name,
								isVisible: !!method?.name,
							},
							[formatMessage(financeMessages.actual_amount_received), `${received} ${currency}`],
							{
								key: formatMessage(historyMessages.active_orders_transaction_fee),
								value: `${fee} ${currency}`,
								separated: !!note,
							},
							{ key: formatMessage(financeMessages.your_note), value: note, isVisible: !!note },
						]}
					/>
				)}
			</div>
			<div className={styles.footer}>
				<Button
					caption={cancelCaptions[step]}
					loading={canceling}
					disabled={loading}
					kind="outlined"
					onClick={cancelHandlers[step]}
				/>
				<Button
					caption={submitCaptions[step]}
					loading={loading}
					disabled={isSubmitDisabled}
					onClick={submitHandlers[step]}
				/>
			</div>
		</div>
	)
}

export default ConfirmWithdrawal
