/* eslint-disable jsx-a11y/alt-text */
import React, { CSSProperties } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import dayjs from "dayjs"
import { Button, rems } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import notificationsMessages from "messages/notifications"
import { useMst } from "models/Root"

import { ReactComponent as Unread } from "assets/icons/menu/unread.svg"
import IconNotification from "assets/icons/menu/notification.svg"

import { ROOT_ICON } from "../../constants"
import styles from "./notifications.module.scss"

export const NotificationsIcon: React.FC = observer(() => {
	const {
		notifications: { unread_count },
	} = useMst()

	return (
		<div className={styles.icon}>
			<span>{!!unread_count && (unread_count > 99 ? "99+" : unread_count)}</span>
			<img src={IconNotification} style={{ "--size": rems(ROOT_ICON) } as CSSProperties} />
		</div>
	)
})

const Notifications: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		notifications: { latest, unread_count, loadLatestNotifications },
	} = useMst()

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<div>{unread_count}</div>
				<span>Notifications Unread</span>
			</div>
			<div className={styles.list}>
				{latest.map(({ uuid, title, text, read_at, created_at }) => (
					<Button
						key={`notifications-items-${uuid}`}
						kind="secondary"
						to={routes.profile.getNotificationDetails(uuid, false)}
						className={styles.item}
					>
						<div className={styles.title}>
							{!read_at && <Unread />}
							<span>{title}</span>
						</div>
						<div className={styles.text}>{text}</div>
						<div className={styles.date}>{dayjs(created_at).fromNow()}</div>
					</Button>
				))}
				{!latest.length && (
					<div className={styles.empty}>
						{formatMessage(notificationsMessages.no_notifications)}
					</div>
				)}
			</div>
			<Button
				size="middle"
				kind="secondary"
				caption={formatMessage(notificationsMessages.all_notifications)}
				to={routes.profile.notification}
				className={styles.all}
			/>
		</div>
	)
}

export default observer(Notifications)
