/* eslint-disable no-unused-expressions */
import React, { ComponentProps, useEffect, useState } from "react"
import { FormattedRelativeTime, useIntl } from "react-intl"
import { Button, CodeInput, Counter, Field } from "@btc-alpha/ui-components"

import { EWizardStep, TLoginResponse } from "types/auth"
import { routes } from "constants/routing"
import { EMAIL_PINCODE_LENGTH, GOOGLE_2FA_LENGTH } from "constants/verification"
import commonMessages from "messages/common"
import p2pMessages from "messages/p2p"
import accountMessages from "messages/account"
import AuthService from "services/AuthService"
import { TDefaultModalProps } from "hooks/useModal"
import useAsyncAction from "hooks/useAsyncAction"

import google2faIcon from "assets/icons/google-2fa.svg"

import styles from "./loginForm.module.scss"

type TParams = {
	email: string
	response: TLoginResponse
	onFinish?: (response: Required<TLoginResponse>["done"]) => void
}

type TProps = TDefaultModalProps & TParams

const ConfirmLogin: React.FC<TProps> = ({
	email,
	response: initResponse,
	onFinish,
	closeModal,
}) => {
	const { formatMessage } = useIntl()

	const [response, setResponse] = useState<TLoginResponse>(initResponse)

	const wizard_id = response.form?.values.wizard_id || ""
	const step = response.wizard?.step as EWizardStep

	const [code, setCode] = useState<string>("")
	const [error, setError] = useState<boolean>(false)
	useEffect(() => setError(!!Object.keys(response?.form?.errors || {})), [response])
	useEffect(() => setError(false), [code])

	const [send, sending] = useAsyncAction(
		async () => {
			const body: Parameters<typeof AuthService.login>[0] = { email, wizard_id }
			if (step === EWizardStep.PINCODE) body.pincode = code
			else Object.assign(body, { totp: code, token: code })
			const result = await AuthService.login(body)
			if (result.done) {
				onFinish?.(result.done)
				closeModal()
			} else if (
				[EWizardStep.PINCODE, EWizardStep.TOKEN].includes(result.wizard?.step as EWizardStep)
			) {
				setResponse(result)
				setCode("")
			} else throw result
		},
		async error => {
			if ([EWizardStep.PINCODE, EWizardStep.TOKEN].includes(error?.wizard?.step as EWizardStep)) {
				setResponse(error)
				return true
			}
			return false
		},
	)

	const [{ delay, left }, setInterruptions] = useState<{ delay?: string; left?: number }>({})

	useEffect(
		() =>
			setInterruptions({
				delay: response?.form?.fields?.find(({ delay }) => !!delay)?.delay as string,
				left: response?.form?.fields?.find(({ left }) => typeof left === "number")?.left,
			}),
		[response],
	)

	const delayed = !!delay && !!delay.length && new Date(delay) > new Date()
	const resetDelayed = () =>
		setInterruptions(interruptions => ({ ...interruptions, delay: undefined }))

	const handleResendPincode = async () => {
		const result = await AuthService.loginResendPincode(wizard_id)
		setInterruptions({ delay: result.delay, left: result.left })
	}

	/* Render */

	const props: Partial<
		Record<EWizardStep, Partial<ComponentProps<typeof Field<typeof CodeInput>>>>
	> = {
		[EWizardStep.PINCODE]: {
			fLabel: formatMessage(commonMessages.enter_pincode),
			fLabelIcon: "ai-mail_outline_new",
			length: EMAIL_PINCODE_LENGTH,
			fSubline: (
				<Counter
					destination={delay}
					render={value =>
						formatMessage(
							typeof left === "number" && left > 0
								? commonMessages.pincode_input_with_tries
								: left === 0
								? commonMessages.pincode_input_with_no_tries
								: commonMessages.pincode_input_get_new_pincode_success,
							{
								tries: left,
								time_to_wait: <FormattedRelativeTime value={value} updateIntervalInSeconds={1} />,
							},
						)
					}
				>
					<Button
						kind="clear"
						size="mini"
						icon="ai-reload"
						iconSize={16}
						caption={formatMessage(
							left === 0
								? commonMessages.pincode_input_with_no_tries
								: commonMessages.pincode_input_get_new_pincode,
							{ time_to_wait: "" },
						)}
						loading="auto"
						onClick={handleResendPincode}
					/>
				</Counter>
			),
		},
		[EWizardStep.TOKEN]: {
			fLabel: formatMessage(commonMessages.enter_2fa),
			fLabelIcon: <img src={google2faIcon} alt={formatMessage(accountMessages.google_2fa)} className={styles.fieldIcon} />,
			length: GOOGLE_2FA_LENGTH,
			fSubline: (
				<Counter
					destination={delay}
					render={value =>
						formatMessage(commonMessages.otp_input_blocked, {
							time_to_wait: <FormattedRelativeTime value={value} updateIntervalInSeconds={1} />,
						})
					}
					hideOnFinish
					onFinish={resetDelayed}
				/>
			),
		},
	}

	const isSubmitDisabled =
		(step === EWizardStep.TOKEN && code.length !== GOOGLE_2FA_LENGTH && !delayed) ||
		(step === EWizardStep.PINCODE && code.length !== EMAIL_PINCODE_LENGTH)

	return (
		<div className={styles.confirmation}>
			<div className={styles.content}>
				<Field
					fComponent={CodeInput}
					{...props[step]}
					className={styles.code}
					number
					error={error}
					value={code}
					onChange={setCode}
				/>
			</div>
			<div className={styles.footer}>
				<Button
					caption={formatMessage(commonMessages.cancel)}
					disabled={sending}
					kind="outlined"
					onClick={closeModal}
				/>
				<Button
					caption={formatMessage(p2pMessages.next_step)}
					loading={sending}
					disabled={isSubmitDisabled}
					onClick={send}
				/>
			</div>
			<a href={routes.support.request}>
				<Button
					kind="clear"
					size="mini"
					caption={formatMessage(accountMessages.having_problem_with_verification)}
					className={styles.help}
				/>
			</a>
		</div>
	)
}

export default ConfirmLogin
