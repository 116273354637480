import { TCurrencyShort } from "types/finances"
import { types as t } from "mobx-state-tree/dist/types"
import { IGetDepositHistoryParams, IGetDepositHistoryResponse } from "./deposit"
import { queryVars } from "../constants/query"

export enum EWithdrawalStatuses {
	NEW = 10, // created new, need to be confirmed
	CONFIRMED = 20, // confirmed by user, need to be verified
	VERIFIED = 23, // verified by automatic system. ve additinal info in verification field
	PROCESSING = 27, // processing by bot or operator
	DONE = 30, // money sent, see tx id in payment field
	REFUSED = 40, // refuxed by operator or bot
	CANCELLED = 50, // cancelled by user
}

export type TWithdrawStatus = {
	id: EWithdrawalStatuses
	label: string
}

export type TWithdrawAttribute = {
	label: string
	value: string
}

export type TWithdraw = {
	id: number
	date: string
	amount: string
	currency_id?: string
	slug?: string
	status: TWithdrawStatus
	payment_method_name: string
	fee_amount: string
	fee_currency_id?: string
	comment?: string
	note?: string
	currency: TCurrencyShort
	txid?: string
	txid_url?: string
	attributes?: TWithdrawAttribute[]
}

export interface IGetWithdrawHistoryParams {
	currency?: string
	page?: number
	"page-size"?: number
}

export interface IGetWithdrawHistoryResponse {
	count: number
	results: TWithdraw[]
}

export type TWithdrawMethodContract = {
	name?: string
	type?: string
	address?: string
}

export type TWithdrawMethodNetwork = {
	name: string
}

export type TWithdrawMethodAttribute = {
	name: string
	type: string
	label?: string
	required?: boolean
	help_text?: string
	regex?: string
	min_length?: number
	max_length?: number
	value?: string
}

export type TWithdrawMethodNote = {
	type: string
	message: string
}

export type TWithdrawMethod = {
	id: number
	name: string
	image_svg?: string
	image_png?: string
	min_withdraw: string
	max_withdraw: string
	withdraw_fee_amount: string
	withdraw_fee_rate: string
	currency: TCurrencyShort
	network?: TWithdrawMethodNetwork
	withdraw_fee_currency: TCurrencyShort
	contract?: TWithdrawMethodContract
	notes: TWithdrawMethodNote[]
	attributes: TWithdrawMethodAttribute[]
	blockchain_block_interval?: number
	min_verification_level?: number
	is_withdraw_enabled?: boolean
}

export type TWithdrawalDetails = {
	slug: string
	is_totp_required: boolean
	totp_timeout?: string
	is_totp_confirmed: boolean
	is_pincode_required: boolean
	pincode_tries_left: number
	pincode_timeout?: string
	pincode_generated_at?: string
	is_pincode_confirmed: boolean
	deadline_at: string
	is_totp_ok: boolean
	is_pincode_ok: boolean
	is_ok: boolean
	currency: TCurrencyShort
	fee_amount: string
	fee_currency: TCurrencyShort
	payment_type_id: number
	payment_method_name: string
	attributes: Record<string, string>
	attributes_labeled: TWithdrawAttribute[]
	amount: string
	note?: string
	withdraw: any // ?
}

export interface ICreateWithdrawBody {
	payment_type: number
	amount: string
	note?: string
	stored_attributes?: any
	attributes: Record<string, string>
	fee_source: number
}

export interface ICreateWithdrawResponse extends TWithdrawalDetails {}

export interface IGetWithdrawMethodsParams {
	[queryVars.currency]: string
}

export type TGetWithdrawMethodsResponse = TWithdrawMethod[]

export type TCreateWithdrawRequestError = {
	address?: string[]
	amount?: string[]
	non_field_errors?: string[]
}
export type TWithdrawDailyLimit = {
	quota?: string
	used?: string
	quota_converted?: string
	is_hard_limited: boolean
	hard_limit_until: string | null
	used_converted?: string
	unused_in_currency?: string
	currency?: string
	verification_level?: number
	extend_verification_level?: number
}

export interface IConfirmWithdrawRequestBody {
	totp?: string
	token?: string
	pincode?: string
}
