import React, { useRef } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Table, TTableOnFetch, TTableRef } from "@btc-alpha/ui-components"

import { AccountTypeEnum } from "types/account"
import { ACCOUNT_TYPE } from "constants/exchange"
import historyMessages from "messages/history"
import { IOrder } from "models/History"
import HistoryService from "services/HistoryService"
import { getPageTitle } from "helpers/global"

import RedesignedLayout from "layouts/RedesignedLayout"

import { useColumns, useFilters } from "./activeOrders.hooks"
import styles from "./activeOrders.module.scss"

const ActiveOrders: React.FC = () => {
	const { formatMessage } = useIntl()
	const tableRef = useRef<TTableRef>(null)

	const columns = useColumns(tableRef.current?.refetch)
	const filters = useFilters()

	const handleFetch: TTableOnFetch<IOrder> = async ({ page, limit, filters }) => {
		const [date_after, date_before] = filters.date?.split(",") || []
		const { results: data, count } = await HistoryService.getOrders({
			open_only: true,
			wallet_type: ACCOUNT_TYPE[AccountTypeEnum.SPOT],
			pair: filters.pair,
			side: filters.side,
			date_before,
			date_after,
			"page-size": limit,
			page,
		})
		return { data, count }
	}

	return (
		<RedesignedLayout
			title={formatMessage(historyMessages.current_orders)}
			helmet={{ title: getPageTitle(formatMessage(historyMessages.current_orders)) }}
			className={styles.activeOrders}
		>
			<div className={styles.table}>
				<Table<IOrder>
					ref={tableRef}
					columns={columns}
					filters={filters}
					lastSticky
					empty={formatMessage(historyMessages.no_orders_to_show)}
					lazyLoad
					resetFilters
					defFetching
					footer="pagination"
					onFetch={handleFetch}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(ActiveOrders)
