import React, { FC } from "react"

import Logo from "assets/icons/auth/Logo"

import InternalLink from "components/InternalLink"

import styles from "./header.module.scss"

type TProps = {
	headline: string
	description: string
	linkCaption?: string
	linkRoute?: string
	email?: string
}

const Header: FC<TProps> = ({ headline, description, linkCaption, linkRoute, email }) => (
	<div className={styles.header}>
		<Logo className={styles.logo} />
		<div className={styles.title}>{headline}</div>
		<div className={styles.description}>
			<span>{description}</span>
			{!!linkCaption?.length && <InternalLink to={linkRoute}>{linkCaption}</InternalLink>}
		</div>
		{!!email?.length && <div className={styles.email}>{email}</div>}
	</div>
)

export default Header
