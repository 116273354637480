/* eslint-disable no-unused-expressions */
import React, { useRef } from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Button, Table, TTableOnFetch, TTableRef } from "@btc-alpha/ui-components"

import { IInternalTransferDetails } from "types/internal_transfers"
import { queryVars } from "constants/query"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import transfersMessages from "messages/transfers"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import InternalTransferService from "services/InternalTransferService"

import RedesignedLayout from "layouts/RedesignedLayout"

import { getPageTitle } from "helpers/global"
import AnnotationQuestionIcon from "assets/icons/annotation-question.svg"

import { useColumns } from "pages/FinanceHistory/Transfers/hooks"

import DepositQuestions from "../Deposit/DepositQuestions"
import TransferForm from "./TransferForm"
import styles from "./transfer.module.scss"

const HISTORY_SIZE = 10

const Transfer: React.FC = () => {
	const { formatMessage } = useIntl()
	const { currency = "" } = useParams<{ [queryVars.currency]: string }>()
	const localeNavigate = useLocaleNavigate()

	const tableRef = useRef<TTableRef>(null)
	const handleRefetch = () => tableRef.current?.refetch()
	const columns = useColumns(handleRefetch)

	const handleFetch: TTableOnFetch<IInternalTransferDetails> = async () => {
		const { results, count } = await InternalTransferService.loadTransfersHistory({
			"page-size": HISTORY_SIZE,
		})
		return { data: results, count }
	}

	const handleAllClick = () => localeNavigate(routes.financeHistory.transfers)

	return (
		<RedesignedLayout
			title={formatMessage(commonMessages.transfer)}
			helmet={{ title: getPageTitle(formatMessage(commonMessages.transfer)) }}
			className={styles.root}
		>
			<div className={styles.main}>
				<TransferForm currency={currency} updateHistory={handleRefetch} />
				<div className={styles.info}>
					<header>
						<span>{formatMessage(commonMessages.common_questions)}</span>
						<Button
							caption={formatMessage(transfersMessages.help_center)}
							icon={AnnotationQuestionIcon}
							size="mini"
							kind="outlined"
							to={routes.support.root}
						/>
					</header>
					<div className={styles.faq}>
						<DepositQuestions />
					</div>
				</div>
			</div>
			<div className={styles.history}>
				<h2>{formatMessage(transfersMessages.transfers_history)}</h2>
				<Table
					ref={tableRef}
					columns={columns}
					id="txid"
					empty={formatMessage(transfersMessages.no_orders_to_show)}
					lazyLoad
					defFetching
					footer="all"
					defLimit={HISTORY_SIZE}
					onFetch={handleFetch}
					onAllClick={handleAllClick}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(Transfer)
