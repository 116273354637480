/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react"
import { FormattedRelativeTime, useIntl } from "react-intl"
import { Button, CodeInput, Counter, Field, KeyValues } from "@btc-alpha/ui-components"

import {
	IConfirmTransferRequestError,
	ICreateTransferRes,
	TCreateTransferRequestError,
} from "types/internal_transfers"
import { EMAIL_PINCODE_LENGTH, GOOGLE_2FA_LENGTH } from "constants/verification"
import commonMessages from "messages/common"
import p2pMessages from "messages/p2p"
import transfersMessages from "messages/transfers"
import financeMessages from "messages/finance"
import InternalTransferService from "services/InternalTransferService"
import { TDefaultModalProps, TModalParams } from "hooks/useModal"
import useAsyncAction from "hooks/useAsyncAction"

import google2faIcon from "assets/icons/google-2fa.svg"
import SuccessImg from "assets/images/withdraws/success.svg"

import styles from "./transfer.module.scss"
import { ERROR_RECEIVER } from "./constants"

type TParams = {
	currency: string
	amount: number
	receiver: string
	security?: string
	days?: number
	message?: string
	note?: string
	updateHistory?: () => void
}

type TProps = TDefaultModalProps & TParams

type TStep = "info" | "totp" | "pincode" | "success"

const ConfirmTransfer: React.FC<TProps> = ({
	currency,
	amount,
	receiver,
	security,
	days,
	message,
	note,
	updateHistory,
	updateModal,
	closeModal,
	localeNavigate,
}) => {
	const { formatMessage } = useIntl()

	const [step, setStep] = useState<TStep>("info")
	const setNextStep = ({
		is_ok,
		is_totp_required,
		is_totp_ok,
		is_pincode_required,
		is_pincode_ok,
	}: Partial<ICreateTransferRes> = {}) => {
		if (is_ok) setStep("success")
		else if (is_totp_required && !is_totp_ok) setStep("totp")
		else if (is_pincode_required && !is_pincode_ok) setStep("pincode")
		else setStep("info")
	}

	const [details, setDetails] = useState<ICreateTransferRes>()
	useEffect(() => setNextStep(details), [details])

	const {
		pincode_tries_left,
		pincode_timeout,
		totp_timeout,
		slug: detailsSlug = "",
	} = details || {}

	const close = () => {
		localeNavigate({ search: {} })
		closeModal()
	}

	/* Creating */

	const [create, creating] = useAsyncAction(
		async () => {
			const result = await InternalTransferService.createTransferRequest({
				currency,
				amount,
				receiver,
				security_code: security,
				valid_days: days,
				message,
				note,
			})
			setDetails(result)
		},
		error => {
			if ((error as TCreateTransferRequestError)?.receiver?.length) closeModal(ERROR_RECEIVER)
		},
	)

	const [cancel, canceling] = useAsyncAction(async () => {
		await InternalTransferService.cancelCreateTransferRequest(detailsSlug)
		close()
	})

	/* 2FA */

	const [totp, setTotp] = useState<string>("")
	const [totpError, setTotpError] = useState<boolean>(false)
	useEffect(() => setTotpError(false), [totp])

	const [verifyTotp, verifyingTotp] = useAsyncAction(
		async () => {
			const result = await InternalTransferService.confirmTransfer(detailsSlug, {
				totp,
				token: totp,
			})
			result?.is_ok && updateHistory?.()
			setDetails(result)
		},
		async error => {
			error = error as IConfirmTransferRequestError
			if (error.totp_timeout?.length)
				setDetails(value => ({
					...value!,
					totp_timeout: error.pincode_timeout[0],
				}))
			setTotpError(true)
		},
	)

	/* Pincode */

	const [pincode, setPincode] = useState<string>("")
	const [pincodeError, setPincodeError] = useState<boolean>(false)
	useEffect(() => setPincodeError(false), [pincode])

	const [verifyPincode, verifyingPincode] = useAsyncAction(
		async () => {
			const result = await InternalTransferService.confirmTransfer(detailsSlug, { pincode })
			result?.is_ok && updateHistory?.()
			setDetails(result)
		},
		async error => {
			error = error as IConfirmTransferRequestError
			if (error.pincode_timeout?.length && error.pincode_tries_left?.length)
				setDetails(value => ({
					...value!,
					pincode_timeout: error.pincode_timeout[0],
					pincode_tries_left: +error.pincode_tries_left[0],
				}))
			setPincodeError(true)
		},
	)

	const handleResend = async () => {
		const result = await InternalTransferService.resendTransferPincode(detailsSlug)
		setDetails(result)
	}

	/* Render */

	useEffect(() => {
		const titles: Record<TStep, Required<TModalParams["title"]>> = {
			info: formatMessage(transfersMessages.confirm_to_transfer),
			totp: "2FA Authentication",
			pincode: formatMessage(transfersMessages.email_verification),
			success: (
				<div className={styles.success}>
					<img src={SuccessImg} alt="" />
					<strong>{formatMessage(transfersMessages.success_transfer)}</strong>
					<span>{formatMessage(transfersMessages.transfer_processed_successfully)}</span>
				</div>
			),
		}
		updateModal({ title: titles[step] })
	}, [step])

	const submitHandlers: Record<TStep, () => void> = {
		info: create,
		totp: verifyTotp,
		pincode: verifyPincode,
		success: close,
	}

	const cancelHandlers: Record<TStep, () => void> = {
		info: close,
		totp: cancel,
		pincode: cancel,
		success: () => {
			closeModal()
			localeNavigate({ search: {} })
		},
	}

	const submitCaptions: Record<TStep, string> = {
		info: formatMessage(p2pMessages.next_step),
		totp: formatMessage(commonMessages.confirm),
		pincode: formatMessage(commonMessages.confirm),
		success: formatMessage(commonMessages.close),
	}

	const cancelCaptions: Record<TStep, string> = {
		info: formatMessage(commonMessages.cancel),
		totp: formatMessage(commonMessages.cancel),
		pincode: formatMessage(commonMessages.cancel),
		success: formatMessage(transfersMessages.transfers_history),
	}

	const isSubmitDisabled =
		canceling ||
		(step === "totp" && totp.length !== GOOGLE_2FA_LENGTH) ||
		(step === "pincode" && pincode.length !== EMAIL_PINCODE_LENGTH)

	const loading = creating || verifyingTotp || verifyingPincode

	return (
		<div className={styles.confirmation}>
			<div className={styles.content}>
				{step === "totp" ? (
					<Field
						fComponent={CodeInput}
						fLabel={formatMessage(commonMessages.enter_2fa)}
						fLabelIcon={
							<img
								src={google2faIcon}
								alt={formatMessage(transfersMessages.google_2fa)}
								className={styles.fieldIcon}
							/>
						}
						className={styles.code}
						length={GOOGLE_2FA_LENGTH}
						number
						error={totpError}
						value={totp}
						onChange={setTotp}
						fSubline={
							<Counter
								destination={totp_timeout || 0}
								render={value =>
									formatMessage(commonMessages.otp_input_blocked, {
										time_to_wait: (
											<FormattedRelativeTime value={value} updateIntervalInSeconds={1} />
										),
									})
								}
								hideOnFinish
							/>
						}
					/>
				) : step === "pincode" ? (
					<Field
						fComponent={CodeInput}
						fLabel={formatMessage(commonMessages.enter_pincode)}
						fLabelIcon="ai-mail_outline_new"
						className={styles.code}
						length={EMAIL_PINCODE_LENGTH}
						numeric
						error={pincodeError}
						value={pincode}
						onChange={setPincode}
						fSubline={
							<Counter
								destination={pincode_timeout}
								render={value =>
									formatMessage(
										typeof pincode_tries_left === "number" && pincode_tries_left > 0
											? commonMessages.pincode_input_with_tries
											: pincode_tries_left === 0
											? commonMessages.pincode_input_with_no_tries
											: commonMessages.pincode_input_get_new_pincode_success,
										{
											tries: pincode_tries_left,
											time_to_wait: (
												<FormattedRelativeTime value={value} updateIntervalInSeconds={1} />
											),
										},
									)
								}
							>
								<Button
									kind="clear"
									size="mini"
									icon="ai-reload"
									iconSize={16}
									caption={formatMessage(
										pincode_tries_left === 0
											? commonMessages.pincode_input_with_no_tries
											: commonMessages.pincode_input_get_new_pincode,
										{ time_to_wait: "" },
									)}
									loading="auto"
									className={styles.resend}
									onClick={handleResend}
								/>
							</Counter>
						}
					/>
				) : (
					<KeyValues
						items={[
							[formatMessage(financeMessages.amount_received), `${amount} ${currency}`],
							{
								key: formatMessage(commonMessages.receiver),
								value: receiver,
								separated: !!security || !!message || !!note,
							},
							{
								key: formatMessage(transfersMessages.security_code),
								value: security,
								isVisible: !!security,
							},
							{
								key: formatMessage(transfersMessages.days_active),
								value: days,
								isVisible: !!days,
								separated: !!message || !!note,
							},
							{
								key: formatMessage(commonMessages.description),
								value: message,
								isVisible: !!message,
							},
							{
								key: formatMessage(commonMessages.note),
								value: note,
								isVisible: !!note,
							},
						]}
					/>
				)}
			</div>
			<div className={styles.footer}>
				<Button
					caption={cancelCaptions[step]}
					loading={canceling}
					disabled={loading}
					kind="outlined"
					onClick={cancelHandlers[step]}
				/>
				<Button
					caption={submitCaptions[step]}
					loading={loading}
					disabled={isSubmitDisabled}
					onClick={submitHandlers[step]}
				/>
			</div>
		</div>
	)
}

export default ConfirmTransfer
