/* eslint-disable prefer-const,no-unused-expressions */
import { useNavigate } from "react-router-dom"

import { useMst } from "models/Root"

export type TNavigationParams = {
	path?: string
	search?: Record<string, string>
}

export const makePath = (to: string | TNavigationParams, withLocale = "") => {
	if (withLocale) withLocale = `/${withLocale}`
	if (typeof to === "string") return `${withLocale}${to}`
	const { path, search } = to
	if (!path && !search) return ""
	const nextPath = path ?? window.location.pathname.replace(/^\/[a-z]{2}\//, "/")
	let query = new URLSearchParams(search).toString()
	if (query.length) query = `?${query}`
	return `${withLocale}${nextPath}${query}`
}

export const useLocalePath = () => {
	const {
		global: { locale },
	} = useMst()
	const getLocalePath = (to: string | TNavigationParams) => makePath(to, locale)
	return getLocalePath
}

const useLocaleNavigate = () => {
	const navigate = useNavigate()
	const getLocalePath = useLocalePath()
	return (to: string | TNavigationParams) => {
		const path = getLocalePath(to)
		path && navigate(path)
	}
}

export default useLocaleNavigate
