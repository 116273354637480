import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cookies from "js-cookie"
import cn from "classnames"
import * as yup from "yup"
import { Input, Button, Field, useForm, pxs, rems, messageError } from "@btc-alpha/ui-components"

import { TLoginResponse } from "types/auth"
import { routes } from "constants/routing"
import { PASSWORD_MAX_SYMBOLS } from "constants/common"
import commonMessages from "messages/common"
import homeMessages from "messages/home"
import formMessages from "messages/form"
import config from "helpers/config"
import { isLocalhost } from "utils/browser"
import AuthService from "services/AuthService"
import useAccount from "hooks/useAccount"
import useModal from "hooks/useModal"
import useLocaleNavigate from "hooks/useLocaleNavigate"

import CloudflareCaptcha, { TCloudflareCaptchaRef } from "components/NewUI/CloudflareCaptcha"

import Header from "../components/Header"
import ThirdParty from "../components/ThirdParty"

import ConfirmLogin from "./ConfirmLogin"
import styles from "./loginForm.module.scss"

enum EFields {
	EMAIL = "email",
	PASSWORD = "password",
}

type TForm = {
	[EFields.EMAIL]: string
	[EFields.PASSWORD]: string
}

const LoginForm: React.FC = () => {
	const { formatMessage } = useIntl()
	const { setIsAuthenticated } = useAccount()
	const localeNavigate = useLocaleNavigate()
	const [openConfirmationModal] = useModal(ConfirmLogin, {
		title: "Security Verification",
		width: pxs(rems(510)),
		closeBack: false,
		closeEsc: false,
	})

	const cloudflareRef = React.useRef<TCloudflareCaptchaRef>(null)
	const [cloudflareToken, setCloudflareToken] = useState<string>()

	const { submit, change, submitting, errors } = useForm<TForm>({
		validation: {
			[EFields.EMAIL]: yup.string().required(formatMessage(formMessages.required)),
			[EFields.PASSWORD]: yup
				.string()
				.required(formatMessage(formMessages.required))
				.max(PASSWORD_MAX_SYMBOLS),
		},
	})

	const handleFinish = ({ user, token, expire_at }: Required<TLoginResponse>["done"]) => {
		if (isLocalhost) cookies.set(config.sessionCookieName, token)
		// cookies.set(config.sessionCookieName, response.access_token)
		setIsAuthenticated(true)
		// localeNavigate(routes.trade.root)
	}

	const handleSubmit = submit(
		async ({ email, password }) => {
			const response = await AuthService.login({ email, password })
			if (response.wizard?.id) {
				openConfirmationModal({ email, response, onFinish: handleFinish })
			} else if (response.done) {
				handleFinish(response.done)
			}
		},
		error => {
			const data = (error as any)?.data as TLoginResponse
			if (data?.form?.errors) messageError(Object.values(data?.form?.errors).flat())
			cloudflareRef.current?.resetAction()
		},
	)

	const handleForgotClick = () => localeNavigate(routes.resetPassword)

	const [isFilled, setFilled] = useState<boolean>(false)
	const handleChange = change(({ email, password }) => setFilled(!!email && !!password))

	return (
		<div className={styles.root}>
			<Header
				headline={formatMessage(commonMessages.welcome_back)}
				description={formatMessage(commonMessages.new_one)}
				linkCaption={formatMessage(commonMessages.create_account)}
				linkRoute={routes.register.root}
			/>

			<form onSubmit={handleSubmit} onChange={handleChange}>
				<Field
					fComponent={Input}
					fLabel={formatMessage(commonMessages.email)}
					disabled={submitting}
					name={EFields.EMAIL}
					placeholder={formatMessage(homeMessages.email)}
					fError={errors?.email}
					error={!!errors?.email}
				/>

				<Field
					fComponent={Input}
					fLabel={formatMessage(commonMessages.password)}
					fAction={formatMessage(commonMessages.forgot_password)}
					fOnActionClick={handleForgotClick}
					disabled={submitting}
					name={EFields.PASSWORD}
					placeholder={formatMessage(commonMessages.enter_password)}
					secure
					fError={errors?.password}
					error={!!errors?.password}
				/>

				<div className={cn(styles.captcha, { [styles.closed]: !!cloudflareToken })}>
					<CloudflareCaptcha ref={cloudflareRef} setToken={setCloudflareToken} theme="dark" />
				</div>

				<Button
					type="submit"
					caption={formatMessage(commonMessages.login)}
					loading={submitting}
					disabled={!isFilled || !cloudflareToken}
					className={styles.submit}
				/>
			</form>

			<ThirdParty />
		</div>
	)
}

export default observer(LoginForm)
