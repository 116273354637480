import React, { useRef } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { COIN_USDT } from "constants/common"
import commonMessages from "messages/common"
import { useMst } from "models/Root"
import { useOnClickOutside } from "hooks/useOnClickOutside"
import useBanned from "hooks/useBanned"
import styles from "./index.module.scss"
import Info from "./Info"
import Search from "./Search"
import Chart from "../Chart"

const Trading: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		terminal: { isOpenSearch, handleOpenSearch },
	} = useMst()
	const banned = useBanned()

	const dropdownRef = useRef<HTMLDivElement | null>(null)

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		handleOpenSearch(event.target.checked)

	const handleCheckboxClose = () => handleOpenSearch(false)

	const handleCheckboxOpen = (ev: any) => {
		ev.preventDefault()

		handleOpenSearch(true)
	}

	useOnClickOutside(dropdownRef, handleCheckboxClose)

	return (
		<section className={styles.trading}>
			<h2 className="visually-hidden">Trading Info</h2>

			<Info isOpen={isOpenSearch} openMenuAction={handleCheckboxOpen} />

			<div className={styles.trading__top} ref={dropdownRef}>
				<input
					type="checkbox"
					id="SearchMenuToggle"
					checked={isOpenSearch}
					onChange={handleCheckboxChange}
					className={`visually-hidden ${styles.trading__toggleMenu}`}
					aria-label="Toggle search open"
				/>

				<Search />
			</div>

			<div className={styles.trading__view}>
				{banned ? (
					<div className={styles.trading__banned}>
						<p>{formatMessage(commonMessages.banned, { asset: COIN_USDT, date: "31.01.2025" })}</p>
					</div>
				) : (
					<Chart />
				)}
			</div>
		</section>
	)
}

export default observer(Trading)
