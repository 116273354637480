/* eslint-disable no-unused-expressions,consistent-return */
import React from "react"
import { useIntl } from "react-intl"
import dayjs from "dayjs"
import cn from "classnames"
import {
	AlphaIcon,
	CurrencyIcon,
	Ellipser,
	ETableColumnAlign,
	ETableFilterType,
	TTableColumns,
	TTableFilters,
	useCopyClick,
	useShare,
} from "@btc-alpha/ui-components"

import { IInternalTransferDetails } from "types/internal_transfers"
import { TransferStatusColors } from "constants/internal_transfers"
import { useMst } from "models/Root"
import commonMessages from "messages/common"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import transfersMessages from "messages/transfers"
import { formatDate } from "utils/formatDate"
import useModal from "hooks/useModal"

import Badge, { BadgeBackgroundColorEnum, BadgeColorEnum } from "components/UI/Badge"

import styles from "./transfers.module.scss"
import AcceptTransfer from "./AcceptTransfer"

const getStatusColor = (transfer: IInternalTransferDetails) => {
	if (transfer.status?.key === 10 && dayjs(transfer.valid_till).isAfter(dayjs(Date.now())))
		return TransferStatusColors.Red
	if (transfer.status?.key === 10 && transfer.is_outgoing) return TransferStatusColors.Grey
	if (transfer.can_accept) return TransferStatusColors.Blue
	if (transfer.can_cancel) return TransferStatusColors.Red
	if (transfer.status?.key === 10) return TransferStatusColors.Blue
}

const getStatus = (transfer: IInternalTransferDetails) => {
	const { formatMessage } = useIntl()
	const { status } = transfer

	if (status?.key === 10)
		return <AlphaIcon name="ai-shield_transfer" color={getStatusColor(transfer)} size={16} />

	let color = BadgeColorEnum.GREY_DARK
	let text = ""

	if (transfer.status?.key === 30 && transfer.is_outgoing) {
		color = BadgeColorEnum.ORANGE
		text = formatMessage(commonMessages.status_sent)
	} else if (transfer.status?.key === 30 && !transfer.is_outgoing) {
		color = BadgeColorEnum.GREEN
		text = formatMessage(commonMessages.status_received)
	} else if (transfer.status?.key === 20) {
		color = BadgeColorEnum.GREY
		text = formatMessage(commonMessages.status_canceled)
	} else if (transfer.status?.key === 30) {
		color = BadgeColorEnum.GREEN
		text = transfer.status?.label
	}

	return text ? (
		<Badge alpha color={color}>
			{text}
		</Badge>
	) : null
}

export const useColumns = (refetch: () => void): TTableColumns<IInternalTransferDetails> => {
	const { formatNumber, formatMessage } = useIntl()
	const share = useShare()
	const copy = useCopyClick()

	const [openConfirmationModal] = useModal(AcceptTransfer, {
		title: formatMessage(transfersMessages.transfer_receiving),
		width: 500,
		closeBack: false,
	})

	const acceptHandler = (transfer: IInternalTransferDetails) => () =>
		openConfirmationModal({
			transfer,
			updateHistory: refetch,
		})

	return [
		{
			key: "date",
			caption: formatMessage(transfersMessages.date_and_time),
			render: ({ date }) => formatDate(date),
		},
		{
			key: "currency",
			caption: formatMessage(transfersMessages.coin),
			className: styles.currency,
			render: ({ currency }) => (
				<>
					<CurrencyIcon name={currency.code} size={20} />
					<span>{currency.code}</span>
				</>
			),
		},
		{
			key: "amount",
			caption: formatMessage(transfersMessages.quantity),
			render: ({ amount, currency, is_outgoing }) => (
				<span className={cn({ [styles.outgoing]: is_outgoing, [styles.income]: !is_outgoing })}>
					{`${is_outgoing ? "– " : ""}${formatNumber(+amount, {
						minimumFractionDigits: currency.precision || 2,
						maximumFractionDigits: currency.precision || 8,
					})} ${currency.code}`}
				</span>
			),
		},
		{
			key: "receiver",
			caption: formatMessage(commonMessages.user_uid),
			className: styles.receiver,
			render: ({ receiver }) => (
				<>
					<span>{receiver.uid}</span>
					{receiver.verified && (
						<Badge alpha color={BadgeBackgroundColorEnum.GREEN}>
							{formatMessage(commonMessages.verified)}
						</Badge>
					)}
				</>
			),
		},
		{
			key: "status",
			caption: formatMessage(historyMessages.orders_table_status),
			className: styles.status,
			render: getStatus,
		},
		{
			key: "message",
			caption: formatMessage(commonMessages.description),
		},
		{
			key: "txid",
			caption: formatMessage(historyMessages.deposits_table_transaction),
			render: ({ txid }) => (txid?.length ? <Ellipser value={txid} style={{ width: 90 }} /> : "–"),
			actions: ({ txid }) =>
				!!txid && [
					{
						icon: "ai-share_outline",
						visible: !!share,
						onClick: ({ txid }) => share?.({ text: txid || "" }),
					},
					{
						icon: "ai-copy_new",
						onClick: ({ txid }) => copy(txid || ""),
					},
				],
		},
		{
			key: "actions",
			align: ETableColumnAlign.MIDDLE,
			width: 80,
			caption: formatMessage(commonMessages.action),
			className: styles.actions,
			actions: transfer => [
				{
					icon: "ai-check",
					caption: formatMessage(commonMessages.accept),
					visible: transfer.can_accept && !transfer.can_cancel,
					onClick: acceptHandler(transfer),
				},
			],
		},
	]
}

export const useFilters = (): TTableFilters<IInternalTransferDetails> => {
	const { formatMessage } = useIntl()
	const {
		account: { balances },
	} = useMst()

	return [
		{
			key: "currency",
			type: ETableFilterType.SELECT,
			items: balances?.map(({ code, name }) => ({ value: code, label: code, description: name })),
			caption: formatMessage(exchangeMessages.coin),
			clearable: true,
		},
	]
}
