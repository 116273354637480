import { TCurrencyShort } from "types/finances";

export enum EDepositStatus {
  PENDING = 1, //  # when waiting for confirmation
  MODERATION = 2, //  # when manual or additional moderation need (no usage)
  CONFIRMED = 5, //  # when on balance
  REJECTED = 6, //  # when impossible to approve
}

export type TDepositMethodNetwork = {
	name: string
}

export type TDepositMethodContract = {
	name: string
	type: string
	address: string
}

export type TDepositMethodNote = {
	name: string
	type: string
	address: string
}

export type TDepositMethodAttribute = {
	label: string
	name: string
	value: string
	show_qr: boolean
}

export type TDepositMethod = {
	id: number
	name: string
	image_svg?: string
	image_png?: string
	min_deposit: string
	max_deposit: string
	deposit_fee_amount: string
	deposit_fee_rate: string
	deposit_confirmations_need: number
	currency: TCurrencyShort
	network?: TDepositMethodNetwork
	blockchain_block_interval?: number
	min_verification_level?: number
	is_sci: boolean
	contract?: TDepositMethodContract
	notes?: TDepositMethodNote[]
	attributes?: TDepositMethodAttribute[]
	is_deposit_enabled?: boolean
}

export type TDepositStatus = {
	id: EDepositStatus
	label: string
}

export type TDeposit = {
	amount: string
	confirmations?: number
	currency?: TCurrencyShort
	currency_id?: string
	id: number
	date: string
	fee_rate: string
	fee_value: string
	payment_method_name: string
	status: TDepositStatus
	required_confirmations: number
	txid?: string
	txid_url?: string
	reject_reason?: string
}

export interface ICreateDepositReq {
	payment_type: number
	amount: number
}

export interface ICreateDepositRes {
	redirect_url: string
}

export interface IGetDepositHistoryParams {
	currency?: string
	page?: number
	"page-size"?: number
}

export interface IGetDepositHistoryResponse {
	count: number
	results: TDeposit[]
}

export interface IGetDepositMethodsParams {
	currency: string
}

export type TGetDepositMethodsResponse = TDepositMethod[]
