/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useCallback, useEffect, useState } from "react"

import { IRegisterResponse } from "types/register"
import useAccount from "hooks/useAccount"

import HelmetRegister from "components/NewUI/Helmets/Register"
import PinCode from "components/NewUI/Auth/PinCode"
import RegPersonalForm from "components/NewUI/Auth/RegPersonalForm"
import RegJuridicalForm from "components/NewUI/Auth/RegJuridicalForm"
import RegWelcome from "components/NewUI/Auth/RegWelcome"
import RegForm from "components/NewUI/Auth/RegForm"

import { EAccountType, ERegisterStep } from "./types"
import { RegisterContext } from "./context"

const Register: React.FC = () => {
	const [step, setStep] = useState<ERegisterStep>()
	const [token, _setToken] = useState<string>("")
	const [email, _setEmail] = useState<string>("")
	const [type, setType] = useState<EAccountType>(EAccountType.PERSONAL)

	const [response, setResponse] = useState<IRegisterResponse>()

	const { isAuthenticated, isLoaded, name, type: accountType } = useAccount()
	useEffect(() => {
		if (isAuthenticated && isLoaded && !name)
			setStep(
				accountType === EAccountType.JURIDICAL
					? ERegisterStep.JURIDICAL_INFO
					: ERegisterStep.PERSONAL_INFO,
			)
	}, [isAuthenticated, isLoaded, name])

	const setToken = useCallback((token?: string) => _setToken(token || ""), [_setToken])
	const setEmail = useCallback((email?: string) => _setEmail(email || ""), [_setEmail])

	const steps = {
		[ERegisterStep.PINCODE]: <PinCode />,
		[ERegisterStep.PERSONAL_INFO]: <RegPersonalForm />,
		[ERegisterStep.JURIDICAL_INFO]: <RegJuridicalForm />,
		[ERegisterStep.FINISH]: <RegWelcome />,
	}

	return (
		<RegisterContext.Provider
			value={{
				step,
				setStep,
				token,
				setToken,
				email,
				setEmail,
				type,
				setType,
				response,
				setResponse,
			}}
		>
			<HelmetRegister />
			{steps[step as keyof typeof steps] || <RegForm />}
		</RegisterContext.Provider>
	)
}

export default Register
