import React, { isValidElement } from "react"
import cn from "classnames"
import { AlphaIcon, Button } from "@btc-alpha/ui-components"

import { TItem } from "../types"
import { ITEM_ICON } from "../constants";

import useDropDown, { TItemsActivateHandler } from "./useDropDown"

import styles from "../navigation.module.scss"

type TProps = {
	item: TItem
	onItemsActivate?: TItemsActivateHandler
}

const Item: React.FC<TProps> = ({ item, onItemsActivate }) => {
	const { handleMouseEnter, handleMouseLeave, portal } = useDropDown(
		item.items,
		({ parent }) => {
			const { right = 0, top = 0 } = parent?.getBoundingClientRect() || {}
			return { left: right, top }
		},
		onItemsActivate,
	)

	const isItems = item.items?.some(
		subItem => subItem && (isValidElement(subItem) || !(subItem as TItem).hidden),
	)

	return (
		<>
			<Button
				kind="secondary"
				icon={item.icon}
				iconSize={ITEM_ICON}
				to={item.link}
				disabled={item.disabled}
				className={cn(styles.item, { [styles.disabled]: item.disabled })}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={item.onClick}
			>
				<div>
					<div>
						<span>{item.title}</span>
						{!!item.label && (
							<div
								className={cn(styles.label, {
									[styles.labelPrimary]: item.labelType === "primary",
									[styles.labelSecondary]: item.labelType === "secondary",
								})}
							>
								{item.label}
							</div>
						)}
					</div>
					{!!item.description && <p>{item.description}</p>}
				</div>
				{isItems && <AlphaIcon name="ai-chevron_right" size={12} className={styles.chevron} />}
			</Button>
			{portal}
		</>
	)
}

export default Item
