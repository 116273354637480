import { TradeActionsEnum } from "types/exchange";
import { EOrderSide, EOrderStatus, EOrderType } from "types/orders";
import { flip } from "utils/format";

export const ORDER_TYPE: Record<string, number> = {
	[EOrderType.MARKET]: 1,
	[EOrderType.LIMIT]: 2,
	[EOrderType.STOP_ORDER]: 3,
};

export const ORDER_SIDE: Record<string, number> = {
	[TradeActionsEnum.SELL]: 1,
	[TradeActionsEnum.BUY]: 2,
};

export const ORDER_STATUS: Record<string, number> = {
	[EOrderStatus.PENDING]: 1,
	[EOrderStatus.OPEN]: 2,
	[EOrderStatus.CANCELLED]: 3,
	[EOrderStatus.PARTIAL_CANCELED]: 4,
	[EOrderStatus.PARTIAL_CANCELLED]: 4,
	[EOrderStatus.PARTIAL_FILLED]: 5,
	[EOrderStatus.FILLED]: 6,
	[EOrderStatus.EXPIRED]: 7,
	[EOrderStatus.FAILED]: 8,
};

export const ORDER_STATUS_TEXT: Record<string, string> = {
	[EOrderStatus.PENDING]: "pending",
	[EOrderStatus.OPEN]: "open",
	[EOrderStatus.CANCELLED]: "cancelled",
	[EOrderStatus.PARTIAL_CANCELED]: "partial cancelled",
	[EOrderStatus.PARTIAL_CANCELLED]: "partial cancelled",
	[EOrderStatus.PARTIAL_FILLED]: "partial filled",
	[EOrderStatus.FILLED]: "filled",
	[EOrderStatus.EXPIRED]: "expired",
	[EOrderStatus.FAILED]: "failed",
};

export const ORDER_TYPE_FLIPPED: Record<number, string> = flip(ORDER_TYPE);
export const ORDER_SIDE_FLIPPED: Record<number, string> = flip(ORDER_SIDE);
export const ORDER_STATUS_FLIPPED: Record<number, string> = flip(ORDER_STATUS);
