/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import {
	ETableFilterType,
	messageSuccess,
	TTableColumns,
	TTableFilters,
} from "@btc-alpha/ui-components"

import { EOrderNumberType, EOrderNumberSide } from "types/orders"
import { useMst } from "models/Root"
import { IHistoryOrderPair, IOrder } from "models/History"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import ExchangeService from "services/ExchangeService"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION, PERCENT_PRECISION } from "utils/constants"
import { formatDate } from "utils/formatDate"
import errorHandler from "utils/errorHandler"

import Badge, { BadgeColorEnum } from "components/UI/Badge"

const directionColor: Record<EOrderNumberSide, BadgeColorEnum> = {
	[EOrderNumberSide.BUY]: BadgeColorEnum.GREEN,
	[EOrderNumberSide.SELL]: BadgeColorEnum.RED,
}

const orderTypeLabel: Record<number, MessageDescriptor> = {
	[EOrderNumberType.MARKET]: exchangeMessages.order_type_market,
	[EOrderNumberType.LIMIT]: exchangeMessages.order_type_limit,
	[EOrderNumberType.STOP_LIMIT]: exchangeMessages.order_type_stop_limit,
	[EOrderNumberType.STOP_MARKET]: exchangeMessages.order_type_stop_market,
}

export const useColumns = (refetch?: () => void): TTableColumns<IOrder> => {
	const { formatNumber, formatMessage } = useIntl()

	const getFormatedNumber = (value: number | null, precision: number = MAX_PRICE_PRECISION) =>
		formatNumber(value || 0, {
			minimumFractionDigits: MIN_PRICE_PRECISION,
			maximumFractionDigits: Math.max(precision, MIN_PRICE_PRECISION),
		})

	const getCoins = (pair?: IHistoryOrderPair | null) => {
		const [base = "", quote = ""] = pair?.symbol?.split("_") || []
		return { base, quote }
	}

	const cancelHandler = (id: number) => async () => {
		try {
			await ExchangeService.cancelOrder(id)
			messageSuccess(formatMessage(historyMessages.order_was_cancelled))
			refetch?.()
		} catch (err) {
			errorHandler(err, false)
		}
	}

	return [
		{
			key: "date",
			caption: formatMessage(historyMessages.date),
			width: 50,
			render: ({ date }) => formatDate(date * 1000),
		},
		{
			key: "pair",
			caption: formatMessage(historyMessages.active_orders_pair),
			render: ({ pair }) => pair?.label || "",
		},
		{
			key: "type",
			caption: formatMessage(historyMessages.active_orders_type),
			render: ({ type }) =>
				formatMessage(orderTypeLabel[type as never] || { id: "orderType", defaultMessage: "--" }),
		},
		{
			key: "side",
			caption: formatMessage(historyMessages.direction),
			render: ({ side }) => (
				<Badge color={directionColor[side as never]} alpha>
					{formatMessage(
						+side === EOrderNumberSide.BUY
							? historyMessages.orders_table_type_buy
							: historyMessages.orders_table_type_sell,
					)}
				</Badge>
			),
		},
		{
			key: "price",
			caption: formatMessage(historyMessages.active_orders_price),
			render: ({ price, pair }) =>
				`${getFormatedNumber(price, pair?.price_precision)} ${getCoins(pair).base}`,
		},
		{
			key: "amount_original",
			caption: formatMessage(historyMessages.active_orders_original_amount),
			render: ({ amount_original, pair }) =>
				`${getFormatedNumber(amount_original, pair?.amount_precision)} ${getCoins(pair).quote}`,
		},
		{
			key: "filled_percent",
			caption: formatMessage(historyMessages.active_orders_filled),
			render: ({ filled_percent }) =>
				`${formatNumber(filled_percent || 0, {
					minimumFractionDigits: PERCENT_PRECISION,
					maximumFractionDigits: PERCENT_PRECISION,
				})}%`,
		},
		{
			key: "quote_amount",
			caption: formatMessage(historyMessages.active_orders_total),
			render: ({ quote_amount, pair }) =>
				`${getFormatedNumber(quote_amount, pair?.amount_precision)} ${getCoins(pair).quote}`,
		},
		{
			key: "amount_unfilled",
			caption: formatMessage(historyMessages.active_orders_amount),
			render: ({ amount_unfilled, pair }) =>
				`${getFormatedNumber(amount_unfilled, pair?.amount_precision)}  ${getCoins(pair).quote}`,
		},
		{
			key: "actions",
			caption: formatMessage(historyMessages.orders_table_action),
			width: 1,
			actions: ({ id, pair }) => [
				{
					icon: "ai-cancel_mini",
					onClick: cancelHandler(id),
				},
			],
		},
	]
}

export const useFilters = (): TTableFilters<IOrder> => {
	const { formatMessage } = useIntl()
	const {
		tickers: { loadTickers, list: tickers },
	} = useMst()

	useEffect(() => {
		!tickers?.length && loadTickers()
	}, [tickers])

	return [
		{
			key: "pair",
			type: ETableFilterType.SELECT,
			items: tickers?.map(({ base_currency, quote_currency }) => ({
				label: `${base_currency?.code}/${quote_currency?.code}`,
				value: `${base_currency?.code}_${quote_currency?.code}`,
			})),
			caption: formatMessage(historyMessages.quote_assets),
			clearable: true,
		},
		{
			key: "side",
			type: ETableFilterType.SELECT,
			items: [
				{
					value: EOrderNumberSide.BUY,
					label: formatMessage(historyMessages.orders_table_type_buy),
				},
				{
					value: EOrderNumberSide.SELL,
					label: formatMessage(historyMessages.orders_table_type_sell),
				},
			],
			caption: formatMessage(historyMessages.transfers_direction),
			clearable: true,
		},
		{
			key: "date",
			type: ETableFilterType.DATE,
			caption: formatMessage(historyMessages.orders_table_date),
			clearable: true,
		},
	]
}
