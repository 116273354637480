import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import { COIN_USDT } from "constants/common"
import commonMessages from "messages/common"
import { URL_VARS } from "constants/routing"
import { queryVars } from "constants/query"
import { useMst } from "models/Root"
import Input from "components/NewUI/Input"
import SearchIcon from "assets/icons/terminal/Search"
import { TickersQuotedEnum } from "types/ticker"
import { QUOTED_CURRENCIES_BASE, QUOTED_CURRENCIES_FIAT } from "models/Ticker"
import styles from "./index.module.scss"
import Tab from "./Tab"
import Item from "./Item"
import CoinsHeader from "./CoinsHeader"

const Search: React.FC = () => {
	const {
		tickers: {
			filter: { quotedCurrency, setSearch, search, setQuotedCurrency, tradeType },
			formattedList,
			updateFavoritePair,
		},
		global: { locale, isAuthenticated },
		render: { margin: isMargin },
	} = useMst()
	const navigate = useNavigate()
	const { formatMessage } = useIntl()
	// We form the tab list from favorites, fiat and the currency list.
	const tabList = useMemo(() => {
		const arr = [
			{
				label: formatMessage(commonMessages.favorites),
				// isActive: filter.quotedCurrency === TickersQuotedEnum.FAVORITES,
				name: TickersQuotedEnum.FAVORITES,
			},
		]

		const QUOTED_CURRENCIES_BASE_ARR = QUOTED_CURRENCIES_BASE.map(currency => ({
			label: currency,
			name: currency,
		})).reverse()

    const result = [
      ...arr,
      ...QUOTED_CURRENCIES_BASE_ARR,
    ]

    if (QUOTED_CURRENCIES_FIAT.length > 0)
      result.push({
        label: TickersQuotedEnum.FIAT,
        name: TickersQuotedEnum.FIAT,
      })

    return result.filter(({ name }) => name.toUpperCase() !== COIN_USDT)
	}, [TickersQuotedEnum, formatMessage, QUOTED_CURRENCIES_BASE, QUOTED_CURRENCIES_FIAT])

	const handleTickerFavoriteClick = (symbol: string, isFavorite: boolean) => {
		if (isAuthenticated) {
			updateFavoritePair(symbol, isFavorite).then(() => null)
		}
	}

	const handleLinkClick = (symbol: string) => {
		navigate(`/${locale}/${URL_VARS.TRADE}/${symbol}?${queryVars.type}=${tradeType}`)
		window.location.reload()
	}

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)

	return (
		<div className={styles.search}>
			<div className={styles.search__inputWrapper}>
				<Input
					prefixIcon={<SearchIcon />}
					value={search}
					placeholder={formatMessage(commonMessages.search)}
					onChange={handleSearchChange}
					type="text"
				/>
			</div>

			<ul className={styles.search__topList}>
				{tabList.map(({ name, label }) => (
					<Tab
						key={`${label}-radioLabel`}
						name={name}
						label={label}
						quotedCurrency={quotedCurrency}
						setQuotedCurrency={setQuotedCurrency}
					/>
				))}
			</ul>

			{/* <ul className={styles.search__optionsList}>
				<li className={styles.search__optionsItem}>
					<button
						className={`${styles.search__optionsItemButton} ${styles.search__optionsItemButton___active}`}
						type="button"
					>
						All
					</button>
				</li>

				<li className={styles.search__optionsItem}>
					<button className={styles.search__optionsItemButton} type="button">
						0 Fees
					</button>
				</li>

				<li className={styles.search__optionsItem}>
					<button className={styles.search__optionsItemButton} type="button">
						Arbitrum
					</button>
				</li>
			</ul> */}

			<div className={styles.search__coins}>
				<CoinsHeader />

				{formattedList.length > 0 ? (
					<ul className={styles.search__coinsList}>
						{formattedList.map(item => (
							<Item
								key={`${item.base_currency_code}-${item.quote_currency_code}-coinsList`}
								item={item}
								handleLinkClick={handleLinkClick}
								handleTickerFavoriteClick={handleTickerFavoriteClick}
								isMargin={isMargin}
								isFiat={
									quotedCurrency === "FIAT" ||
									QUOTED_CURRENCIES_FIAT.includes(item.quote_currency_code)
								}
							/>
						))}
					</ul>
				) : (
					<p style={{ textAlign: "center", marginTop: "30px" }}>This list is empty</p>
				)}
			</div>
		</div>
	)
}

export default observer(Search)
