/* eslint-disable no-unused-expressions */
import React from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Button, Table, TTableOnFetch } from "@btc-alpha/ui-components"

import { TDeposit } from "types/deposit"
import { queryVars } from "constants/query"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import historyMessages from "messages/history"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import DepositService from "services/DepositService"
import { getPageTitle } from "helpers/global";

import RedesignedLayout from "layouts/RedesignedLayout"

import AnnotationQuestionIcon from "assets/icons/annotation-question.svg"

import { useColumns } from "pages/FinanceHistory/Deposits/hooks"

import DepositForm from "./DepositForm"
import DepositQuestions from "./DepositQuestions"
import styles from "./deposit.module.scss"

const HISTORY_SIZE = 10

const Deposit: React.FC = () => {
	const { formatMessage } = useIntl()
	const { currency = "" } = useParams<{ [queryVars.currency]: string }>()
	const localeNavigate = useLocaleNavigate()

	const columns = useColumns()

	const handleFetch: TTableOnFetch<TDeposit> = async () => {
		const { results, count } = await DepositService.getPreviousDeposits({
			"page-size": HISTORY_SIZE,
		})
		return { data: results, count }
	}

	const handleAllClick = () => localeNavigate(routes.financeHistory.root)

	return (
		<RedesignedLayout
			title={formatMessage(commonMessages.deposit)}
			helmet={{ title: getPageTitle(formatMessage(commonMessages.deposit)) }}
			className={styles.deposit}
		>
			<div className={styles.main}>
				<DepositForm currency={currency} />
				<div className={styles.info}>
					<header>
						<span>{formatMessage(commonMessages.common_questions)}</span>
						<Button
							caption={formatMessage(commonMessages.help_center)}
							icon={AnnotationQuestionIcon}
							size="mini"
							kind="outlined"
							to={routes.support.root}
						/>
					</header>
					<div className={styles.faq}>
						<DepositQuestions />
					</div>
				</div>
			</div>
			<div className={styles.history}>
				<h2>{formatMessage(historyMessages.deposits_history)}</h2>
				<Table
					columns={columns}
					empty={formatMessage(commonMessages.no_orders_to_show)}
					lazyLoad
					defFetching
					footer="all"
					defLimit={HISTORY_SIZE}
					onFetch={handleFetch}
					onAllClick={handleAllClick}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(Deposit)
