import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import stylesModal from "styles/components/UI/Modal.module.scss";
import styles from "styles/components/Profile/Verification/DocumentUploadModal.module.scss";
import verificationMessages from "messages/verification";
import commonMessages from "messages/common";
import Modal, {
	ActionGroup,
	BodyContainer,
	Content,
	Description,
	Footer,
} from "components/UI/Modal";
import { observer } from "mobx-react-lite";
import DocFrontSideImg from "assets/images/profile/verification/doc-front-side.svg";
import DocBackSideImg from "assets/images/profile/verification/doc-back-side.svg";
import DocFrontSideBlurredImg from "assets/images/profile/verification/doc-front-side-blurred.svg";
import DocFrontSideCuttedImg from "assets/images/profile/verification/doc-front-side-cutted.svg";
import DocFrontSideGlareImg from "assets/images/profile/verification/doc-front-side-glare.svg";
import DocBackSideBlurredImg from "assets/images/profile/verification/doc-back-blurred.svg";
import DocBackSideCuttedImg from "assets/images/profile/verification/doc-back-cutted.svg";
import DocBackSideGlareImg from "assets/images/profile/verification/doc-back-glare.svg";
import SelfieImg from "assets/images/profile/verification/doc-selfie.svg";
import { ReactComponent as ImgRuleIcon } from "assets/images/profile/verification/doc-img-rule-icon.svg";
import { useDropzone } from "react-dropzone";
import { MAX_UPLOAD_FILE_SIZE_MB } from "utils/constants";
import SuccessScreen from "components/UI/SuccessScreen";
import Button from "components/UI/Button";
import { validateFileSize } from "utils/fileHelper";
import {
	DocSideImageEnum,
	IFile,
	LevelEnum,
	VariantDocumentsEnum,
	VariantEnum,
} from "types/verification";
import classnames from "classnames";
import ButtonMicro from "components/UI/Button/ButtonMicro";
import errorHandler from "utils/errorHandler";
import { isSafari } from "utils/browser";
import Stepper from "components/Stepper";

interface IProps {
	variant: VariantEnum;
	level: LevelEnum;
	isOpen: boolean;
	update?(data: FormData): void;
	onClose(): void;
	hasFrontBackSide?: boolean;
	initialDocSideImage?: DocSideImageEnum;
}

enum ActiveStepEnum {
	FRONT_SIDE = 1,
	BACK_SIDE = 2,
}

const DocumentUploadModal: React.FC<IProps> = ({
	variant,
	level,
	update,
	isOpen,
	onClose,
	hasFrontBackSide,
	initialDocSideImage,
}) => {
	const { formatMessage } = useIntl();

	const [files, setFiles] = useState<IFile[]>([]);
	const [fileError, setFileError] = useState<string>("");
	const [isUploading, setUploading] = useState<boolean>(false);
	const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
	const [activeStep, setActiveStep] = useState<number>(ActiveStepEnum.FRONT_SIDE);

	const isDocument = variant === VariantEnum.Document;
	const isSelfie = variant === VariantEnum.Selfie;
	const isFrontSide =
		activeStep === ActiveStepEnum.FRONT_SIDE && initialDocSideImage !== DocSideImageEnum.Back;

	useEffect(() => {
		setIsSuccessful(false);
	}, [isOpen]);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const acceptedFile = acceptedFiles.length > 0 ? acceptedFiles[0] : null;
			if (!acceptedFile) {
				setFileError(formatMessage(commonMessages.invalid_file_type));
				return;
			}
			if (!validateFileSize(acceptedFile, MAX_UPLOAD_FILE_SIZE_MB)) {
				setFileError(
					`${formatMessage(verificationMessages.maximum_size)}: ${MAX_UPLOAD_FILE_SIZE_MB}MB`,
				);
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(acceptedFile);
			reader.onload = () => {
				const file: IFile = {
					file: acceptedFile,
					preview: reader.result,
				};
				if (file === null) return;
				setFiles((state: IFile[]) => [...state, file]);
				setUploading(false);
			};
			reader.onerror = () => {
				console.error(reader.error);
			};
			setFileError("");
		},
		[variant, level],
	);

	const handleSave = async () => {
		try {
			if (!files.length) return;

			setUploading(true);
			const data = new FormData();
			switch (level) {
				case LevelEnum.Personal:
					if (isDocument) {
						const docFront = files[0]?.file ?? null;
						const docBack = files.length > 1 ? files[1]?.file : null;
						if (docFront != null) {
							data.append(VariantDocumentsEnum.IdentityFrontDocument, docFront);
						}
						if (docBack != null) {
							data.append(VariantDocumentsEnum.IdentityBackDocument, docBack);
						}
					}
					if (isSelfie) {
						const selfie = files[0]?.file;
						if (selfie != null) {
							data.append(VariantDocumentsEnum.Selfie, selfie);
						}
					}

					break;
				case LevelEnum.Address:
					if (files[0].file != null) {
						data.append("document", files[0].file);
					}
					break;
				case LevelEnum.Finance:
					if (files[0].file != null) {
						data.append("document", files[0].file);
					}
					break;
				default:
					break;
			}
			await update?.(data);
			setIsSuccessful(true);
			setUploading(false);
		} catch (err: any) {
			setIsSuccessful(false);
			if (!err) return;
			errorHandler(err);
		} finally {
			setUploading(false);
		}
	};

	const handleSubmit = () => {
		if (hasFrontBackSide && activeStep === ActiveStepEnum.FRONT_SIDE) {
			setActiveStep(ActiveStepEnum.BACK_SIDE);
		} else {
			handleSave();
		}
	};

	const handleBack = () => {
		setActiveStep(ActiveStepEnum.FRONT_SIDE);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: true,
		multiple: true,
		accept: {
			"image/jpeg": [".jpeg", ".png", ".jpg"],
		},
	});

	const onDeleteFile = (index: number) => {
		setFiles((f) => {
			f.splice(index, 1);
			return [...f];
		});
	};

	return (
		<Modal
			iconCode="camera_on"
			className={styles.modal_container}
			onClose={onClose}
			isOpen={isOpen}
			label={formatMessage(
				isDocument
					? hasFrontBackSide
						? activeStep === ActiveStepEnum.FRONT_SIDE
							? verificationMessages.upload_front_side_photo
							: verificationMessages.upload_backside_photo
						: verificationMessages.upload_document_photo
					: verificationMessages.upload_selfie_with_document,
			)}
		>
			{isSuccessful ? (
				<div className={stylesModal.content}>
					<SuccessScreen>{formatMessage(commonMessages.photo_added_successfully)}</SuccessScreen>
				</div>
			) : (
				<>
					<BodyContainer>
						<Content className={isSuccessful ? undefined : styles.content}>
							<Description noMargin>
								{isDocument
									? formatMessage(verificationMessages.upload_a_photo_of_your_documents)
									: formatMessage(verificationMessages.upload_a_selfie_with_documents)}
								.&nbsp;
								{formatMessage(verificationMessages.supported_file_types)}
								&nbsp;
								<b>
									JPG, PNG. {formatMessage(verificationMessages.maximum_size)}
									:&nbsp;
									{MAX_UPLOAD_FILE_SIZE_MB} MB
								</b>
							</Description>
							{hasFrontBackSide && (
								<Stepper
									color="#6B22B2"
									className={styles.stepper}
									active={activeStep}
									steps={[
										{ id: 1, label: "" },
										{ id: 2, label: "" },
									]}
								/>
							)}
							<div className={styles.modal_instruction}>
								{files.length > activeStep - 1 ? (
									<div className={classnames(styles.modal_upload_file, "aa-fade-in")}>
										<div className={classnames(styles.modal_upload_file_preview, "aa-fade-in")}>
											<div className={styles.modal_upload_file_preivew_content}>
												<ButtonMicro
													className={styles.delete_button}
													onClick={() => onDeleteFile(0)}
												>
													<i className="ai ai-cancel_mini" />
												</ButtonMicro>
												<img
													src={files[activeStep - 1].preview as string}
													alt={`preview${activeStep - 1}_${files[activeStep - 1]?.file?.name}`}
													width="250"
													height="140"
												/>
											</div>
										</div>
									</div>
								) : (
									<img
										className={styles.check}
										src={isDocument ? (isFrontSide ? DocFrontSideImg : DocBackSideImg) : SelfieImg}
										width="280px"
										height={isDocument ? "177px" : "200px"}
										alt="Document"
									/>
								)}
								<div className={styles.invalid_previews}>
									<div className={styles.invalid_previews_item}>
										<img
											src={isFrontSide ? DocFrontSideBlurredImg : DocBackSideBlurredImg}
											width="81"
											height="51"
											alt="Invalid Document"
										/>
										<span>{formatMessage(verificationMessages.not_blurry)}</span>
									</div>
									<div className={styles.invalid_previews_item}>
										<img
											src={isFrontSide ? DocFrontSideCuttedImg : DocBackSideCuttedImg}
											width="81"
											height="51"
											alt="Invalid Document"
										/>
										<span>{formatMessage(verificationMessages.not_cutted)}</span>
									</div>
									<div className={styles.invalid_previews_item}>
										<img
											src={isFrontSide ? DocFrontSideGlareImg : DocBackSideGlareImg}
											width="81"
											height="51"
											alt="Invalid Document"
										/>
										<span>{formatMessage(verificationMessages.no_glare)}</span>
									</div>
								</div>
								<div className={styles.modal_instruction_img_rules}>
									<div className={styles.modal_instruction_img_rules_item}>
										<ImgRuleIcon />
										<span>{formatMessage(verificationMessages.good_readable_information)}</span>
									</div>
									<div className={styles.modal_instruction_img_rules_item}>
										<ImgRuleIcon />
										<span>{formatMessage(verificationMessages.not_black_and_white_photo)}</span>
									</div>
									<div className={styles.modal_instruction_img_rules_item}>
										<ImgRuleIcon />
										<span>{formatMessage(verificationMessages.unedited_photo)}</span>
									</div>
									<div className={styles.modal_instruction_img_rules_item}>
										<ImgRuleIcon />
										<span>{formatMessage(verificationMessages.document_not_expired)}</span>
									</div>
								</div>
							</div>
						</Content>
					</BodyContainer>
					<Footer>
						{files.length < activeStep ? (
							<>
								<div className={styles.upload_file_zone_container}>
									<label
										htmlFor="upload_input"
										{...getRootProps()}
										className={classnames(styles.modal_upload_file_zone, {
											[styles.safari]: isSafari,
										})}
									>
										<i className="ai ai-upload" />
										{formatMessage(
											isDragActive
												? verificationMessages.drop_the_file_here
												: isDocument
												? verificationMessages.upload_document_photo
												: verificationMessages.upload_selfie_with_document,
										)}
										<input
											id="upload_input"
											name="upload_input"
											type="file"
											{...getInputProps()}
											style={{ display: isSafari ? "block" : "none" }}
											accept="image/png, image/jpeg, image/jpg"
										/>
									</label>
									{fileError && (
										<div className={styles.modal_upload_file_error}>
											<i className="ai ai-warning" />
											{fileError}
										</div>
									)}
								</div>
								<ActionGroup noMargin>
									{activeStep === ActiveStepEnum.BACK_SIDE && (
										<Button
											fullWidth
											color="primary"
											variant="text"
											onClick={handleBack}
											label={formatMessage(commonMessages.back_btn)}
										/>
									)}
								</ActionGroup>
							</>
						) : (
							<ActionGroup noMargin>
								<Button
									fullWidth
									color="primary"
									variant="filled"
									label={formatMessage(
										hasFrontBackSide && activeStep === ActiveStepEnum.FRONT_SIDE
											? commonMessages.continue
											: commonMessages.submit,
									)}
									onClick={handleSubmit}
									isLoading={isUploading}
								/>
								{activeStep === ActiveStepEnum.BACK_SIDE && (
									<Button
										fullWidth
										color="primary"
										variant="text"
										onClick={handleBack}
										label={formatMessage(commonMessages.back_btn)}
									/>
								)}
							</ActionGroup>
						)}
					</Footer>
				</>
			)}
		</Modal>
	);
};

export default observer(DocumentUploadModal);

interface IDocumentUploadProps {
	verified: boolean;
	src: string;
	alt: string;
	message: string;
	level: LevelEnum;
	variant: VariantEnum;
	disabled?: boolean;
	error?: string | string[];
	update?(data: FormData): void;
	onClick?(): void;
	hasFrontBackSide?: boolean;
	initialDocSideImage?: DocSideImageEnum;
}

export const DocumentUpload: React.FC<IDocumentUploadProps> = ({
	verified,
	src,
	message,
	alt,
	variant,
	level,
	disabled,
	error,
	update,
	onClick,
	hasFrontBackSide,
	initialDocSideImage,
}) => {
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	return (
		<>
			{isModalOpen && (
				<DocumentUploadModal
					isOpen={isModalOpen}
					onClose={() => setModalOpen(false)}
					variant={variant}
					level={level}
					update={update}
					hasFrontBackSide={hasFrontBackSide}
					initialDocSideImage={initialDocSideImage}
				/>
			)}
			<div className={styles.form_preview_image}>
				{verified && <i className="ai ai-check_outline" />}
				{!verified && <img src={src} width="120" height="94" alt={alt} />}
			</div>
			<button
				className={classnames(styles.form_upload_button, {
					[styles.verified]: verified,
					[styles.error]: !disabled && !!error,
					[styles.disabled]: disabled,
				})}
				type="button"
				onClick={() => {
					if (onClick) {
						onClick();
					}
					if (!disabled) {
						setModalOpen(true);
					}
				}}
			>
				{message}
			</button>
		</>
	);
};
