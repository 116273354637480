/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { useIntl } from "react-intl"
import { Collapser } from "@btc-alpha/ui-components"

import commonMessages from "messages/common"
import financeMessages from "messages/finance"

const DepositQuestions: React.FC = () => {
	const { formatMessage } = useIntl()

	return (
		<>
			<Collapser label={formatMessage(commonMessages.how_to_invite_users)}>
				<p>{formatMessage(financeMessages.questions_1_text)}</p>
			</Collapser>
			<Collapser label={formatMessage(commonMessages.referral_program_bonuses)}>
				<p>{formatMessage(financeMessages.questions_2_subtitle)}:</p>
				<ol>
					<li>
						<b>{formatMessage(financeMessages.questions_2_text_a)}</b>
						{formatMessage(financeMessages.questions_2_text_b)}
					</li>
					<li>
						<b>{formatMessage(financeMessages.questions_2_text_c)}</b>
						{formatMessage(financeMessages.questions_2_text_d)}
					</li>
				</ol>
				<p>{formatMessage(financeMessages.questions_2_text_e)}</p>
			</Collapser>
			<Collapser label={formatMessage(commonMessages.referral_program_conditions)}>
				<p>{formatMessage(financeMessages.questions_3_text_a)}</p>
				<p>
					<b>{formatMessage(financeMessages.questions_3_text_b)}</b>
					{formatMessage(financeMessages.questions_3_text_c)}
				</p>
				<p>
					<b>{formatMessage(financeMessages.questions_3_text_d)}</b>
					{formatMessage(financeMessages.questions_3_text_e)}
				</p>
			</Collapser>
		</>
	)
}

export default DepositQuestions
