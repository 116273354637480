import React, { useEffect } from "react"

import useWindowSize from "hooks/useWindowSize"
import Navigation from "layouts/RedesignedLayout/navigation"
import HomePageHelmet from "components/NewUI/Helmets/HomePage"
import Banner from "components/NewUI/HomePage/Banner"
import Benefits from "components/NewUI/HomePage/Benefits"
import Supports from "components/NewUI/HomePage/Supports"
import USP from "components/NewUI/HomePage/USP"
import Partners from "components/NewUI/HomePage/Partners"
import CryptoCards from "components/NewUI/HomePage/CryptoCards"
import MobileApp from "components/NewUI/HomePage/MobileApp"
import Communities from "components/NewUI/HomePage/Communities"
import FooterLarge from "components/NewUI/Footer/Large"
import Cookies from "components/Cookies"
import MobileWelcomePage from "components/NewUI/HomePage/MobileWelcomePage"

const HomePage: React.FC = () => {
	const { smallTablet } = useWindowSize()

	useEffect(() => {
		document.body.style.backgroundColor = "#101014"
		document.documentElement.style.backgroundColor = "#101014"

		return () => {
			document.body.style.backgroundColor = "#000"
			document.documentElement.style.backgroundColor = "#101014"
		}
	}, [])

	return smallTablet ? (
		<>
			<HomePageHelmet />
			<MobileWelcomePage />
		</>
	) : (
		<>
			<HomePageHelmet />
			<Navigation />
			<main>
				<Banner />
				<Benefits />
				<Supports />
				<USP />
				<Partners />
				<CryptoCards />
				<MobileApp />
				<Communities />
			</main>
			<FooterLarge isHomePage />
			<Cookies />
		</>
	)
}

export default HomePage
