/* eslint-disable consistent-return,@typescript-eslint/ban-types,no-irregular-whitespace */
import { useCallback } from "react"
import { useLocation } from "react-router-dom"
import cookies from "js-cookie"

import { routes } from "constants/routing"
import { COOKIE_P2P_SCAMMER_ATTENTION } from "constants/p2p"
import config from "helpers/config"
import useLocaleNavigate, { TNavigationParams, makePath } from "hooks/useLocaleNavigate"
import usePathname from "hooks/usePathname"
import AuthService from "services/AuthService"
import { useMst } from "models/Root"

import ImgHamster from "assets/images/characters/hamster.png"

// import ImgBull from "assets/images/characters/bull.png"
// import ImgBear from "assets/images/characters/bear.png"
// import ImgShark from "assets/images/characters/shark.png"
// import ImgOwl from "assets/images/characters/owl.png"
// import ImgLion from "assets/images/characters/lion.png"
// import ImgDragon from "assets/images/characters/dragon.png"
// import ImgWhale from "assets/images/characters/whale.png"

const useAccount = () => {
	const {
		global: { isAuthenticated, setIsAuthenticated },
		account: { profileStatus, isProfileStatusLoaded, loadProfileStatus },
	} = useMst()
	const localeNavigate = useLocaleNavigate()
	const pathname = usePathname()

	const logout = async () => {
		try {
			await AuthService.logout()
		} finally {
			cookies.remove(config.sessionCookieName)
			cookies.remove(COOKIE_P2P_SCAMMER_ATTENTION)
			localeNavigate(routes.login.redirect(pathname))
			setIsAuthenticated(false)
		}
	}

	/**
	 * Check if a function or route is available to
	 * the user depending on his verification level
	 *
	 * @overload
	 * @param {string | TNavigationParams} path - Navigation route
	 *
	 * or
	 *
	 * @param {Function} handler - Function that is executed at the required verification level
	 *
	 * @param {number} [level=1] - Required level
	 *
	 * @returns {Function | string | undefined} - Returns
	 * * if user is not authenticated – undefined
	 * * if user verification level is lower than needed:
	 *
	 *         string: verification route
	 *
	 *         Function: navigator to verification route
	 * * if user verification level is sufficient:
	 *
	 *         string | Function: value that you gave
	 */
	const verify = useCallback<{
		(handler: Function, level?: number): Function | undefined
		(path: string | TNavigationParams, level?: number): string | undefined
	}>(
		(handlerOrPath: any, level?: number) => {
			if (!isAuthenticated || typeof profileStatus?.verification_level !== "number") return
			const isHandler = typeof handlerOrPath === "function"
			if ((profileStatus?.verification_level || 0) < (level || 1)) {
				if (isHandler) return () => localeNavigate(routes.verification.overview)
				return makePath(routes.verification.overview)
			}
			if (isHandler) return handlerOrPath
			return makePath(handlerOrPath as string | TNavigationParams)
		},
		[isAuthenticated, profileStatus?.verification_level],
	)

	const {
		is_sub_account: isSubAccount = false,
		is_email_confirmed: isEmailConfirmed = false,
		is_deposit_enabled: isDepositEnabled = false,
		is_withdraw_enabled: isWithdrawEnabled = false,
		is_api_enabled: isApiEnabled = false,
		...data
	} = profileStatus || {}

	return {
		isAuthenticated,
		setIsAuthenticated,
		character: ImgHamster,
		isLoaded: isProfileStatusLoaded,
		...data,
		isDepositEnabled,
		isWithdrawEnabled,
		isSubAccount,
		isEmailConfirmed,
		isApiEnabled,
		isAlphaCodeEnabled: !isSubAccount && isWithdrawEnabled,
		load: loadProfileStatus,
		verify,
		logout,
	}
}

export default useAccount
