import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import messages from "messages/home"

import ArrowRight from "assets/icons/homePage/ArrowRight"
import ProTerminal from "assets/images/HomePage/ProTerminal.webp"
import LiteVersion from "assets/images/HomePage/LiteVersion.webp"
import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import styles from "./index.module.scss"

const Supports: FC = () => (
	<section className={styles.supports}>
		<h2 className="visually-hidden"><FormattedMessage {...messages.support_title} /></h2>

		<div className={styles.supports__container}>
			<p className={styles.supports__headline}><FormattedMessage {...messages.support_title} /></p>

			<p className={styles.supports__headlineContent}>
				<FormattedMessage {...messages.support_description} />
			</p>
		</div>

		<div className={`${styles.supports__container} ${styles.supports__container___content}`}>
			<div className={styles.supports__card}>
				<div className={styles.supports__cardContentWrapper}>
					<h3 className={styles.supports__cardHeadline}><FormattedMessage {...messages.professional_terminal} /></h3>

					<p className={styles.supports__cardContent}>
						<FormattedMessage {...messages.professional_terminal_description} />
					</p>

					<InternalLink className={styles.supports__cardButton} to={routes.trade.root}>
						<span><FormattedMessage {...messages.try_it} /></span>

						<ArrowRight />
					</InternalLink>
				</div>

				<img className={styles.supports__cardImg} src={ProTerminal} alt="pro terminal" />
			</div>

			<div className={styles.supports__card}>
				<div className={styles.supports__cardContentWrapper}>
					<h3 className={styles.supports__cardHeadline}><FormattedMessage {...messages.lite_version} /></h3>

					<p className={styles.supports__cardContent}>
						<FormattedMessage {...messages.lite_version_description} />
					</p>

					<button type="button" disabled className={styles.supports__cardButton}>
						<span><FormattedMessage {...messages.soon} /></span>

						{/*<ArrowRight />*/}
					</button>
				</div>

				<img className={styles.supports__cardImg} src={LiteVersion} alt="lite terminal" />
			</div>
		</div>
	</section>
)

export default Supports
