/* eslint-disable react/jsx-fragments,react/jsx-no-useless-fragment */
import React from "react"
import { Navigate, RouteProps } from "react-router-dom";
import { observer } from "mobx-react-lite"

import { useMst } from "models/Root"
import usePathname from "hooks/usePathname";

const AuthenticatedRoute: React.FC<RouteProps> = ({ children }) => {
	const {
		global: { isAuthenticated, locale },
	} = useMst()
	const pathname = usePathname()

	if (!isAuthenticated) {
		return <Navigate to={`/${locale}/login${pathname ? `?redirect=${pathname}` : ""}`} />
	}

	return <>{children}</>
}

export default observer(AuthenticatedRoute)
