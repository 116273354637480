import React, { MouseEventHandler, useEffect, useState } from "react"
import { createPortal } from "react-dom"

import { TRootItem } from "../types"

import Items from "./Items"

import styles from "../navigation.module.scss"

export type TItemsActivateHandler = (active: boolean) => void

const useDropDown = (
	items: Required<TRootItem>["items"] | undefined,
	getPosition: (args: { rect: DOMRect; trigger: HTMLElement; parent: HTMLElement | null }) => {
		left: number
		top: number
	},
	onItemsActivate?: TItemsActivateHandler,
) => {
	const [element, setElement] = useState<React.ReactNode>(null)

	const updateItems = (target: EventTarget | null, triggered: boolean) => {
		if (!items || !target) return
		const trigger = target as HTMLElement
		const rect = trigger.getBoundingClientRect()
		const parent = trigger.closest<HTMLElement>(`.${styles.items}`)
		const { left, top } = getPosition({ rect, trigger, parent })
		setElement(
			<Items
				triggered={triggered}
				left={left}
				top={top}
				items={items}
				onHide={() => setElement(null)}
				onActivate={onItemsActivate}
			/>,
		)
	}

	const handleMouseEnter: MouseEventHandler<HTMLElement> = ({ currentTarget }) =>
		updateItems(currentTarget, true)
	const handleMouseLeave: MouseEventHandler<HTMLElement> = ({ currentTarget }) =>
		updateItems(currentTarget, false)

	useEffect(() => {
		const handleResize = () => setElement(null)
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	return {
		handleMouseEnter,
		handleMouseLeave,
		portal: items && createPortal(element, document.body),
	}
}

export default useDropDown
