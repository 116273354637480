import React, { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import messages from "messages/home"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import InternalLink from "components/InternalLink"
import LowFeesImage from "assets/images/HomePage/LowFeesImage.webp"

import styles from "./index.module.scss"

const LowFees: FC = () => {
	const { formatMessage } = useIntl()

	return (
		<div className={styles.lowFees}>
			<div className={styles.lowFees__content}>
				<div className={styles.lowFees__contentWrapper}>
					<h3 className={styles.lowFees__headline}>
						<FormattedMessage {...messages.main_tub_name_4} />
					</h3>

					<p className={styles.lowFees__contentText}>
						<FormattedMessage {...messages.main_tub_content_description_4} />
					</p>
				</div>

				<InternalLink className={styles.lowFees__button} to={routes.tradingFees}>
					{formatMessage(commonMessages.fee)}
				</InternalLink>
			</div>

			<img
				className={styles.lowFees__svg}
				src={LowFeesImage}
				width="280"
				height="280"
				alt="Low fees"
			/>
		</div>
	)
}

export default LowFees
