import React from "react"
import cn from "classnames"
import Footer from "components/Footer"
import Cookies from "components/Cookies"
import FooterLarge from "../components/NewUI/Footer/Large"
import styles from "../styles/pages/Page.module.scss"
import Navigation from "./RedesignedLayout/navigation";
// import MarketBanner from "components/MarketBanner";

interface IProps {
	isExchange?: boolean
	colorPrimary?: boolean
	children?: React.ReactNode
}

const MainLayout: React.FC<IProps> = ({ children, colorPrimary, isExchange }) => (
	<>
		{/* <MarketBanner /> */}

    <Navigation />
		<div className={cn("layout", colorPrimary && "primary")}>{children}</div>

		<div className={styles.footer__wrapper}>
			<FooterLarge />
		</div>
		{/*<Footer />*/}
		<Cookies />
	</>
)

export default MainLayout
