import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl"
import cn from "classnames"

import { Button } from "@btc-alpha/ui-components"
import config from "helpers/config"
import { isAndroid } from "utils/browser"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import InternalLink from "components/InternalLink"
import LogoIcon from "assets/icons/Logo"
import IconApple from "assets/icons/social/apple.svg"
import IconGoogle from "assets/icons/google.svg"
import mobileBg from "assets/images/HomePage/mobile-bg.png"

import styles from "./index.module.scss"

const MobileWelcomePage: React.FC = () => {
	const { formatMessage } = useIntl()

	const storeLink = isAndroid ? config.mobileAppGooglePlay : config.mobileAppAppStore
	const storeIcon = isAndroid ? IconGoogle : IconApple
	// const storeLabel = isAndroid ? "Get in on Google Play" : "Download on the App Store"
	const storeLabel = isAndroid ? "Coming Soon" : "Download on the App Store"

	useEffect(() => {
		const updateVh = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};

		document.body.style.overflow = "hidden";
		document.body.style.position = "fixed";
		document.body.style.width = "100%";
		document.body.style.height = "100%";

		updateVh();
		window.addEventListener("resize", updateVh);

		return () => {
			document.body.style.overflow = "";
			document.body.style.position = "";
			document.body.style.width = "";
			document.body.style.height = "";
			window.removeEventListener("resize", updateVh);
		};
	}, []);

	return createPortal(
		<div className={styles.wrapper}>
			<header>
				<div className={styles.header}>
					<InternalLink ariaLabel="BTC-Alpha" className={styles.logo} to={routes.trade.root}>
						<LogoIcon />
					</InternalLink>
					<hr />
					<div className={styles.space} />
					<Button
						size="mini"
						kind="secondary"
						to={routes.news.root}
						caption={formatMessage(commonMessages.news)}
						className={cn(styles.primary, styles.active)}
					/>
					<Button
						size="mini"
						kind="primary"
						to={routes.trade.root}
						caption={formatMessage(commonMessages.trade)}
						className={cn(styles.primary, styles.active)}
					/>
				</div>

				<div className={styles.title}>
					<span className={styles.title__highlighted}>Trade</span> &
					<span className={styles.title__highlighted}>Explore</span> Web3 with the BTC-Alpha App
				</div>

				<div className={styles.description}>
					The Global Cryptocurrency Repository, Accessible at Your Convenience
				</div>
			</header>

			<div className={styles.container}>
				<img src={mobileBg} width="100%" height="100%" alt="" />
			</div>

			<footer className={styles.footer}>
				<a
					href={storeLink}
					className={cn(styles.mobile_app_download, { [styles.disabled]: isAndroid })}
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={storeIcon} width="20" height="20" alt={storeLabel} />
					{storeLabel}
				</a>
			</footer>
		</div>,
		document.body
	)
}

export default MobileWelcomePage
