import React, { FC, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import messages from "messages/home"
import { observer } from "mobx-react-lite"

import styles from "./index.module.scss"
import DailyVolume from "../../../../assets/icons/homePage/DailyVolume"
import ExpOfWork from "../../../../assets/icons/homePage/ExpOfWork"
import TradingPairs from "../../../../assets/icons/homePage/TradingPairs"
import SecurityRating from "../../../../assets/icons/homePage/SecurityRating"
import { useMst } from "../../../../models/Root"
import { routes } from "../../../../constants/routing"
import InternalLink from "../../../InternalLink"
import { getVolumes } from "../../../../services/HomeService"
import commonMessages from "../../../../messages/common"

const USP: FC = () => {
	const {
		global: { isAuthenticated },
	} = useMst()

	const [volume, setVolume] = useState<number>(0)
	const intl = useIntl()

	useEffect(() => {
		getCurrentVolumes()
	}, [])

	const getCurrentVolumes = async (): Promise<void> => {
		try {
			const data = await getVolumes()
			setVolume(data.trading_volume)
		} catch (e) {
			console.error(e)
		}
	}

	const volumeToString = useMemo(() => {
		if (!volume) {
			return ""
		}
		const suffixes = [
			"",
			intl.formatMessage(messages.k),
			intl.formatMessage(messages.mln),
			intl.formatMessage(messages.bln),
			intl.formatMessage(messages.trn),
		]
		const str = `${volume.toFixed(0)}`
		const number = str.slice(0, ((str.length - 1) % 3) + 1)
		const index = Math.floor(str.length / 3)
		return `${number}${number ? "+" : ""} ${suffixes[index] || suffixes[0]}`
	}, [volume, intl])
	const { formatMessage } = useIntl()

	return (
		<section className={styles.usp}>
			<h2 className="visually-hidden">Driving worldwide crypto adoption since 2016.</h2>

			<div className={styles.usp__container}>
				<div className={`${styles.usp__item} ${styles.usp__item___main}`}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>
								<FormattedMessage {...messages.trust_title} />
						</p>

						<p className={styles.usp__itemContent}>
							<FormattedMessage {...messages.trust_description} />
						</p>
					</div>

					{isAuthenticated ? (
						<InternalLink className={styles.usp__itemButton} to={routes.trade.root}>
							<FormattedMessage {...messages.top_trade} />
						</InternalLink>
					) : (
						<InternalLink className={styles.usp__itemButton} to={routes.register.root}>
							{formatMessage(commonMessages.join_us)}
						</InternalLink>
					)}
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>
							200+ <FormattedMessage {...messages.mln} />
						</p>

						<p className={styles.usp__itemContent}>
							<FormattedMessage {...messages.daily_volume} />
						</p>
					</div>

					<DailyVolume />
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>
							9 <FormattedMessage {...messages.years} />
						</p>

						<p className={styles.usp__itemContent}>
							<FormattedMessage {...messages.years_description} />
						</p>
					</div>

					<ExpOfWork />
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>100+</p>

						<p className={styles.usp__itemContent}>
							<FormattedMessage {...messages.trading_pairs} />
						</p>
					</div>

					<TradingPairs />
				</div>

				<div className={styles.usp__item}>
					<div className={styles.usp__itemContentWrapper}>
						<p className={styles.usp__itemHeadline}>
							14 <FormattedMessage {...messages.positions} />
						</p>

						<p className={styles.usp__itemContent}>
							<FormattedMessage {...messages.positions_description} />
						</p>
					</div>

					<SecurityRating />
				</div>
			</div>
		</section>
	)
}

export default observer(USP)
