/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import {
	Stepper,
	Select,
	TSelectChangeEvent,
	TSelectOption,
	CurrencyIcon,
	Field,
	Input,
	Button,
	CheckBox,
} from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import commonMessages from "messages/common"
import transfersMessages from "messages/transfers"
import { useMst } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import useModal from "hooks/useModal"

import { ReactComponent as IconCircleQuestion } from "assets/icons/circle-question.svg"

import { ERROR_RECEIVER } from "./constants"
import ConfirmTransfer from "./ConfirmTransfer"
import styles from "./transfer.module.scss"

type TProps = {
	currency: string
	updateHistory?: () => void
}

const TransferForm: React.FC<TProps> = ({ currency, updateHistory }) => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { balances, isBalancesLoaded },
	} = useMst()
	const localeNavigate = useLocaleNavigate()

	const [step, setStep] = useState<number>(1)
	const [error, setError] = useState<string>()

	/* Coin */

	const { available = 0 } = balances.find(({ code }) => code === currency) || {}

	const currencyOptions = useMemo(
		() =>
			balances
				.filter(({ available }) => available)
				.sort((a, b) => a.code.localeCompare(b.code))
				.map<TSelectOption>(({ code, name, available }) => ({
					icon: <CurrencyIcon name={code} />,
					label: code,
					message: name,
					amount: formatNumber(+available, { minimumFractionDigits: 2, maximumFractionDigits: 8 }),
					value: code.toUpperCase(),
				})),
		[balances.length],
	)

	const handleCurrencyChange: TSelectChangeEvent = value => {
		if (!value) {
			localeNavigate(routes.profile.createTransfer)
			setStep(0)
			return
		}
		currency !== value && localeNavigate(routes.profile.getTransferCurrency(value))
		setStep(1)
	}

	/* Requisites */

	const [amount, setAmount] = useState<string>("")
	const [receiver, setReceiver] = useState<string>("")

	const maximumAmount = available
	const minimumAmount = 0

	const handleAllClick = () => setAmount(maximumAmount.toString())

	/* Security */

	const [needSecurity, setNeedSecurity] = useState<boolean>(false)
	const toggleNeedSecurity = () => setNeedSecurity(value => !value)

	const [security, setSecurity] = useState<string>("")
	const [days, setDays] = useState<string>("")

	useEffect(() => {
		if (+amount > 0 && !!receiver && (!needSecurity || (needSecurity && !!security && +days > 0)))
			setStep(2)
		else setStep(1)
	}, [amount, receiver, needSecurity, security, days])

	/* Optional */

	const [message, setMessage] = useState<string>("")
	const [note, setNote] = useState<string>("")

	/* Confirm */

	const [openConfirmationModal] = useModal(ConfirmTransfer, {
		title: formatMessage(transfersMessages.confirm_to_transfer),
		width: 500,
		closeBack: false,
		closeEsc: false,
		closeButton: false,
		onClose: setError,
	})

	const handleConfirmClick = () => {
		setError(undefined)
		openConfirmationModal({
			currency,
			amount: +amount,
			receiver,
			security: needSecurity ? security : undefined,
			days: needSecurity ? +days : undefined,
			message,
			note,
			updateHistory,
		})
	}

	/* Render */

	useEffect(() => {
		setAmount("")
		setError(undefined)
	}, [currency])

	const isConfirmEnabled = step >= 2

	return (
		<Stepper step={step} className={styles.form}>
			<Stepper.Item
				title={formatMessage(transfersMessages.select_coin_to_transfer)}
				className={styles.step}
			>
				<Select
					placeholder={formatMessage(transfersMessages.select_coin)}
					placeholderIcon={<IconCircleQuestion />}
					options={currencyOptions}
					search
					value={currency}
					loading={!isBalancesLoaded}
					onChange={handleCurrencyChange}
				/>
			</Stepper.Item>
			<Stepper.Item title={formatMessage(transfersMessages.transfer_to)} className={styles.step}>
				<Field
					fLabel={formatMessage(commonMessages.amount)}
					fSubline={formatMessage(transfersMessages.maximum_amount, {
						value: +maximumAmount,
						currency,
					})}
					fRequired
					fComponent={Input}
					number
					min={minimumAmount}
					max={maximumAmount}
					action={formatMessage(commonMessages.all)}
					actionClick={handleAllClick}
					value={amount}
					onChange={setAmount}
				/>
				<Field
					fLabel={`${formatMessage(commonMessages.receiver)} - User ID`}
					fRequired
					fComponent={Input}
					value={receiver}
					error={error === ERROR_RECEIVER}
					onChange={setReceiver}
				/>
				<CheckBox
					label={formatMessage(transfersMessages.add_security_code)}
					checked={needSecurity}
					onChange={toggleNeedSecurity}
				/>
				{needSecurity && (
					<div className={styles.security}>
						<Field
							fLabel={formatMessage(transfersMessages.security_code)}
							fRequired
							fComponent={Input}
							value={security}
							onChange={setSecurity}
						/>
						<Field
							fLabel={formatMessage(transfersMessages.days_active)}
							fRequired
							fComponent={Input}
							number
							min={1}
							max={366}
							maxLength={3}
							value={days}
							onChange={setDays}
						/>
					</div>
				)}
			</Stepper.Item>
			<Stepper.Item
				title={formatMessage(transfersMessages.optional_details)}
				className={styles.step}
			>
				<Field
					fLabel={formatMessage(commonMessages.description)}
					fOptional
					fComponent={Input}
					clearable
					value={message}
					onChange={setMessage}
				/>
				<Field
					fLabel={formatMessage(commonMessages.note)}
					fOptional
					fComponent={Input}
					placeholder={formatMessage(transfersMessages.type_anything_you_want)}
					clearable
					value={note}
					onChange={setNote}
				/>
			</Stepper.Item>
			<Stepper.Footer>
				<div className={styles.confirmation}>
					<p>{formatMessage(transfersMessages.received_amount_will_equal)}</p>
					<Button
						caption={formatMessage(transfersMessages.confirm_transfer)}
						disabled={!isConfirmEnabled}
						onClick={handleConfirmClick}
					/>
				</div>
			</Stepper.Footer>
		</Stepper>
	)
}

export default observer(TransferForm)
