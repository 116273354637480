/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import dayjs from "dayjs"
import { Billboard, Button, Field, Input, KeyValues } from "@btc-alpha/ui-components"

import { IInternalTransferDetails } from "types/internal_transfers"
import commonMessages from "messages/common"
import transfersMessages from "messages/transfers"
import halvingMessages from "messages/halving"
import InternalTransferService from "services/InternalTransferService"
import { TDefaultModalProps, TModalParams } from "hooks/useModal"
import useAsyncAction from "hooks/useAsyncAction"

import SuccessImg from "assets/images/withdraws/success.svg"

import styles from "./transfers.module.scss"

const getUnitValue = (diff: number): number => {
	const absDiff = Math.abs(diff)
	if (absDiff > 86400) return Math.round(absDiff / 86400)
	if (absDiff < 86400 && absDiff > 3600) return Math.floor(absDiff / 3600)
	if (absDiff < 3600) return absDiff / 60
	return 0
}

const getUnitOfTime = (diff: number): MessageDescriptor => {
	const absDiff = Math.abs(diff)
	if (absDiff > 86400) return halvingMessages.days
	if (absDiff < 86400 && absDiff > 3600) return halvingMessages.hours
	if (absDiff < 3600 && absDiff > 0) return halvingMessages.minutes
	return { id: "unknown", defaultMessage: "––" }
}

type TParams = {
	transfer: IInternalTransferDetails
	updateHistory?: () => void
}

type TProps = TDefaultModalProps & TParams

type TStep = "form" | "success"

const AcceptTransfer: React.FC<TProps> = ({
	transfer,
	updateHistory,
	updateModal,
	closeModal,
	localeNavigate,
}) => {
	const { formatMessage, formatNumber } = useIntl()

	const [step, setStep] = useState<TStep>("form")

	const { txid, amount, currency, sender, valid_till, message } = transfer

	const close = () => {
		localeNavigate({ search: {} })
		closeModal()
	}

	/* Form */

	const [code, setCode] = useState<string>("")
	const [error, setError] = useState<boolean>(false)
	useEffect(() => setError(false), [code])

	const [submit, loading] = useAsyncAction(
		async () => {
			const result = await InternalTransferService.acceptTransferRequest(txid, code)
			updateHistory?.()
			setStep("success")
		},
		() => setError(true),
	)

	useEffect(() => {
		const titles: Record<TStep, Required<TModalParams["title"]>> = {
			form: formatMessage(transfersMessages.transfer_receiving),
			success: (
				<div className={styles.success}>
					<img src={SuccessImg} alt="" />
					<strong>{formatMessage(transfersMessages.received_successfully)}</strong>
					<span>{formatMessage(transfersMessages.transfer_processed_successfully)}</span>
				</div>
			),
		}
		updateModal({ title: titles[step] })
	}, [step])

	const submitHandlers: Record<TStep, () => void> = {
		form: submit,
		success: close,
	}

	const cancelHandlers: Record<TStep, () => void> = {
		form: close,
		success: () => {
			closeModal()
			localeNavigate({ search: {} })
		},
	}

	const submitCaptions: Record<TStep, string> = {
		form: formatMessage(commonMessages.receive),
		success: formatMessage(commonMessages.close),
	}

	const cancelCaptions: Record<TStep, string> = {
		form: formatMessage(commonMessages.cancel),
		success: formatMessage(transfersMessages.transfers_history),
	}

	const now = Date.now()
	const diff = valid_till ? dayjs(valid_till).diff(dayjs(now), "seconds", true) : NaN

	const isSubmitDisabled = !code.length

	return (
		<div className={styles.confirmation}>
			<div className={styles.content}>
				<KeyValues
					items={[
						[
							formatMessage(transfersMessages.quantity),
							`${formatNumber(+amount, {
								minimumFractionDigits: currency.precision || 2,
								maximumFractionDigits: currency.precision || 8,
							})} ${currency.code}`,
						],
						{
							key: formatMessage(commonMessages.sender),
							value: sender,
							separated: !valid_till && !!message,
						},
						{
							key: formatMessage(commonMessages.active_to),
							value: `${getUnitValue(diff)} ${formatMessage(getUnitOfTime(diff))}`,
							isVisible: !!valid_till && step === "form",
							separated: !!message,
						},
						{
							key: formatMessage(commonMessages.description),
							value: message,
							isVisible: !!message,
						},
					]}
				/>
				{step === "form" && (
					<>
						<Billboard
							bordered
							text={formatMessage(transfersMessages.secure_transfer_msg)}
						/>
						<Field
							fComponent={Input}
							fLabel={formatMessage(commonMessages.secure_code)}
							secure
							error={error}
							value={code}
							onChange={setCode}
						/>
					</>
				)}
			</div>
			<div className={styles.footer}>
				<Button
					caption={cancelCaptions[step]}
					disabled={loading}
					kind="outlined"
					onClick={cancelHandlers[step]}
				/>
				<Button
					caption={submitCaptions[step]}
					loading={loading}
					disabled={isSubmitDisabled}
					onClick={submitHandlers[step]}
				/>
			</div>
		</div>
	)
}

export default AcceptTransfer
