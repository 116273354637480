import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cn from "classnames"
import * as yup from "yup"
import {
	Button,
	CheckBox,
	Field,
	Input,
	messageError,
	Switcher,
	useForm,
} from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import { PASSWORD_MAX_SYMBOLS, PASSWORD_MIN_SYMBOLS } from "constants/common"
import { EAccountType, ERegisterStep } from "pages/Register/types"
import commonMessages from "messages/common"
import accountMessages from "messages/account"
import homeMessages from "messages/home"
import formMessages from "messages/form"
import AuthService from "services/AuthService"
import useParamQuery from "hooks/useSearchQuery"
import { useRegisterContext } from "pages/Register/context"

import RedesignedAuthLayout from "layouts/RedesignedAuthLayout"
import InternalLink from "components/InternalLink"
import CloudflareCaptcha, { TCloudflareCaptchaRef } from "components/NewUI/CloudflareCaptcha"

import Header from "../components/Header"
import PasswordRules from "../components/PasswordRules"
import ThirdParty from "../components/ThirdParty"

import styles from "./regForm.module.scss"

enum EFields {
	EMAIL = "email",
	PASSWORD = "password",
	INVITE = "invite",
	CONDITION = "condition",
}

type TForm = {
	[EFields.EMAIL]: string
	[EFields.PASSWORD]: string
	[EFields.INVITE]: string
	[EFields.CONDITION]: boolean
}

const RegForm: React.FC = () => {
	const query = useParamQuery()
	const { formatMessage } = useIntl()
	const { setToken, setStep, setEmail, setResponse } = useRegisterContext()
	// const referralCode = query.get(REFERRAL_CODE_KEY) || cookies.get(REFERRAL_CODE_KEY)

	const cloudflareRef = React.useRef<TCloudflareCaptchaRef>(null)
	const [cloudflareToken, setCloudflareToken] = useState<string>()

	const { submit, change, submitting, errors } = useForm<TForm>({
		validation: {
			[EFields.EMAIL]: yup.string().required(formatMessage(formMessages.required)),
			[EFields.PASSWORD]: yup
				.string()
				.required(formatMessage(formMessages.required))
				.max(PASSWORD_MAX_SYMBOLS),
			[EFields.CONDITION]: yup.boolean().required(),
		},
	})

	const [account_type, setAccountType] = useState<EAccountType>(EAccountType.PERSONAL)

	const handleSubmit = submit(
		async ({ email, password, invite }) => {
			// const response = await AuthService.register({ email, password, account_type })
			const response = await AuthService.register({
				email,
				password,
				invite,
				type: account_type === EAccountType.PERSONAL ? 1 : 2,
			})
			setResponse(response)
			// setToken(response.token)
			setEmail(email)
			setStep(ERegisterStep.PINCODE)
		},
		error => {
			const data = (error as any)?.data as Record<string, string[]>
			if (typeof data === "object" || Object.values(data)[0] instanceof Array)
				messageError(Object.values(data).flat())
			cloudflareRef.current?.resetAction()
		},
	)

	const [isFilled, setFilled] = useState<boolean>(false)
	const [password, setPassword] = useState<string>("")
	const handleChange = change(({ email, password, condition }) => {
		setFilled(!!email && !!password && !!condition)
		setPassword(password)
	})

	const isPassErrorLength = password.length < PASSWORD_MIN_SYMBOLS
	const isPassNumber = !/\d/.test(password)
	const isPassLowercase = !/[a-z]/.test(password)
	const isPassUppercase = !/[A-Z]/.test(password)

	const isPasswordFilled =
		!isPassErrorLength && !isPassNumber && !isPassLowercase && !isPassUppercase

	return (
		<RedesignedAuthLayout
			title={formatMessage(accountMessages.start_trading_on_btc_alpha)}
			description={formatMessage(accountMessages.professional_tools_for_trading)}
		>
			<div className={styles.root}>
				<Header
					headline={formatMessage(commonMessages.create_account)}
					description={formatMessage(commonMessages.have_account)}
					linkCaption={formatMessage(commonMessages.login_noun)}
					linkRoute={routes.login.root}
				/>

				<Switcher
					className={styles.type}
					items={[
						{
							label: formatMessage(commonMessages.personal),
							key: EAccountType.PERSONAL.toString(),
						},
						{
							label: formatMessage(commonMessages.business),
							key: EAccountType.JURIDICAL.toString(),
						},
					]}
					selected={account_type.toString()}
					full
					onClick={value => setAccountType(+value)}
				/>

				<form onSubmit={handleSubmit} onChange={handleChange}>
					<Field
						fComponent={Input}
						fLabel={formatMessage(commonMessages.email)}
						disabled={submitting}
						name={EFields.EMAIL}
						placeholder={formatMessage(homeMessages.email)}
						fError={errors?.email}
						error={!!errors?.email}
					/>

					<Field
						fComponent={Input}
						fLabel={formatMessage(commonMessages.password)}
						disabled={submitting}
						name={EFields.PASSWORD}
						placeholder={formatMessage(commonMessages.enter_password)}
						secure
						fError={errors?.password}
						error={!!errors?.password}
					/>

					<PasswordRules
						isPassErrorLength={isPassErrorLength}
						isPassNumber={isPassNumber}
						isPassLowercase={isPassLowercase}
						isPassUppercase={isPassUppercase}
						className={styles.rules}
					/>

					<Field
						fComponent={Input}
						fLabel={formatMessage(commonMessages.referral_code)}
						disabled={submitting}
						name={EFields.INVITE}
						placeholder={formatMessage(commonMessages.enter_code)}
						fOptional
					/>

					<CheckBox name={EFields.CONDITION} disabled={submitting} className={styles.agreement}>
						{formatMessage(commonMessages.terms_conditions, {
							privacy_policy: (
								<InternalLink to={routes.privacyPolicy}>
									{formatMessage(commonMessages.privacy_policy_context)}
								</InternalLink>
							),
							terms_of_use: (
								<InternalLink to={routes.termsOfUse}>
									{formatMessage(commonMessages.terms_of_use_context)}
								</InternalLink>
							),
						})}
					</CheckBox>

					<div className={cn(styles.captcha, { [styles.closed]: !!cloudflareToken })}>
						<CloudflareCaptcha ref={cloudflareRef} setToken={setCloudflareToken} theme="dark" />
					</div>

					<Button
						type="submit"
						caption={formatMessage(commonMessages.continue)}
						loading={submitting}
						disabled={!isFilled || !isPasswordFilled || !cloudflareToken}
						className={styles.submit}
					/>
				</form>

				<ThirdParty />
			</div>
		</RedesignedAuthLayout>
	)
}

export default observer(RegForm)
