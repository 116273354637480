import React from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { AlphaIcon, Button, useCopyClick } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import { FORMAT_NUMBER_OPTIONS_BTC, FORMAT_NUMBER_OPTIONS_USD } from "constants/format"
import commonMessages from "messages/common"
import { useMst } from "models/Root"
import useAccount from "hooks/useAccount"

import InternalLink from "components/InternalLink"

import IconDeposit from "assets/icons/menu/deposit.svg"
import IconWithdrawal from "assets/icons/menu/withdrawal.svg"

import styles from "./wallet.module.scss"

const Wallet: React.FC = () => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { totalBalance },
		finance: { isBalancesVisible, setIsBalancesVisible },
	} = useMst()
	const { verify } = useAccount()

	const handleVisibilityClick = () => setIsBalancesVisible(!isBalancesVisible)

	return (
		<div className={styles.root}>
			<div className={styles.panel}>
				<div className={styles.header}>
					<span>{formatMessage(commonMessages.assetsOverview)}</span>
					<Button
						size="mini"
						kind="clear"
						icon={isBalancesVisible ? "ai-eye_disabled" : "ai-eye"}
						iconSize={12}
						onClick={handleVisibilityClick}
					/>
				</div>
				<InternalLink to={routes.profile.walletOverview} className={styles.amounts}>
					<div>
						{isBalancesVisible
							? formatNumber(totalBalance?.USDT ?? 0, FORMAT_NUMBER_OPTIONS_USD)
							: STARS_STR}{" "}
						<span>USD</span>
					</div>
					<span>
						≈{" "}
						{isBalancesVisible
							? formatNumber(totalBalance?.BTC ?? 0, FORMAT_NUMBER_OPTIONS_BTC)
							: STARS_STR}{" "}
						BTC
					</span>
					<AlphaIcon
						name="ai-chevron_right"
						size={12}
						color="--alpha-ui-color-label-secondary"
						className={styles.chevron}
					/>
				</InternalLink>
			</div>
			<p>{formatMessage(commonMessages.dataMayBeDelayed)}</p>
			<div className={styles.buttons}>
				<Button
					size="middle"
					kind="primary"
					icon={IconDeposit}
					iconSize={18}
					caption={formatMessage(commonMessages.deposit)}
					to={verify(routes.profile.createDeposit)}
					className={styles.primary}
				/>
				<Button
					size="middle"
					kind="secondary"
					icon={IconWithdrawal}
					iconSize={18}
					caption={formatMessage(commonMessages.withdraw)}
					to={verify(routes.profile.createWithdraw)}
				/>
			</div>
		</div>
	)
}

export default observer(Wallet)
