import ApiClient from "helpers/ApiClient"
import {
  IResetPasswodRequestBody, IResetPasswordBody,
  // TConfirmRequestBody,
  TLoginRequestBody,
  TLoginResendPincodeResponse,
  TLoginResponse
  // TRegisterRequestBody,
  // TRegisterResponse,
  // IRegisterJuridicalInfoBody,
  // IRegisterJuridicalInfoResponse,
  // IRegisterPersonalInfoBody,
  // IRegisterPersonalInfoResponse,
} from "types/auth";

import {
	IRegisterBody,
	IRegisterCompleteResponse,
	IRegisterJuridicalInfoBody,
	IRegisterJuridicalInfoResponse,
	IRegisterPersonalInfoBody,
	IRegisterPersonalInfoResponse,
	IRegisterResponse,
} from "types/register"

const AuthService = {
	login: (body: TLoginRequestBody): Promise<TLoginResponse> =>
		ApiClient.post("web/profile/auth", body),
	loginResendPincode: (wizard_id: string): Promise<TLoginResendPincodeResponse> =>
		ApiClient.post("web/resend-pincode", { wizard_id }),

	register: (body: IRegisterBody): Promise<IRegisterResponse> =>
		ApiClient.post("web/register/email", body),
	confirm: (pincode: string, slug: string): Promise<IRegisterResponse> =>
		ApiClient.post(`web/register/${slug}/pincode`, { pincode }),
	resend: (slug: string): Promise<IRegisterResponse> =>
		ApiClient.post(`web/register/${slug}/resend-pincode`),

	sendPersonalInfo: (
		body: IRegisterPersonalInfoBody,
		slug: string,
	): Promise<IRegisterPersonalInfoResponse> =>
		ApiClient.post(`web/register/${slug}/personal-info`, body),
	sendJuridicalInfo: (
		body: IRegisterJuridicalInfoBody,
		slug: string,
	): Promise<IRegisterJuridicalInfoResponse> =>
		ApiClient.post(`web/register/${slug}/juridical-info`, body),
	complete: (slug: string): Promise<IRegisterCompleteResponse> =>
		ApiClient.post(`web/register/${slug}/complete`),

  resetPasswordRequest: (body: IResetPasswodRequestBody) =>
    ApiClient.post("web/profile/reset-password-code/request", body),
  getResetPasswordToken: () => ApiClient.get("web/profile/reset-password-code/request"),
  resetPasswordConfirm: (slug: string, body: IResetPasswordBody) =>
    ApiClient.post(`web/profile/reset-password-code/${slug}/confirm`, body),

  logout: () => ApiClient.post("web/profile/logout"),

	// @TODO: don't remove. these code will be used for new Auth service:
	// login: (body: TLoginRequestBody) => ApiAuthClient.post<TLoginResponse>("login", body),
	// register: (body: TRegisterRequestBody) => ApiAuthClient.post<TRegisterResponse>("register", body),
	// confirm: (body: TConfirmRequestBody) => ApiAuthClient.post<TLoginResponse>("confirm", body),
	// refresh: () => ApiAuthClient.post<void>("refresh"),
	// logout: () => ApiAuthClient.post<void>("logout"),

	// sendPersonalInfo: (body: IRegisterPersonalInfoBody): Promise<IRegisterPersonalInfoResponse> =>
	// 	ApiClient.post(`web/verification/person/fill-for-auth`, body),
	// sendJuridicalInfo: (body: IRegisterJuridicalInfoBody): Promise<IRegisterJuridicalInfoResponse> =>
	// 	ApiClient.post(`web/verification/juridical/fill-for-auth`, body),
}

export default AuthService
