// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable prefer-template */
import Decimal from "decimal.js"

import { MAX_PRICE_PRECISION } from "./constants"

/* eslint-disable radix */
export const formatNumberToString = (n: number, precision?: number): string => {
	if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}k`
	if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`
	if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`
	if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`
	return formatNumberNoRounding(n, precision)
}

const toFixed = (x: number) => {
	if (Math.abs(x) < 1.0) {
		const e = parseInt(x.toString().split("e-")[1])
		if (e) {
			x *= 10 ** (e - 1)
			x = "0." + new Array(e).join("0") + x.toString().substring(2)
		}
	} else {
		let e = parseInt(x.toString().split("+")[1])
		if (e > 20) {
			e -= 20
			x /= 10 ** e
			x += new Array(e + 1).join("0")
		}
	}
	return x
}

// Function to format a number with a specified precision without rounding
export const formatNumberNoRounding = (value: number, precision = 8): string => {
	// Create a new Decimal object with the given value
	const decimalValue = new Decimal(value)

	// Convert the number to a string and split it into integer and fractional parts
	const parts = decimalValue.toFixed(precision, Decimal.ROUND_DOWN).split(".")

	if (parts.length === 2) {
		// If there is a fractional part, truncate or pad it with zeros to match the precision
		const integerPart = parts[0]
		const fractionalPart = parts[1].slice(0, precision).padEnd(precision, "0")
		return `${integerPart}.${fractionalPart}`
	}

	// If there is no fractional part, add zeros to match the precision
	return precision === 0 ? parts[0] : `${parts[0]}.${"0".repeat(precision)}`
}

const truncateAndFormat = (value: number, precision: number, decimalPlaces: number): string => {
	let multiplier: number

	if (precision > 0) {
		multiplier = 10 ** precision
		value = Math.floor(value * multiplier) / multiplier
	} else if (precision < 0) {
		multiplier = 10 ** Math.abs(precision)
		value = Math.floor(value / multiplier) * multiplier
	}

	const [wholePart, fractionPart = ""] = value.toString().split(".")
	const paddedFraction = fractionPart.padEnd(decimalPlaces, "0")

	return `${wholePart}.${paddedFraction}`
}

export const formatWithPrecision = (number: number, precision = 8, decimalPlaces = 2): string => {
	if (typeof number !== "number") {
		return ""
	}

	return truncateAndFormat(number, precision, decimalPlaces)
}

export const formatField = (
	value: number,
	precision: number,
	formatNumber: (value: number | bigint, opts?: FormatNumberOptions) => string,
): string =>
	formatNumber(value, {
		useGrouping: false,
		minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	}).replace(",", ".")

export const flip = (obj: Record<any, any>) =>
	Object.keys(obj).reduce((ret, key) => {
		ret[obj[key]] = key
		return ret
	}, {})

export const formatRateToPercentage = (rate: number): string =>
	`${formatNumberNoRounding(rate * 100, 4)}%`

// only for coin info
export const formatCryptoCurrencyPrice = (price?: number, currency = "$"): string => {
	if (typeof price !== "number") {
		return "--"
	}

	const stringPrice = price.toString()
	const indexOfPoint = stringPrice.indexOf(".")

	if (price >= 1) {
		return `${currency} ${groupPrice(stringPrice.slice(0, indexOfPoint + 3))}`
	}

	let firstNotZeroIndex = 0

	for (let i = 0; i < stringPrice.length; i++) {
		if (stringPrice[i] !== "0" && stringPrice[i] !== ".") {
			firstNotZeroIndex = i
			break
		}
	}

	return `${currency} ${stringPrice.slice(0, firstNotZeroIndex + 4)}`
}

export const formatPercent = (percent?: number) => {
	const formattedPercent = Math.abs(percent).toFixed(2)
	return percent ? `${formattedPercent} %` : "--"
}

const groupPrice = (v: string, decimal = ",") => v.replace(/\B(?=(\d{3})+(?!\d))/g, decimal)

export const ellipsisInsideWord = (word: string, moreThan = 10) =>
	word && word.length > moreThan ? `${word.substr(0, 5)}.....${word.substr(word.length - 5)}` : word

export const getPrecision = (precision: any) =>
	Number.isNaN(+precision) ? MAX_PRICE_PRECISION : Math.min(+precision, MAX_PRICE_PRECISION)

export const getRoundedNumber = (value, precision = MAX_PRICE_PRECISION) => {
	const roundTo = 10 ** precision
	return Math.floor(value * roundTo) / roundTo
}

export const limitDecimals = (value: string, precision: number = MAX_PRICE_PRECISION): string => {
	const decimals = value.split(",")[1] || value.split(".")[1]

	return decimals && decimals.length >= precision
		? value.slice(0, value.toString().length - (decimals.length - precision))
		: value
}

export const splitWords = (string: string) => string.replace(/(?!^)([A-Z])/g, " $1")
