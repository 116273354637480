import React from "react"
import { useIntl } from "react-intl"
import { Button } from "@btc-alpha/ui-components"

import config from "helpers/config"
import commonMessages from "messages/common"

import IconGoogle from "assets/icons/social/google.svg"
import IconApple from "assets/icons/social/apple.svg"
import IconTelegram from "assets/icons/social/telegram.svg"

import styles from "./thirdParty.module.scss"

const ThirdParty: React.FC = () => {
	const { formatMessage } = useIntl()

	return (
		<div className={styles.root}>
			<div className={styles.title}>{formatMessage(commonMessages.continue_or)}</div>
			<div className={styles.buttons}>
				<Button icon={IconTelegram} caption="Telegram" kind="outlined" disabled />
				<Button
					icon={IconGoogle}
					caption="Google"
					kind="outlined"
					// loading="auto"
					// to={`${config.apiAuthPrefix}/google/auth`}
					disabled
				/>
				<Button icon={IconApple} caption="Apple" kind="outlined" disabled />
			</div>
		</div>
	)
}

export default ThirdParty
