import React, { FC, useRef, useImperativeHandle, forwardRef } from "react"
import { Turnstile, TurnstileInstance, TurnstileTheme } from "@marsidev/react-turnstile"

import config from "helpers/config"

import styles from "./index.module.scss"

export type TCloudflareCaptchaRef = {
  resetAction: () => void
}

type TProps = {
  setToken: (token: string) => void
  size?: "auto" | "normal" | "compact" | "invisible"
  theme: TurnstileTheme
  isError?: boolean
}

const CloudflareCaptcha = forwardRef<TCloudflareCaptchaRef, TProps>(
  ({ size = "normal", setToken, theme = "dark", isError }, ref) => {
    const internalRef = useRef<TurnstileInstance>()
    const onExpire = () => setToken("")

    useImperativeHandle(
      ref,
      () => ({
        resetAction: () => {
          internalRef.current?.reset()
          setToken("")
        },
      }),
      [],
    )

    return (
      <div className={isError ? styles.wobble : undefined}>
        <Turnstile
          ref={internalRef}
          options={{ theme, size, language: "auto" }}
          siteKey={config.cloudflareSiteKey}
          onSuccess={setToken}
          onExpire={onExpire}
        />
      </div>
    )
  },
)

export default CloudflareCaptcha
