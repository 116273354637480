/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Button, ProgressButton, Table, TTableOnFetch, TTableRef } from "@btc-alpha/ui-components"

import { TWithdrawDailyLimit, TWithdraw } from "types/withdrawal"
import { queryVars } from "constants/query"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import historyMessages from "messages/history"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import WithdrawalService from "services/WithdrawalService"

import RedesignedLayout from "layouts/RedesignedLayout"

import { getPageTitle } from "helpers/global"
import AnnotationQuestionIcon from "assets/icons/annotation-question.svg"

import { useColumns } from "pages/FinanceHistory/Withdrawals/hooks"

import DepositQuestions from "../Deposit/DepositQuestions"
import WithdrawalForm from "./WithdrawalForm"
import styles from "./withdrawal.module.scss"

const HISTORY_SIZE = 10

const DEFAULT_LIMIT: TWithdrawDailyLimit = { hard_limit_until: null, is_hard_limited: false }

const Withdrawal: React.FC = () => {
	const { formatMessage } = useIntl()
	const { currency = "" } = useParams<{ [queryVars.currency]: string }>()
	const localeNavigate = useLocaleNavigate()

	const [
		{
			is_hard_limited: isLimited,
			quota: limitQuota = 0,
			used: limitUsed = 0,
			currency: limitCurrency,
		},
		setLimit,
	] = useState<TWithdrawDailyLimit>(DEFAULT_LIMIT)
	useEffect(() => {
		if (!currency) {
			setLimit(DEFAULT_LIMIT)
			return
		}
		WithdrawalService.getWithdrawLimit({ currency })
			.then(setLimit)
			.catch(() => setLimit(DEFAULT_LIMIT))
	}, [currency])

	const tableRef = useRef<TTableRef>(null)

	const columns = useColumns()

	const handleFetch: TTableOnFetch<TWithdraw> = async () => {
		const { results, count } = await WithdrawalService.getPreviousWithdraws({
			"page-size": HISTORY_SIZE,
		})
		return { data: results, count }
	}

	const handleAllClick = () => localeNavigate(routes.financeHistory.withdraws)

	return (
		<RedesignedLayout
			title={formatMessage(commonMessages.withdraw)}
			helmet={{ title: getPageTitle(formatMessage(commonMessages.withdraw)) }}
			className={styles.withdrawal}
		>
			<div className={styles.main}>
				<WithdrawalForm currency={currency} updateHistory={tableRef.current?.refetch} />
				<div className={styles.info}>
					<header>
						<span>{formatMessage(commonMessages.common_questions)}</span>
						<Button
							caption={formatMessage(financeMessages.help_center)}
							icon={AnnotationQuestionIcon}
							size="mini"
							kind="outlined"
							to={routes.support.root}
						/>
					</header>
					<div className={styles.faq}>
						<DepositQuestions />
					</div>
					<ProgressButton
						title={formatMessage(financeMessages.daily_remaining_limit)}
						max={+limitQuota}
						value={+limitUsed}
						unit={limitCurrency}
						disabled={!isLimited}
					/>
				</div>
			</div>
			<div className={styles.history}>
				<h2>{formatMessage(historyMessages.withdraws_history)}</h2>
				<Table
					ref={tableRef}
					columns={columns}
					empty={formatMessage(financeMessages.no_orders_to_show)}
					lazyLoad
					defFetching
					footer="all"
					defLimit={HISTORY_SIZE}
					onFetch={handleFetch}
					onAllClick={handleAllClick}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(Withdrawal)
