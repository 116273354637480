import React from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Switcher } from "@btc-alpha/ui-components"

// import { EBybitWalletType } from "types/account"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import accountMessages from "messages/account"
import financeMessages from "messages/finance"
import useAccount from "hooks/useAccount"

import IconOverview from "assets/icons/menu/overview.svg"
import IconDatabase from "assets/icons/menu/database.svg"
import IconPercent from "assets/icons/menu/percent.svg"
import IconDeposit from "assets/icons/menu/deposit.svg"
import IconWithdrawal from "assets/icons/menu/withdrawal.svg"
import IconTransfer from "assets/icons/menu/transfer.svg"
import IconPieChart from "assets/icons/menu/pie-chart.svg"
import IconClockRewind from "assets/icons/menu/clock-rewind.svg"

import MenuSections from "../components/MenuSections"

const Wallet: React.FC = () => {
	const { formatMessage } = useIntl()
	const navigate = useNavigate()
	const { verify, isSubAccount } = useAccount()

	return (
		<>
			<Switcher
				items={[
					{ key: "wallet", label: formatMessage(financeMessages.wallet) },
					{ key: "account", label: formatMessage(accountMessages.account), disabled: true },
				]}
				selected="wallet"
				full
				onClick={() => navigate("")}
			/>
			<MenuSections
				sections={[
					{
						items: [
							{
								route: routes.profile.walletOverview,
								caption: formatMessage(financeMessages.overview),
								icon: IconOverview,
							},
						],
					},
					{
						title: formatMessage(financeMessages.wallet),
						items: [
							{
								route: routes.profile.wallets,
								// query: { type: EBybitWalletType.FUND },
								caption: formatMessage(financeMessages.spot),
								icon: IconDatabase,
								exact: true,
							},
							// {
							// 	route: routes.profile.wallets,
							// 	query: { type: EBybitWalletType.UNIFIED },
							// 	caption: formatMessage(financeMessages.unified_trading),
							// 	icon: IconPercent,
							// 	exact: true,
							// },
						],
					},
					{
						title: formatMessage(financeMessages.manage_crypto),
						items: [
							{
								route: verify(routes.profile.createDeposit),
								template: routes.profile.createDepositCurrency,
								caption: formatMessage(financeMessages.deposit),
								icon: IconDeposit,
							},
							{
								route: verify(routes.profile.createWithdraw),
								template: routes.profile.createWithdrawCurrency,
								caption: formatMessage(commonMessages.withdraw),
								icon: IconWithdrawal,
							},
							{
								route: verify(routes.profile.createTransfer),
								caption: formatMessage(financeMessages.transfer),
								icon: IconTransfer,
								visible: !isSubAccount,
							},
							{
								route: verify(routes.financeHistory.root),
								caption: formatMessage(commonMessages.history),
								icon: IconClockRewind,
							},
						],
					},
					{
						title: formatMessage(financeMessages.analysis),
						items: [
							{
								// route: "/profile/wallets/test",
								caption: "UTA P&L",
								icon: IconPieChart,
								disabled: true,
							},
						],
					},
				]}
			/>
		</>
	)
}

export default Wallet
