import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import cookies from "js-cookie"

import accountMessages from "messages/account"
import useParamQuery from "hooks/useSearchQuery"
import useAccount from "hooks/useAccount"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import { routes, URL_VARS } from "constants/routing"
import { getRedirectPathname } from "helpers/getRedirectPathname"
import config from "helpers/config"
import { isLocalhost } from "utils/browser"

import RedesignedAuthLayout from "layouts/RedesignedAuthLayout"
import LoadingSpinner from "components/UI/LoadingSpinner"
import HelmetLogin from "components/NewUI/Helmets/Login"
import LoginForm from "components/NewUI/Auth/LoginForm"

const Login: React.FC = () => {
	const { formatMessage } = useIntl()
	const { isAuthenticated } = useAccount()
	const query = useParamQuery()
	const localeNavigate = useLocaleNavigate()

	useEffect(() => {
		const sessionCookieName = cookies.get(config.sessionCookieName)
		const csrfCookieName = cookies.get(config.csrfCookieName)

		if (isAuthenticated && (isLocalhost || csrfCookieName) && sessionCookieName) {
			const redirectQuery = query.get(URL_VARS.REDIRECT)
			const pathname = redirectQuery ? getRedirectPathname(redirectQuery) : ""
			localeNavigate(pathname || routes.profile.wallets)
		}
	}, [isAuthenticated])

	if (isAuthenticated) return <LoadingSpinner />

	return (
		<RedesignedAuthLayout
			title={formatMessage(accountMessages.welcome_to_btc_alpha)}
			description={formatMessage(accountMessages.trusted_partner_in_crypto)}
		>
			<HelmetLogin />
			<LoginForm />
		</RedesignedAuthLayout>
	)
}

export default observer(Login)
