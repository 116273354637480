import React, { ComponentProps, useEffect, useMemo } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { SkeletonTheme } from "react-loading-skeleton"
import { useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { Configurator, getStyleVariable } from "@btc-alpha/ui-components"

import "react-loading-skeleton/dist/skeleton.css"
import "@btc-alpha/ui-components/dist/index.css"

import { ThemeEnum } from "types/theme"
import commonMessages from "messages/common"
import { useMst } from "models/Root"
import config from "helpers/config"
import useSalesDoubler from "hooks/useSalersDoubler"
import useAccount from "hooks/useAccount"
import { BannedProvider } from "hooks/useBanned"

import Router from "./Router"

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})

console.log(
	"%cThis browser feature is for developers. If someone told you to write here any data, they are scammers. If you paste data, you will give scammers access to your account.",
	"font-size: 26px; color: #bada55",
)

const App: React.FC = () => {
	const { global, account, notifications, render, tickers } = useMst()
	const { pathname } = useLocation()
	const { formatMessage } = useIntl()
	const salesDoubler = useSalesDoubler()
	const { isAuthenticated, load: loadAccount } = useAccount()
	const canonicalURL = `${process.env.REACT_APP_HOST}${pathname}`

	useEffect(() => {
    tickers.loadTickers()

		global.loadDepartmentInfo()
		if (config.salesDoubler) salesDoubler.sendRegister()

		const zoomDisable = (event: Event) => {
			event.preventDefault()
			document.body.style.setProperty("zoom", "1")
		}

		document.addEventListener("gesturestart", zoomDisable)
		document.addEventListener("gesturechange", zoomDisable)
		document.addEventListener("gestureend", zoomDisable)

		return () => {
			document.removeEventListener("gesturestart", zoomDisable)
			document.removeEventListener("gesturechange", zoomDisable)
			document.removeEventListener("gestureend", zoomDisable)
		}
	}, [])

	useEffect(() => window.scrollTo(0, 0), [pathname])

	useEffect(() => {
		if (!isAuthenticated) return
		account.loadRates()
		account.loadBalances()
		loadAccount()
		notifications.loadLatestNotifications()
		// if (render.welcomeBonus) promo.loadPromo()
	}, [isAuthenticated])

	useEffect(() => {
		document.body.setAttribute("data-theme", ThemeEnum.Dark)
		document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#1a1c1f")
	}, [])

	const alphaUiConfigs = useMemo<ComponentProps<typeof Configurator>["configs"]>(() => {
		const headerHeight = getStyleVariable("--redesigned-header-height")
		const horizontalPagePadding = getStyleVariable("--horizontal-page-padding")

		return {
			locale: global.locale,
			vars: {
				stickyHeaderHeight: headerHeight,
				pageHorizontalPadding: horizontalPagePadding,
				comboboxZIndex: "999999",
			},
			texts: {
				components: {
					field: { optional: formatMessage(commonMessages.optional) },
					qr: { copy: formatMessage(commonMessages.copy) },
					select: {
						placeholder: formatMessage(commonMessages.select),
						empty: formatMessage(commonMessages.no_options),
					},
					table: {
						empty: formatMessage(commonMessages.no_activities_to_display),
						limit: formatMessage(commonMessages.show_by_value, { value: 10 }),
						first: formatMessage(commonMessages.go_to_first),
						all: formatMessage(commonMessages.view_all),
					},
				},
				hooks: {
					copy: {
						copied: formatMessage(commonMessages.copied),
					},
				},
				utils: {
					messages: {
						success: formatMessage(commonMessages.success),
						info: formatMessage(commonMessages.informational),
						error: formatMessage(commonMessages.error),
					},
				},
			},
		}
	}, [formatMessage])

	return (
		<div className="main">
			<Helmet htmlAttributes={{ lang: global.locale }}>
				<link rel="canonical" href={canonicalURL} />
				<script async src="https://www.googletagmanager.com/gtag/js?id=G-JGZ53YTY6V" />
				<script>
					{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-JGZ53YTY6V', { anonymize_ip: true });
          `}
				</script>
			</Helmet>

			<QueryClientProvider client={queryClient}>
				<SkeletonTheme
					baseColor="var(--skeleton-base-color)"
					highlightColor="var(--skeleton-highlight-color)"
				>
					<Configurator configs={alphaUiConfigs}>
						<BannedProvider>
							<Router />
						</BannedProvider>
					</Configurator>
				</SkeletonTheme>
			</QueryClientProvider>
		</div>
	)
}

export default observer(App)
