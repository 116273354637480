/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import { ETableFilterType, TTableColumns, TTableFilters } from "@btc-alpha/ui-components"

import { EOrderNumberType, EOrderStatus, EOrderNumberSide } from "types/orders"
import { ORDER_STATUS_TEXT } from "constants/orders"
import { useMst } from "models/Root"
import { IHistoryOrderPair, IOrder } from "models/History"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION, PERCENT_PRECISION } from "utils/constants"
import { formatDate } from "utils/formatDate"
import { capitalizeFirstLetter } from "utils/reportUtils"

import Badge, { BadgeColorEnum } from "components/UI/Badge"

const directionColor: Record<EOrderNumberSide, BadgeColorEnum> = {
	[EOrderNumberSide.BUY]: BadgeColorEnum.GREEN,
	[EOrderNumberSide.SELL]: BadgeColorEnum.RED,
}

const orderTypeLabel: Record<number, MessageDescriptor> = {
	[EOrderNumberType.MARKET]: exchangeMessages.order_type_market,
	[EOrderNumberType.LIMIT]: exchangeMessages.order_type_limit,
	[EOrderNumberType.STOP_LIMIT]: exchangeMessages.order_type_stop_limit,
	[EOrderNumberType.STOP_MARKET]: exchangeMessages.order_type_stop_market,
}

const statusColor: Record<string, BadgeColorEnum> = {
	[EOrderStatus.PARTIAL_CANCELED]: BadgeColorEnum.GREY_DARK,
	[EOrderStatus.PARTIAL_CANCELLED]: BadgeColorEnum.GREY_DARK,
	[EOrderStatus.CANCELLED]: BadgeColorEnum.GREY,
}

export const useColumns = (): TTableColumns<IOrder> => {
	const { formatNumber, formatMessage } = useIntl()

	const getFormatedNumber = (value: number | null, decimal: number = MAX_PRICE_PRECISION) =>
		formatNumber(value || 0, {
			minimumFractionDigits: MIN_PRICE_PRECISION,
			maximumFractionDigits: Math.max(Math.log10(decimal) * -1, MIN_PRICE_PRECISION),
		})

	const getCoins = (pair?: IHistoryOrderPair | null) => {
		const [base = "", quote = ""] = pair?.symbol?.split("_") || []
		return { base, quote }
	}

	return [
		{
			key: "date",
			caption: formatMessage(historyMessages.date),
			width: 1,
			render: ({ date }) => formatDate(date * 1000),
		},
		{
			key: "pair",
			caption: formatMessage(historyMessages.active_orders_pair),
			render: ({ pair }) => pair?.label || "",
		},
		{
			key: "type",
			caption: formatMessage(historyMessages.active_orders_type),
			render: ({ type }) =>
				formatMessage(orderTypeLabel[type as never] || { id: "orderType", defaultMessage: "--" }),
		},
		{
			key: "side",
			caption: formatMessage(historyMessages.direction),
			render: ({ side }) => (
				<Badge color={directionColor[side as never]} alpha>
					{formatMessage(
						+side === EOrderNumberSide.BUY
							? historyMessages.orders_table_type_buy
							: historyMessages.orders_table_type_sell,
					)}
				</Badge>
			),
		},
		{
			key: "price",
			caption: formatMessage(historyMessages.active_orders_price),
			render: ({ price, pair }) =>
				!price
					? "–"
					: `${getFormatedNumber(price, pair?.amount_precision)} ${getCoins(pair).quote}`,
		},
		{
			key: "price_avg",
			caption: `${formatMessage(historyMessages.active_orders_price)} AVG`,
			render: ({ price_avg, pair }) =>
				!price_avg
					? "–"
					: `${getFormatedNumber(price_avg, pair?.amount_precision)} ${getCoins(pair).quote}`,
		},
		{
			key: "filled_percent",
			caption: formatMessage(historyMessages.active_orders_filled),
			render: ({ filled_percent }) =>
				`${formatNumber(filled_percent || 0, {
					minimumFractionDigits: PERCENT_PRECISION,
					maximumFractionDigits: PERCENT_PRECISION,
				})}%`,
		},
		{
			key: "amount_original",
			caption: formatMessage(historyMessages.active_orders_amount),
			render: ({ amount_original, pair }) =>
				`${getFormatedNumber(amount_original, pair?.price_precision)} ${getCoins(pair).base}`,
		},
		{
			key: "value_filled",
			caption: formatMessage(historyMessages.active_orders_total),
			render: ({ value_filled, pair }) =>
				`${getFormatedNumber(value_filled, pair?.amount_precision)} ${getCoins(pair).quote}`,
		},
		{
			key: "status",
			caption: formatMessage(historyMessages.state),
			render: ({ status }) => (
				<Badge color={statusColor[status] || BadgeColorEnum.GREEN} alpha>
					{capitalizeFirstLetter(ORDER_STATUS_TEXT[status], true)}
				</Badge>
			),
		},
	]
}

export const useFilters = (): TTableFilters<IOrder> => {
	const { formatMessage } = useIntl()
	const {
		tickers: { loadTickers, list: tickers },
	} = useMst()

	useEffect(() => {
		!tickers?.length && loadTickers()
	}, [tickers])

	return [
		{
			key: "pair",
			type: ETableFilterType.SELECT,
			items: tickers?.map(({ base_currency, quote_currency }) => ({
				label: `${base_currency?.code}/${quote_currency?.code}`,
				value: `${base_currency?.code}_${quote_currency?.code}`,
			})),
			caption: formatMessage(historyMessages.quote_assets),
			clearable: true,
		},
		{
			key: "side",
			type: ETableFilterType.SELECT,
			items: [
				{
					value: EOrderNumberSide.BUY,
					label: formatMessage(historyMessages.orders_table_type_buy),
				},
				{
					value: EOrderNumberSide.SELL,
					label: formatMessage(historyMessages.orders_table_type_sell),
				},
			],
			caption: formatMessage(historyMessages.transfers_direction),
			clearable: true,
		},
		{
			key: "date",
			type: ETableFilterType.DATE,
			caption: formatMessage(historyMessages.orders_table_date),
			clearable: true,
		},
	]
}
