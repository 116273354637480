/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useIntl } from "react-intl"
import { ETableFilterType, TTableColumns, TTableFilters } from "@btc-alpha/ui-components"

import { EOrderNumberSide } from "types/orders"
import { useMst } from "models/Root"
import { ITicker } from "models/Ticker"
import { ITrade } from "types/history"
import historyMessages from "messages/history"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION } from "utils/constants"
import { formatDate } from "utils/formatDate"

import Badge, { BadgeColorEnum } from "components/UI/Badge"

const directionColor: Record<EOrderNumberSide, BadgeColorEnum> = {
	[EOrderNumberSide.BUY]: BadgeColorEnum.GREEN,
	[EOrderNumberSide.SELL]: BadgeColorEnum.RED,
}

export const useColumns = (): TTableColumns<ITrade> => {
	const { formatNumber, formatMessage } = useIntl()
	const {
		tickers: { tickers },
	} = useMst()

	const getFormatedNumber = (
		value: number | null,
		decimal: number | string = MAX_PRICE_PRECISION,
	) =>
		formatNumber(value || 0, {
			minimumFractionDigits: MIN_PRICE_PRECISION,
			maximumFractionDigits: Math.max(Math.log10(+decimal) * -1, MIN_PRICE_PRECISION),
		})

	const pairsRef = useRef(new Map<string, ITicker | undefined>())
	useMemo(() => pairsRef.current?.clear(), [tickers?.length])
	const getPair = (symbol: string): ITicker | undefined => {
		if (pairsRef.current.has(symbol)) return pairsRef.current.get(symbol)
		const pair = tickers.find(item => item.symbol === symbol)
		pairsRef.current.set(symbol, pair)
		return pair
	}

	return [
		{
			key: "date",
			caption: formatMessage(historyMessages.date),
			width: 1,
			render: ({ date }) => (!date ? "–" : formatDate(date * 1000)),
		},
		{
			key: "pair_id",
			caption: formatMessage(historyMessages.active_orders_pair),
			render: ({ pair_id }) => {
				const pair = getPair(pair_id)
				return !pair?.base_currency_code
					? ""
					: `${pair?.base_currency_code}/${pair?.quote_currency_code}`
			},
		},
		{
			key: "side",
			caption: formatMessage(historyMessages.direction),
			render: ({ side }) => (
				<Badge color={directionColor[side as EOrderNumberSide]} alpha>
					{formatMessage(
						side === EOrderNumberSide.BUY
							? historyMessages.orders_table_type_buy
							: historyMessages.orders_table_type_sell,
					)}
				</Badge>
			),
		},
		{
			key: "price",
			caption: formatMessage(historyMessages.active_orders_trading_price),
			render: ({ price, pair_id }) => {
				const pair = getPair(pair_id)
				return `${getFormatedNumber(price, pair?.price_precision)} ${
					pair?.base_currency_code || ""
				}`
			},
		},
		{
			key: "amount1",
			caption: formatMessage(historyMessages.active_orders_amount),
			render: ({ amount1, pair_id }) => {
				const pair = getPair(pair_id)
				return `${getFormatedNumber(amount1, pair?.price_precision)} ${
					pair?.base_currency_code || ""
				}`
			},
		},
		{
			key: "amount2",
			caption: formatMessage(historyMessages.active_orders_total),
			render: ({ amount2, pair_id }) => {
				const pair = getPair(pair_id)
				return `${getFormatedNumber(amount2, pair?.amount_precision)} ${
					pair?.quote_currency_code || ""
				}`
			},
		},
		{
			key: "fee_amount",
			caption: formatMessage(historyMessages.active_orders_transaction_fee),
			width: 140,
			render: ({ fee_amount }) =>
				!fee_amount
					? "–"
					: formatNumber(+fee_amount, { maximumFractionDigits: MAX_PRICE_PRECISION }),
		},
	]
}

export const useFilters = (): TTableFilters<ITrade> => {
	const { formatMessage } = useIntl()
	const {
		tickers: { tickers },
	} = useMst()

	return [
		{
			key: "pair",
			type: ETableFilterType.SELECT,
			items: tickers?.map(({ base_currency_code, quote_currency_code }) => ({
				label: `${base_currency_code}/${quote_currency_code}`,
				value: `${base_currency_code}_${quote_currency_code}`,
			})),
			caption: formatMessage(historyMessages.quote_assets),
			clearable: true,
		},
		{
			key: "date",
			type: ETableFilterType.DATE,
			caption: formatMessage(historyMessages.orders_table_date),
			clearable: true,
		},
	]
}
