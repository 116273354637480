import ApiClient from "helpers/ApiClient"
import {
	IConfirmWithdrawRequestBody,
	ICreateWithdrawBody,
	ICreateWithdrawResponse,
	IGetWithdrawHistoryParams,
	IGetWithdrawHistoryResponse,
	IGetWithdrawMethodsParams,
	TGetWithdrawMethodsResponse,
	TWithdrawDailyLimit,
} from "types/withdrawal"
import { queryVars } from "constants/query"

const WithdrawalService = {
	withdrawMethodsInit: (params: IGetWithdrawMethodsParams): Promise<TGetWithdrawMethodsResponse> =>
		ApiClient.get("web/withdraw/methods", params),
	getPreviousWithdraws: (
		params?: IGetWithdrawHistoryParams,
	): Promise<IGetWithdrawHistoryResponse> => ApiClient.get("web/withdraw/withdraws", params),
	createWithdraw: (data: ICreateWithdrawBody): Promise<ICreateWithdrawResponse> =>
		ApiClient.post("web/withdraw/create/request", data),
	getCurrentBalance: (): Promise<ICreateWithdrawResponse> => ApiClient.get("web/profile/wallets"),
	getWithdrawDetails: (slug: string): Promise<ICreateWithdrawResponse | null> =>
		ApiClient.get(`web/withdraw/create/${slug}/details`),
	resendWithdrawPincode: (slug: string): Promise<ICreateWithdrawResponse> =>
		ApiClient.post(`web/withdraw/create/${slug}/resend`),
	confirmWithdraw: (
		slug: string,
		data?: IConfirmWithdrawRequestBody,
	): Promise<ICreateWithdrawResponse> =>
		ApiClient.post(`web/withdraw/create/${slug}/confirm`, data),
	getWithdrawLimit: (params: { [queryVars.currency]: string }): Promise<TWithdrawDailyLimit> =>
		ApiClient.get(`web/withdraw/withdraw-daily-limit`, params),
	cancelWithdraw: (slug: string): Promise<void> =>
		ApiClient.post(`web/withdraw/create/${slug}/cancel`),
}

export default WithdrawalService
