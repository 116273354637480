import React from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Input } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import commonMessages from "messages/common"

import InternalLink from "components/InternalLink"
import LogoIcon from "assets/icons/Logo"

import { useItems } from "./items"
import RootItem from "./components/RootItem"

import styles from "./navigation.module.scss"

const Navigation: React.FC = () => {
	const { formatMessage } = useIntl()

	const { left, right } = useItems()

	return (
		<div className={styles.root}>
			<InternalLink ariaLabel="BTC-Alpha" className={styles.logo} to={routes.trade.root}>
				<LogoIcon />
			</InternalLink>
			<hr />
			{left.map((root, index) => (
				<React.Fragment key={`navigation-left-item-${index}`}>
					{root.separated && <hr />}
					{root.hidden ? null : <RootItem item={root} />}
				</React.Fragment>
			))}
			{/*<hr />*/}
			{/*<Input*/}
			{/*	mini*/}
      {/*  clearable*/}
			{/*	icon="ai-search"*/}
			{/*	placeholder={formatMessage(commonMessages.search)}*/}
			{/*	className={styles.search}*/}
			{/*/>*/}
			<div className={styles.space} />
			{right.map((root, index) => (
				<React.Fragment key={`navigation-right-item-${index}`}>
					{root.separated && !root.hidden && <hr />}
					{root.hidden ? null : <RootItem item={root} />}
				</React.Fragment>
			))}
		</div>
	)
}

export default observer(Navigation)
