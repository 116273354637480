import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { CurrencyIcon } from "@btc-alpha/ui-components"

import { MAX_PRICE_PRECISION } from "utils/constants"

import ArrowRight from "assets/icons/terminal/ArrowRight"

import styles from "../index.module.scss"

const Row: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	id: number
	namesArr: { headline: string; width: number; asociate: string }[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowRightClick: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowLeftClick: any
	showArrows: boolean
	showLeftArrow: boolean
	showRightArrow: boolean
	isScroll?: boolean
	pairPricePrecision: number
	pairAmountPrecision: number
}> = ({
	item,
	id,
	namesArr,
	handleArrowLeftClick,
	showArrows,
	showLeftArrow,
	showRightArrow,
	handleArrowRightClick,
	isScroll,
	pairPricePrecision,
	pairAmountPrecision,
}) => {
	const { formatNumber } = useIntl()

	return (
		<li className={styles.list__item}>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__itemButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__itemButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__itemButtonRight} ${
							showArrows && showRightArrow ? styles.list__itemButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>
				</>
			) : null}

			{namesArr.map(nameItem => (
				<div
					key={`${id}${nameItem.asociate}-item`}
					className={styles.list__itemElem}
					style={{ minWidth: `${nameItem.width}px` }}
				>
					{nameItem.asociate === "currency" ? (
						<span className={styles.list__currency}>
							<CurrencyIcon
								name={item.currency.symbol}
								size={30}
								className={styles.list__currencyImage}
							/>

							<span className={styles.list__currencyContent}>
								<span className={styles.list__currencyName}>{item.currency.name}</span>
								<span className={styles.list__currencySymbol}>{item.currency.symbol}</span>
							</span>
						</span>
					) : null}

					{nameItem.asociate === "available" ? (
						<span className={styles.list__available}>
							{formatNumber(item.available, {
								useGrouping: true,
								minimumFractionDigits: item.balancePrecision,
								maximumFractionDigits: item.balancePrecision,
							})}{" "}
							<span className={styles.list__availableCurrency}>{item.currency.symbol}</span>
						</span>
					) : null}

					{nameItem.asociate === "total" ? <span>{item.total}</span> : null}

					{nameItem.asociate === "loan" ? <span>{item.loan}</span> : null}

					{nameItem.asociate === "indexPrice" ? (
						<span className={styles.list__available}>
							{item.indexPrice ? (
								<>
									{formatNumber(item.indexPrice, { maximumFractionDigits: MAX_PRICE_PRECISION })}{" "}
									<span className={styles.list__availableCurrency}>USDC</span>
								</>
							) : (
								"--"
							)}
						</span>
					) : null}

					{nameItem.asociate === "indexSum" ? (
						<span className={styles.list__available}>
							{item.indexSum ? (
								<>
									{formatNumber(item.indexSum, {
										useGrouping: true,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}{" "}
									<span className={styles.list__availableCurrency}>USDC</span>
								</>
							) : (
								"--"
							)}
						</span>
					) : null}
				</div>
			))}
		</li>
	)
}

export default observer(Row)
