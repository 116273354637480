import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import {
	AlphaIcon,
	Button,
	CurrencyIcon,
	Input,
	Tabs,
	useLocaleNavigate,
} from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import { QUOTED_CURRENCIES_BASE } from "models/Ticker"

import styles from "./trade.module.scss"

const Trade: React.FC = () => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		tickers: { tickers },
	} = useMst()
	const navigate = useLocaleNavigate()

	const [quoted, setQuoted] = useState<string>(QUOTED_CURRENCIES_BASE[0])
	const [search, setSearch] = useState<string>("")

	useEffect(() => setSearch(""), [quoted])

	const list = tickers.filter(ticker => ticker.quote_currency_code === quoted)

	const searchString = search.toLowerCase().trim()
	const searched = !searchString
		? list
		: list.filter(
				ticker =>
					ticker.base_currency_code.toLowerCase().includes(searchString) ||
					ticker.base_currency.name.toLowerCase().includes(searchString),
		  )

	const pairClickHandler = (symbol: string) => () => {
		navigate(routes.trade.getPair(symbol))
		window.location.reload()
	}

	return (
		<div className={styles.root}>
			<Tabs
				items={QUOTED_CURRENCIES_BASE.map(currency => ({ key: currency, label: currency }))}
				selected={quoted}
				onClick={setQuoted}
			/>
			<Input
				placeholder={formatMessage(commonMessages.search_pair)}
				mini
				icon="ai-search"
				disabled={!list.length}
				className={styles.search}
				clearable
				value={search}
				onChange={setSearch}
			/>
			<div className={styles.currencies}>
				{searched.map(({ symbol, base_currency_code, base_currency, close, price_precision }) => (
					<Button
						key={`trade-${quoted}-items-${base_currency_code}`}
						kind="secondary"
						icon={<CurrencyIcon name={base_currency_code} size={32} />}
						onClick={pairClickHandler(symbol)}
						className={styles.item}
					>
						<div className={styles.name}>
							<span>{base_currency_code}</span>
							<p>{base_currency.name}</p>
						</div>
						<div className={styles.price}>
							<span>
								{formatNumber(close, { maximumFractionDigits: price_precision, useGrouping: true })}
							</span>
						</div>
						<AlphaIcon name="ai-chevron_right" size={12} className={styles.chevron} />
					</Button>
				))}
				{!searched.length && (
					<div className={styles.empty}>{formatMessage(financeMessages.no_pairs)}</div>
				)}
			</div>
		</div>
	)
}

export default observer(Trade)
