import React from "react"
import Lottie, { Options } from "react-lottie"
import animationData from "../assets/lottieAnimations/smTTWgbcsV.json"

const AboutUsBannerBackground = () => {
	const [direction, setDirection] = React.useState(1)

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		renderer: "canvas",
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
			hideOnTransparent: true,
		},
	} as Options

	const handleComplete = () => {
		setDirection(prevDirection => prevDirection * -1)
	}

	return (
		<Lottie
			options={defaultOptions}
			height="100%"
			width="100%"
			style={{ position: "absolute", top: 0, left: 0, pointerEvents: "none" }}
			direction={direction}
			eventListeners={[
				{
					eventName: "complete",
					callback: handleComplete,
				},
			]}
		/>
	)
}

export default AboutUsBannerBackground
