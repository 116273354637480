import React, { createContext, useContext, useState, useMemo, PropsWithChildren } from "react"
import { useLocation } from "react-router-dom"

import config from "helpers/config"
import { COIN_USDT } from "constants/common"

interface TBannedContext {
	banned: boolean
}

const BannedContext = createContext<TBannedContext>({ banned: false })

export const BannedProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { pathname } = useLocation()

	const value = useMemo<TBannedContext>(() => {
		const upperPathname = pathname?.toUpperCase() || ""
		if (!config.banUSDT || !upperPathname) return { banned: false }
		return {
			banned:
				upperPathname.includes(COIN_USDT) &&
				config.banExcludedPairs.every(pair => !upperPathname.includes(pair.toUpperCase())),
		}
	}, [pathname])

	return <BannedContext.Provider value={value}>{children}</BannedContext.Provider>
}

const useBanned = () => {
	const { banned } = useContext(BannedContext)
	return banned
}

export default useBanned
