import React from "react"
import { useIntl } from "react-intl"
import { NavLink } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Button } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import accountMessages from "messages/account"
import financeMessages from "messages/finance"
import useAccount from "hooks/useAccount"

import RedesignedAuthLayout from "layouts/RedesignedAuthLayout"
import Header from "components/NewUI/Auth/components/Header"

import IconBank from "assets/icons/auth/bank.svg"
import IconCard from "assets/icons/auth/card-down.svg"
import IconClock from "assets/icons/auth/clock-stopwatch.svg"

import styles from "./verificationOverview.module.scss"

const VerificationOverview: React.FC = () => {
	const { formatMessage } = useIntl()
	const { logout } = useAccount()

	const handleLogoutClick = async () => logout()

	const items = [
		{
			icon: IconBank,
			text: formatMessage(accountMessages.new_financial_regulations),
			more: "",
		},
		{
			icon: IconCard,
			text: formatMessage(accountMessages.account_withdraw_funds_only),
		},
		{
			icon: IconClock,
			text: formatMessage(accountMessages.process_takes_less_than_5_min),
		},
	]

	return (
		<RedesignedAuthLayout
			title={formatMessage(accountMessages.account_verification)}
			description={formatMessage(accountMessages.enhance_your_security)}
		>
			<div className={styles.root}>
				<Header
					headline={formatMessage(accountMessages.verification)}
					description={formatMessage(accountMessages.confirm_your_details)}
				/>

				<div className={styles.items}>
					{items.map(({ text, icon, more }, index) => (
						<div key={`verification-item-${index.toString()}`}>
							<div style={{ backgroundImage: `url(${icon})` }} />
							<div>
								<p>{text}</p>
								{more && <NavLink to={more}>Read More</NavLink>}
							</div>
						</div>
					))}
				</div>

				<Button
					caption={formatMessage(accountMessages.verify)}
					className={styles.submit}
					to={routes.verification.root}
				/>
				<Button
					caption={formatMessage(accountMessages.log_out)}
					kind="outlined"
					className={styles.submit}
					onClick={handleLogoutClick}
				/>
			</div>
		</RedesignedAuthLayout>
	)
}

export default observer(VerificationOverview)
