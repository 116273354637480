import { createContext, useContext } from "react"

import { IRegisterResponse } from "types/register"

import { EAccountType, ERegisterStep } from "./types"

type TRegisterContext = {
	step?: ERegisterStep
	setStep: (step?: ERegisterStep) => void

	email?: string
	setEmail: (email?: string) => void

	token: string
	setToken: (token?: string) => void

	type: EAccountType
	setType: (type: EAccountType) => void

	response?: IRegisterResponse
	setResponse: (response?: IRegisterResponse) => void
}

export const RegisterContext = createContext<TRegisterContext>({} as never)

export const useRegisterContext = () => useContext(RegisterContext)
