import ApiClient from "helpers/ApiClient"
import { IPaginationParams, IPaginationRes } from "types/general"
import {
	IConfirmTransferRequestBody,
	ICreateTransferRequestBody,
	ICreateTransferRes,
	IInternalTransferDetails,
} from "types/internal_transfers"

const InternalTransferService = {
	createTransferRequest: (body: ICreateTransferRequestBody): Promise<ICreateTransferRes> =>
		ApiClient.post("web/transfer/create/request", body),
	confirmTransfer: (slug: string, body: IConfirmTransferRequestBody): Promise<ICreateTransferRes> =>
		ApiClient.post(`web/transfer/create/${slug}/confirm`, body),
	resendTransferPincode: (slug: string): Promise<ICreateTransferRes> =>
		ApiClient.post(`web/transfer/create/${slug}/resend`),
	cancelCreateTransferRequest: (slug: string) =>
		ApiClient.post(`web/transfer/create/${slug}/cancel`),

	loadTransfersHistory: (
		params: IPaginationParams | any,
	): Promise<IPaginationRes<IInternalTransferDetails>> =>
		ApiClient.get("web/transfer/transfers", params),

	getTransferDetails: (txid: string): Promise<IInternalTransferDetails> =>
		ApiClient.get(`web/transfer/${txid}/detail`),
	cancelTransferRequest: (txid: string) => ApiClient.post(`web/transfer/${txid}/cancel`),
	acceptTransferRequest: (
		txid: string,
		security_code: string,
	): Promise<{ transfer: IInternalTransferDetails }> =>
		ApiClient.post(`web/transfer/${txid}/accept`, { security_code }),
}

export default InternalTransferService
