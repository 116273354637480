import React, { MouseEventHandler, useMemo } from "react"
import { useParams, NavLink, useMatch, useSearchParams } from "react-router-dom"
import cn from "classnames"

import styles from "./menuButton.module.scss"

type TProps = {
	caption: string
	icon?: string
	disabled?: boolean
	route?: string
	query?: Record<string, string>
	exact?: boolean
	template?: string
	onClick?: () => void
	className?: string
}

const MenuButton: React.FC<TProps> = ({
	caption,
	icon,
	disabled,
	route,
	query,
	exact,
	template,
	onClick,
	className,
}) => {
	const [params] = useSearchParams()
	const { locale } = useParams<{ locale: string }>()

	const match = useMatch({ path: `/${locale}/${template}`.replace(/\/{2,}/g, "/"), end: exact })
	const matched = !!template && !!match

	const to = useMemo(() => {
		let search = new URLSearchParams(query).toString()
		if (search.length) search = `?${search}`
		return `/${locale}/${route}`.replace(/\/{2,}/g, "/") + search
	}, [route, query, locale])

	const isMatchedQuery = useMemo(() => {
		if (params.size !== Object.keys(query || {}).length) return false
		return Object.entries(query || {}).every(([key, value]) => params.get(key) === value)
	}, [params, query])

	const children = (
		<>
			{icon && <img src={icon} alt={caption} />}
			<span>{caption}</span>
		</>
	)

	if (onClick || !route) {
		return (
			<button
				type="button"
				disabled={disabled}
				className={cn(styles.menuButton, className)}
				onClick={onClick}
			>
				{children}
			</button>
		)
	}

	const handleClick: MouseEventHandler<HTMLAnchorElement> = event => event.preventDefault()

	return (
		<NavLink
			to={to}
			end={exact}
			onClick={disabled ? handleClick : undefined}
			className={({ isActive }) => {
				let isFullActive = isActive || matched
				if (isFullActive && exact) isFullActive = isMatchedQuery
				return cn(
					styles.menuButton,
					{
						[styles.active]: isFullActive,
						[styles.disabled]: disabled,
					},
					className,
				)
			}}
		>
			{children}
		</NavLink>
	)
}

export default MenuButton
