import React from "react"
import { rems } from "@btc-alpha/ui-components"

type TProps = {
	size?: number
	color?: string
	className?: string
}

const Loader: React.FC<TProps> = ({ size = 24, color = "#fff", className }) => {
	const preparedColor = color?.startsWith("--") ? `var(${color})` : color
	return (
		<svg
			width={rems(size)}
			height={rems(size)}
			viewBox="-5 -5 48 48"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="audio-loading"
			className={className}
		>
			<defs>
				<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
					<stop stopColor={preparedColor} stopOpacity="0" offset="0%" />
					<stop stopColor={preparedColor} stopOpacity=".631" offset="63.146%" />
					<stop stopColor={preparedColor} offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(1 1)">
					<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke={preparedColor} strokeWidth="4">
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="1.2s"
							repeatCount="indefinite"
						/>
					</path>
					<circle fill="none" cx="36" cy="18" r="1">
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</circle>
				</g>
			</g>
		</svg>
	)
}

export default Loader
