import React from "react"
import { useIntl } from "react-intl"
import { Switcher } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import commonMessages from "messages/common"
import historyMessages from "messages/history"

import IconFile from "assets/icons/menu/file.svg"
import IconFileCheck from "assets/icons/menu/file-check.svg"
import IconCandleCheck from "assets/icons/menu/candle-check.svg"

import MenuSections from "../components/MenuSections"

const Orders: React.FC = () => {
	const { formatMessage } = useIntl()

	return (
		<>
			<Switcher
				items={[{ key: "orders", label: formatMessage(commonMessages.orders) }]}
				selected="orders"
				full
			/>
			<MenuSections
				sections={[
					{
						items: [
							{
								route: routes.history.activeOrders,
								caption: formatMessage(commonMessages.current_orders),
								icon: IconFile,
							},
							{
								route: routes.history.closedOrders,
								caption: formatMessage(historyMessages.order_history),
								icon: IconFileCheck,
							},
							{
								route: routes.history.tradesHistory,
								caption: formatMessage(historyMessages.trading_history),
								icon: IconCandleCheck,
							},
						],
					},
				]}
			/>
		</>
	)
}

export default Orders
