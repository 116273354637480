import React from "react"

import Cookies from "components/Cookies"
import Navigation from "./RedesignedLayout/navigation";

const AuthLayout: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => (
	<>
    <Navigation />

		<div className="layout--auth">{children}</div>

		<Cookies />
	</>
)

export default AuthLayout
