export enum ERegisterStep {
	PINCODE = "pincode",
	PERSONAL_INFO = "personal_info",
	JURIDICAL_INFO = "juridical_info",
	FINISH = "finish",
}

export enum EAccountType {
	PERSONAL = 1,
	JURIDICAL = 2,
}
