import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";

// import OrderTypeArrow from "assets/icons/terminal/OrderTypeArrow";
import { EOrderType } from "types/orders";
import styles from "components/NewUI/Terminal/FunctionalPanel/OrderType/index.module.scss";

const OrderType: React.FC<{
	mode: EOrderType;
	text: string;
	setTradeMode: (mode: EOrderType) => void;
	tradeMode: string;
}> = ({ mode, text, tradeMode, setTradeMode }) => {
	// We use useCallback for performance optimization.
	const onClick = useCallback(() => setTradeMode(mode), [mode]);

	return (
		<li className={styles.orderType__item}>
			<button
				className={`${styles.orderType__button} ${
					tradeMode === mode ? styles.orderType__button___checked : ""
				}`}
				type="button"
				onClick={onClick}
			>
				<span>{text}</span>

				{/* <OrderTypeArrow /> */}
			</button>
		</li>
	);
};

export default observer(OrderType);
