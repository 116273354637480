import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { messageError, messageSuccess } from "@btc-alpha/ui-components"

import { useMst } from "models/Root"
import useBodyOverflowHidden from "hooks/newUI/useOverflowHidden"
import exchangeMessages from "messages/exchange"
import ExchangeService from "services/ExchangeService"
import { isOrderDone } from "helpers/history"
import Cross from "assets/icons/terminal/Cross"
import { formatErrorFromServer } from "helpers/exchange"
import { formatNumberNoRounding } from "utils/format"
import styles from "./index.module.scss"

const StopOrderModal: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		terminal: { tradeForm, pair },
	} = useMst()

	const { pricePrecision } = useMemo(() => {
		if (pair) {
			return {
				pricePrecision: pair.price_precision ?? 6,
			}
		}

		return {
			pricePrecision: 6,
		}
	}, [pair])

	useBodyOverflowHidden(tradeForm.isOpenStopOrder)

	const closeModal = () => tradeForm.resetForm()

	const handleSubmit = useCallback(async () => {
		if (tradeForm.order === null) {
			return
		}

		try {
			// @ts-ignore
			const res = await ExchangeService.createOrder(tradeForm.order)

			messageSuccess(
				formatMessage(
					isOrderDone(res) ? exchangeMessages.order_created_done : exchangeMessages.order_created,
				),
			)

			closeModal()
		} catch (err: any) {
			const catchErrors = formatErrorFromServer(err.data)

			if (
				catchErrors.serverErrors?.non_field_errors &&
				Array.isArray(catchErrors.serverErrors?.non_field_errors)
			) {
				catchErrors.serverErrors?.non_field_errors.forEach(element => messageError(element))
			}
		}
	}, [tradeForm.order, formatMessage, closeModal])

	return (
		<div
			className={`${styles.stopOrderModal} ${
				tradeForm.isOpenStopOrder ? styles.stopOrderModal___open : ""
			}`}
		>
			<button
				type="button"
				className={styles.stopOrderModal__closeButton}
				aria-label="close modal"
				onClick={closeModal}
			/>

			{tradeForm.order !== null ? (
				<div className={styles.stopOrderModal__content}>
					<div className={styles.stopOrderModal__header}>
						<p
							className={`${styles.stopOrderModal__headerText} ${
								tradeForm.order.side === "2" ? styles.stopOrderModal__headerText___buy : ""
							}`}
						>
							Stop-Order {tradeForm.order.side === "2" ? "Buy" : "Sell"}{" "}
							{tradeForm.order.pair?.split("_")[0]}
						</p>

						<button
							type="button"
							className={styles.stopOrderModal__headerCloseButton}
							aria-label="close modal"
							onClick={closeModal}
						>
							<Cross />
						</button>
					</div>

					<div className={styles.stopOrderModal__list}>
						<div className={styles.stopOrderModal__row}>
							<p className={styles.stopOrderModal__text}>Trigger price</p>

							<p className={styles.stopOrderModal__text}>
								{tradeForm.order.stop_price} <span>{tradeForm.order.pair?.split("_")[1]}</span>
							</p>
						</div>

						<div className={styles.stopOrderModal__row}>
							<p className={styles.stopOrderModal__text}>Price</p>

							<p className={styles.stopOrderModal__text}>
								{tradeForm.order.price} <span>{tradeForm.order.pair?.split("_")[1]}</span>
							</p>
						</div>

						<div className={styles.stopOrderModal__row}>
							<p className={styles.stopOrderModal__text}>Q-ty</p>

							<p className={styles.stopOrderModal__text}>
								{tradeForm.order.amount} <span>{tradeForm.order.pair?.split("_")[0]}</span>
							</p>
						</div>

						<div className={styles.stopOrderModal__row}>
							<p className={styles.stopOrderModal__text}>Order price</p>

							<p className={styles.stopOrderModal__text}>
								{formatNumberNoRounding(
									Number(tradeForm.order.price) * Number(tradeForm.order.amount),
									pricePrecision,
								)}{" "}
								<span>{tradeForm.order.pair?.split("_")[1]}</span>
							</p>
						</div>
					</div>

					<div className={styles.stopOrderModal__buttons}>
						<button className={styles.stopOrderModal__cancel} type="button" onClick={closeModal}>
							Cancel
						</button>

						<button
							className={`${styles.stopOrderModal__action} ${
								tradeForm.order.side === "2" ? styles.stopOrderModal__action___buy : ""
							}`}
							type="button"
							onClick={handleSubmit}
						>
							{tradeForm.order.side === "2" ? "Buy" : "Sell"} {tradeForm.order.pair?.split("_")[0]}
						</button>
					</div>
				</div>
			) : null}
		</div>
	)
}

export default observer(StopOrderModal)
