import React, { useId } from "react"
import { useIntl } from "react-intl"
import cn from "classnames"

import commonMessages from "messages/common"

import Check from "assets/icons/auth/Check"
import Cross from "assets/icons/auth/Cross"

import styles from "./passwordRules.module.scss"

type TProps = {
	isPassErrorLength: boolean
	isPassSpecialChar?: boolean
	isPassNumber?: boolean
	isPassLowercase: boolean
	isPassUppercase: boolean
	className?: string
}

type TRulesItem = {
	label: string
	error?: boolean
	visible?: boolean
}

const PasswordRules: React.FC<TProps> = ({
	isPassErrorLength,
	isPassSpecialChar,
	isPassLowercase,
	isPassUppercase,
	isPassNumber,
	className,
}) => {
	const id = useId()
	const { formatMessage } = useIntl()

	const items: TRulesItem[] = [
		{
			label: `8 ${formatMessage(commonMessages.characters)}`,
			error: isPassErrorLength,
		},
		{
			label: formatMessage(commonMessages.uppercase_letter),
			error: isPassUppercase,
		},
		{
			label: formatMessage(commonMessages.special_symbol),
			error: isPassSpecialChar,
			visible: isPassSpecialChar !== undefined,
		},
		{
			label: formatMessage(commonMessages.one_digit),
			error: isPassNumber,
			visible: isPassNumber !== undefined,
		},
		{
			label: formatMessage(commonMessages.lowercase_letter),
			error: isPassLowercase,
		},
	]

	return (
		<div className={cn(styles.root, className)}>
			{items.map(({ label, error, visible }, index) => {
				if (visible === false) return null
				return (
					<div key={`Ppassword-rules-${id}-item-${index}`} className={styles.item}>
						{error ? <Cross /> : <Check />}
						<span>{label}</span>
					</div>
				)
			})}
		</div>
	)
}

export default PasswordRules
