/* eslint-disable no-void,no-unused-expressions */
import React, {
	CSSProperties,
	isValidElement,
	TransitionEventHandler,
	useEffect,
	useId,
	useRef,
	useState,
} from "react"
import cn from "classnames"

import { TItem, TRootItem } from "../types"

import { TItemsActivateHandler } from "./useDropDown"
import Item from "./Item"

import styles from "../navigation.module.scss"

type TProps = {
	left: number
	top: number
	triggered: boolean
	items: Required<TRootItem>["items"]
	onHide?: () => void
	onActivate?: TItemsActivateHandler
}

const Items: React.FC<TProps> = ({ left, top, triggered, items, onHide, onActivate }) => {
	const id = useId()
	const rootRef = useRef<HTMLDivElement>(null)
	const subItemsRef = useRef(new Set<number>())

	const [showed, setShowed] = useState<boolean>(false)

	const getActive = () => {
		const hovered = !!rootRef.current?.matches(":hover")
		const isSubItems = !!subItemsRef.current.size
		return triggered || hovered || isSubItems
	}

	const doOnActivate = () => {
		const active = getActive()
		setShowed(active)
		onActivate?.(active)
	}

	useEffect(() => void setTimeout(doOnActivate, 0), [triggered])

	const handleTransitionEnd: TransitionEventHandler<HTMLDivElement> = ({ target }) =>
		(target as HTMLElement).parentNode === rootRef.current && !getActive() && onHide?.()

	const subItemsActivateHandler = (index: number) => (value: boolean) => {
		value ? subItemsRef.current.add(index) : subItemsRef.current.delete(index)
		doOnActivate()
	}

	const width = rootRef.current?.offsetWidth || 0

	return (
		<div
			ref={rootRef}
			style={
				{
					"--items-left": `${left}px`,
					"--items-top": `${top}px`,
					"--items-width": `${width}px`,
				} as CSSProperties
			}
			className={cn(styles.items, { [styles.showed]: showed })}
			onTransitionEnd={handleTransitionEnd}
			onMouseEnter={doOnActivate}
			onMouseLeave={doOnActivate}
		>
			<div>
				{items.map((item, index) => {
					if (!item) return null
					const key = `navigation-${id}-items-${index}`
					if (isValidElement(item)) return <React.Fragment key={key}>{item}</React.Fragment>
					item = item as TItem
					return (
						<React.Fragment key={key}>
							{item.separated && <hr />}
							{!!item.group && <div className={styles.group}>{item.group}</div>}
							{item.hidden ? null : (
								<Item item={item} onItemsActivate={subItemsActivateHandler(index)} />
							)}
						</React.Fragment>
					)
				})}
			</div>
		</div>
	)
}

export default Items
