/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import {
	Stepper,
	Select,
	TSelectChangeEvent,
	TSelectOption,
	CurrencyIcon,
	Field,
	Input,
	Button,
} from "@btc-alpha/ui-components"

import { TWithdrawMethod } from "types/withdrawal"
import { routes } from "constants/routing"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION } from "utils/constants"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import historyMessages from "messages/history"
import { useMst } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import useModal from "hooks/useModal"
import WithdrawalService from "services/WithdrawalService"
import errorHandler from "utils/errorHandler"

import { ReactComponent as IconCircleQuestion } from "assets/icons/circle-question.svg"

import { ERROR_ADDRESS, ERROR_AMOUNT } from "./constants"
import ConfirmWithdrawal from "./ConfirmWithdrawal"

import styles from "./withdrawal.module.scss"

const MIN_ADDRESS_LENGTH = 1

type TProps = {
	currency: string
	updateHistory?: () => void
}

const WithdrawalForm: React.FC<TProps> = ({ currency, updateHistory }) => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { profileStatus, balances, isBalancesLoaded },
	} = useMst()
	const localeNavigate = useLocaleNavigate()

	const [step, setStep] = useState<number>(1)
	const [error, setError] = useState<string>()

	/* Coin */

	const {
		code = "",
		available = 0,
		precision = 0,
	} = balances.find(({ code }) => code === currency) || {}

	const currencyOptions = useMemo(
		() =>
			balances
				.filter(({ available }) => available)
				.sort((a, b) => a.code.localeCompare(b.code))
				.map<TSelectOption>(({ code, name, available, precision, is_withdraw_enabled }) => ({
					icon: <CurrencyIcon name={code} />,
					label: code,
					description: name,
					amount: formatNumber(+available, {
						useGrouping: true,
						minimumFractionDigits: Math.min(MIN_PRICE_PRECISION, precision || MIN_PRICE_PRECISION),
						maximumFractionDigits: precision || MAX_PRICE_PRECISION,
					}),
					disabled: !is_withdraw_enabled,
					value: code.toUpperCase(),
				})),
		[balances.length],
	)

	const handleCurrencyChange: TSelectChangeEvent = value => {
		if (!value) {
			setStep(0)
			return
		}
		currency !== value && localeNavigate(routes.profile.getWithdrawCurrency(value))
		setStep(1)
	}

	/* Chain */

	const [methods, setMethods] = useState<TWithdrawMethod[]>([])
	const [methodId, setMethodId] = useState<number>(0)

	const loadMethods = async () => {
		if (!currency) return
		try {
			const result = await WithdrawalService.withdrawMethodsInit({ currency })
			setMethods(result)
		} catch (error) {
			errorHandler(error, false)
		}
	}
	useEffect(() => {
		if (step < 1) {
			setMethods([])
			setMethodId(0)
		} else if (currency && step === 1) {
			setMethods([])
			setMethodId(0)
			loadMethods()
		}
	}, [currency])

	const methodOptions = useMemo(
		() =>
			methods.map<TSelectOption<number>>(
				({ id, name, withdraw_fee_amount, is_withdraw_enabled }) => ({
					label: name,
					amount: `${formatMessage(financeMessages.withdraw_confirming_fee_amount)} ${formatNumber(
						+withdraw_fee_amount,
						{
							minimumFractionDigits: 2,
							maximumFractionDigits: 8,
						},
					)}`,
					value: id,
					disabled: !is_withdraw_enabled,
				}),
			),
		[methods],
	)

	/* Address */

	const [address, setAddress] = useState<string>("")
	const [tag, setTag] = useState<string>("")
	const [note, setNote] = useState<string>("")

	useEffect(() => {
		setNote("")
	}, [currency])

	useEffect(() => {
		setError("")
		setAddress("")
		setTag("")
	}, [methodId])

	useEffect(() => {
		if (address.length < MIN_ADDRESS_LENGTH && step > 1) setStep(1)
		else if (address.length >= MIN_ADDRESS_LENGTH && step === 1) setStep(2)
		setAmount("")
	}, [step, address])

	/* Amount */

	const method = methods.find(({ id }) => id === methodId)
	const {
		withdraw_fee_rate = 0,
		withdraw_fee_amount = 0,
		min_withdraw = 0,
		min_verification_level,
	} = method || {}

	const [amount, setAmount] = useState<string>("")

	const maximumAmount = available - +withdraw_fee_amount - available * +withdraw_fee_rate
	const minimumAmount = +min_withdraw

	const receivedAmount = Math.max(Math.min(+amount, maximumAmount), minimumAmount)
	const calculatedFee = +withdraw_fee_amount + receivedAmount * +withdraw_fee_rate

	const handleAllClick = () => setAmount(maximumAmount.toString())

	const resetForm = () => {
		setMethodId(0)
		setAddress("")
		setTag("")
		setNote("")
		setAmount("")
		setStep(1)
		setError(undefined)
	}

	/* Confirm */

	const [openConfirmationModal, , , getConfirmationModalOpened] = useModal(ConfirmWithdrawal, {
		title: formatMessage(financeMessages.confirm_to_withdraw),
		width: 500,
		closeBack: false,
		closeEsc: false,
		closeButton: false,
		onClose: setError,
	})

	const handleConfirmClick = () => {
		setError(undefined)
		openConfirmationModal({
			currency,
			address,
			method,
			received: receivedAmount,
			fee: calculatedFee,
			tag,
			note,
			updateHistory,
			onSuccess: resetForm,
		})
	}

	/* Resume */

	const [searchParams, setSearchParams] = useSearchParams()
	const slug = searchParams.get("slug")
	useEffect(() => {
		if (!slug || !currency || getConfirmationModalOpened()) return
		WithdrawalService.getWithdrawDetails(slug)
			.then(withdrawal => {
				if (!withdrawal) {
					setSearchParams({})
					return
				}
				openConfirmationModal({
					currency,
					address: withdrawal.attributes.address,
					received: +withdrawal.amount,
					fee: +withdrawal.fee_amount,
					withdrawal,
					updateHistory,
					onSuccess: resetForm,
				})
			})
			.catch(() => setSearchParams({}))
	}, [slug])

	/* Render */

	//TODO: render verification level if false
	const isEnoughVerificationLevel =
		(profileStatus?.verification_level ?? 0) >= (min_verification_level ?? 0)

	const isConfirmEnabled =
		isEnoughVerificationLevel &&
		step >= 2 &&
		!!amount &&
		+min_withdraw <= +amount &&
		+amount <= available

	return (
		<Stepper step={step} className={styles.form}>
			<Stepper.Item
				title={formatMessage(financeMessages.select_coin_to_withdraw)}
				className={styles.step}
			>
				<Select
					placeholder={formatMessage(financeMessages.select_coin)}
					placeholderIcon={<IconCircleQuestion />}
					options={currencyOptions}
					search
					value={currency}
					loading={!isBalancesLoaded}
					onChange={handleCurrencyChange}
				/>
			</Stepper.Item>
			<Stepper.Item title={formatMessage(financeMessages.withdraw_to)} className={styles.step}>
				<Field
					fLabel={formatMessage(financeMessages.chain_type)}
					fComponent={Select}
					loading={!methods.length}
					options={methodOptions}
					value={methodId}
					onChange={setMethodId}
				/>
				<Field
					fLabel={formatMessage(financeMessages.wallet_address)}
					fRequired
					fComponent={Input}
					placeholder={formatMessage(financeMessages.type_address)}
					value={address}
					disabled={!methodId}
					error={error === ERROR_ADDRESS}
					onChange={setAddress}
				/>
				<Field
					fLabel={formatMessage(financeMessages.tag)}
					fOptional
					fComponent={Input}
					placeholder={formatMessage(financeMessages.type_tag)}
					disabled={!methodId}
					clearable
					value={tag}
					onChange={setTag}
				/>
				<Field
					fLabel={formatMessage(financeMessages.withdraw_note)}
					fOptional
					fComponent={Input}
					placeholder={formatMessage(financeMessages.type_anything_you_want)}
					disabled={!methodId}
					clearable
					value={note}
					onChange={setNote}
				/>
			</Stepper.Item>
			<Stepper.Item
				title={formatMessage(financeMessages.withdrawable_amount)}
				subTitle={
					available
						? `${formatNumber(available, {
								maximumFractionDigits: precision || MAX_PRICE_PRECISION,
								useGrouping: true,
						  })} ${currency}`
						: "0"
				}
			>
				<Field
					fSubline={formatMessage(financeMessages.minimum_withdraw, { value: +min_withdraw })}
					fComponent={Input}
					number
					min={minimumAmount}
					max={maximumAmount}
					action={formatMessage(commonMessages.all)}
					actionClick={handleAllClick}
					error={error === ERROR_AMOUNT}
					value={amount}
					onChange={setAmount}
				/>
			</Stepper.Item>
			<Stepper.Footer>
				<div className={styles.amounts}>
					<div>
						<span>{formatMessage(financeMessages.full_amount)}</span>
						<span>
							{receivedAmount + calculatedFee} {code}
						</span>
					</div>
					<div>
						<span>{formatMessage(financeMessages.amount_received)}</span>
						<span>
							{receivedAmount} {code}
						</span>
					</div>
					<div>
						<span>{formatMessage(historyMessages.active_orders_transaction_fee)}</span>
						<span>
							{calculatedFee} {code}
						</span>
					</div>
				</div>
				<div className={styles.confirmation}>
					<p>{formatMessage(financeMessages.withdrawal_received_amount_will_equal)}</p>
					<Button
						caption={formatMessage(financeMessages.confirm_withdraw)}
						disabled={!isConfirmEnabled}
						onClick={handleConfirmClick}
					/>
				</div>
			</Stepper.Footer>
		</Stepper>
	)
}

export default observer(WithdrawalForm)
