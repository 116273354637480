/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { Button, useCopyClick } from "@btc-alpha/ui-components"

import { routes } from "constants/routing"
import commonMessages from "messages/common"
import useAccount from "hooks/useAccount"
import { useTradingFees } from "services/TradingFeesService"

import InternalLink from "components/InternalLink"

import IconCopy from "assets/icons/copy.svg"
import IconChevron from "assets/icons/chevron-right.svg"

import styles from "./account.module.scss"

const Account: React.FC = () => {
	const { formatMessage } = useIntl()
	const { character, name, email, uid } = useAccount()
	const { data: tradingFees } = useTradingFees()
	const copy = useCopyClick()

	const handleCopyUidClick = () => uid && copy(uid)

	return (
		<div className={styles.root}>
			<InternalLink to={routes.profile.dashboard} className={styles.name}>
				<div style={{ backgroundImage: `url('${character}')` }} />
				<span>{name || email}</span>
				<img src={IconChevron} className={styles.chevron} />
			</InternalLink>
			<div className={styles.info}>
				<div>
					<span>{formatMessage(commonMessages.user_uid)}</span>
					<div>
						<span>{uid}</span>
						<Button
							size="mini"
							kind="clear"
							icon={IconCopy}
							iconSize={12}
							onClick={handleCopyUidClick}
						/>
					</div>
				</div>
				<hr />
				<div>
					<span>{formatMessage(commonMessages.vip_level)}</span>
					<div>
						<span>{tradingFees?.personal?.fee_tier?.name ?? "VIP ?"}</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default observer(Account)
